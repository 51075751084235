import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Highcharts from 'highcharts'
import exporting from 'highcharts/modules/exporting.js'
import offlineExporting from 'highcharts/modules/offline-exporting.js'
import exportData from 'highcharts/modules/export-data.js'
import HighchartsReact from 'highcharts-react-official'
import { Spin, Collapse, Panel } from '../common/Ant'
import { H4 } from '../common/Headers'
import { hasRole } from '../../utils/hasPermission'
import { getSensorReading } from '../../utils/sensorData'
import { dateTimeFormatter } from '../../utils/date'
import { createQueryString, parseQueryParams } from '../../utils/queryParams'
import {
  SENSOR_READING_TYPES,
  FLOW_SENSOR_READING_TYPES,
  ROLES,
  COLORS,
} from '../../constants'
import anomalyMarker from '../../assets/anomaly-marker.png'

exporting(Highcharts)
offlineExporting(Highcharts)
exportData(Highcharts)

const SensorChart = ({
  data,
  columns,
  isLoading,
  updateQueryParams,
  locationName,
  podPillarId,
  isLeakPod,
}) => {
  const [visibleReadings, setVisibleReadings] = useState([])
  // this is strange but calling `setVisibleReadings` from within the chart
  // does not trigger a state update in this component. this is the only method
  // I found to be able to track clicks on the chart legend and update the URL
  // with the selected sensor params
  const [chartUpdated, setChartUpdated] = useState(Math.random())
  const isAdmin = hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN)

  useEffect(() => {
    const { visibleReadings } = parseQueryParams(global.location)

    if (!!visibleReadings && visibleReadings.length > 0) {
      setVisibleReadings(
        Array.isArray(visibleReadings) ? visibleReadings : [visibleReadings]
      )
    } else {
      setVisibleReadings([
        SENSOR_READING_TYPES.TEMPERATURE,
        SENSOR_READING_TYPES.HUMIDITY,
        SENSOR_READING_TYPES.DUST,
        SENSOR_READING_TYPES.CARBON_MONOXIDE,
        SENSOR_READING_TYPES.PRESSURE,
        ...(isLeakPod
          ? [SENSOR_READING_TYPES.BATTERY_NORMAL, SENSOR_READING_TYPES.LEAK_VALUE, SENSOR_READING_TYPES.LEAK_EVENT]
          : []),
      ])
    }
  }, [isLeakPod])

  useEffect(() => {
    const { endTime, startTime, smooth, smoothInterval } = parseQueryParams(
      global.location
    )

    updateQueryParams({
      search: createQueryString({
        endTime,
        startTime,
        smooth,
        smoothInterval,
        visibleReadings,
      }),
    })
  }, [chartUpdated, visibleReadings, updateQueryParams])

  const blackListedReadings = [
    SENSOR_READING_TYPES.DP_ALARM,
    SENSOR_READING_TYPES.TEMPERATURE_LMT_87,
    SENSOR_READING_TYPES.GATEWAY_MODULE_ID,
    SENSOR_READING_TYPES.DP_LOW,
    SENSOR_READING_TYPES.DP_HIGH,
    SENSOR_READING_TYPES.DP_AVG,
    SENSOR_READING_TYPES.STATUS_FLAGS,
    SENSOR_READING_TYPES.ANOMALY_READING_TYPE,
    SENSOR_READING_TYPES.ANOMALY,
    FLOW_SENSOR_READING_TYPES.SWITCH_STATUS,
    FLOW_SENSOR_READING_TYPES.VALVE_STATUS,
    ...(hasRole(ROLES.SUPER_ADMIN)
      ? []
      : [
          SENSOR_READING_TYPES.BATTERY_NORMAL,
          SENSOR_READING_TYPES.BATTERY_LOADED,
          SENSOR_READING_TYPES.BATTERY_DROP,
        ]),
  ]

  const visibleColumns = columns.filter(
    x => blackListedReadings.indexOf(x) === -1
  )

  const chartTitle = locationName
    ? `${locationName} — ${podPillarId}`
    : podPillarId

  // TODO build default opts object and extend for each chart??
  const chartOptions = {
    credits: { enabled: false },
    exporting: {
      filename: chartTitle,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'separator',
            'downloadCSV',
          ],
        },
      },
    },
    chart: {
      animation: false,
      zoomType: 'x',
      style: {
        fontFamily:
          "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif",
      },
    },
    plotOptions: {
      series: {
        type: 'line',
        events: {
          legendItemClick: ({ target: { userOptions } }) => {
            if (!userOptions.visible) {
              visibleReadings.push(userOptions.columnName)
            } else {
              const index = visibleReadings.indexOf(userOptions.columnName)
              visibleReadings.splice(index, 1)
            }

            setVisibleReadings(visibleReadings)
          },
        },
      },
    },
    title: {
      text: chartTitle,
    },
    yAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: null,
      },
    },
    xAxis: {
      gridLineWidth: 1,
      gridLineDashStyle: 'longdash',
      type: 'datetime',
      labels: {
        style: {
          color: COLORS.BLACK_80,
          fontSize: '0.75rem',
        },
      },
      title: {
        text: null,
      },
    },
    tooltip: {
      valueDecimals: 2,
      headerFormat:
        "<span style='font-size: 0.875rem; margin-bottom: 0.875rem'>{point.key}</span><br />",
      style: {
        fontSize: '0.875rem',
        color: COLORS.BLACK_80,
      },
      xDateFormat: '%A, %b %e %Y, %H:%M',
    },
    legend: {
      verticalAlign: 'top',
      itemStyle: {
        fontSize: '0.75rem',
        color: COLORS.BLACK_80,
      },
      itemHoverStyle: {
        color: COLORS.MOON_GRAY,
      },
    },
    series: visibleColumns.map(x => {
      const readingData = getSensorReading(x)
      const zones =
        x === SENSOR_READING_TYPES.LIGHT
          ? [
              {
                // 5 foot-candles = 7.879875854099227 uW/cm2
                value: 7.879875854099227,
                color: COLORS.DARK_RED,
              },
              {
                // 30 foot-candles = 47.279255124595362 uW/cm2
                value: 47.279255124595362,
                color: COLORS.ORANGE,
              },
              {
                value: 10000,
                color: COLORS.GREEN,
              },
            ]
          : null

      return {
        visible: visibleReadings.indexOf(x) !== -1,
        name: readingData.name,
        columnName: x,
        turboThreshold: data.length > 1000 ? data.length : 1000, // 1000 is highcharts default
        data: data.map(y => {
          const hasAnomaly = y.anomalyReadingType === x && !!y.anomaly

          return {
            x: y.time,
            y: y[x],
            marker: {
              lineWidth: isAdmin && hasAnomaly && 2,
              fillColor: isAdmin && hasAnomaly && COLORS.LIGHT_RED,
              lineColor: isAdmin && hasAnomaly && COLORS.DARK_RED,
              symbol: isAdmin && hasAnomaly ? 'circle' : null,
              radius: isAdmin && hasAnomaly ? 7 : null,
              enabled: isAdmin && hasAnomaly ? true : undefined, // setting as `undefined` lets highcharts decide when to show/hide markers
            },
          }
        }),
        color: readingData.color,
        tooltip: {
          valueSuffix: readingData.unit,
        },
        zones,
      }
    }),
  }

  return (
    <div className="SensorChart" onClick={() => setChartUpdated(Math.random)}>
      {isLoading ? (
        <Spin size="large" className="w-100 center mv6" />
      ) : data.length ? (
        <div className="mb3">
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: '400px' } }}
          />

          {data.length > 1 && (
            <div className="mb3 tc b f5">
              Sensor data available from {dateTimeFormatter(data[0].time, true)}{' '}
              to {dateTimeFormatter(data[data.length - 1].time, true)}
            </div>
          )}

          {visibleColumns.length > 0 && (
            <div className="mb3">
              <Collapse>
                <Panel header="Units" key="units">
                  <div className="flex flex-wrap">
                    {visibleColumns.map(x => {
                      const readingData = getSensorReading(x)
                      return (
                        !!readingData.unit && (
                          <div className="w-25-ns w-100 flex">
                            <div className="b mr1">{readingData.name}:</div>
                            <div>{readingData.unit}</div>
                          </div>
                        )
                      )
                    })}
                  </div>
                </Panel>
              </Collapse>
            </div>
          )}

          {isAdmin && (
            <div className="ba b--yellow br2 pa2 mb3 bg-light-yellow">
              <H4>Anomaly Detection (Alpha)</H4>
              <div className="flex items-center mb3 b">
                <div>
                  <img
                    src={anomalyMarker}
                    alt="Anomaly Marker"
                    width={26}
                    height={26}
                  />
                </div>
                <div>
                  Points with this marker have been detected as anomalies.{' '}
                </div>
              </div>
              <div>
                This feature is currently in development and only visible to
                Pillar Admins.
              </div>
            </div>
          )}
        </div>
      ) : (
        <H4 className="tc">No Data Available for Selected Date Range</H4>
      )}
    </div>
  )
}

SensorChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.string).isRequired,
  updateQueryParams: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  locationName: PropTypes.string.isRequired,
  podPillarId: PropTypes.string.isRequired,
  isLeakPod: PropTypes.bool.isRequired,
}

export default SensorChart
