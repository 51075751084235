import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Modal } from './common/Ant'
import Form from './common/Form'
import Select, { Option } from './common/Select'
import Button from './common/Button'
import { toTitleCase } from '../utils/textFormatters'
import { H3 } from './common/Headers'
import { unique } from '../utils/helpers'
import { BATTERY_STATE } from '../constants'

class BatchUpdatePodsModal extends Component {
  static propTypes = {
    selectedPods: PropTypes.arrayOf(PropTypes.object),
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    podMetadata: PropTypes.object.isRequired,
    isModalVisible: PropTypes.bool.isRequired,
    batchUpdatePods: PropTypes.func.isRequired,
    handleModalCancel: PropTypes.func.isRequired,
  }

  state = {
    selectedFirmwareVersion: undefined,
    selectedAssetLocationId: undefined,
    selectedStatus: undefined,
    selectedBatteryStatus: undefined,
    podType: undefined,
  }

  setAssetLocationId = selectedAssetLocationId =>
    this.setState({ selectedAssetLocationId })
  setFirmwareVersion = selectedFirmwareVersion =>
    this.setState({ selectedFirmwareVersion })
  setBatteryStatus = selectedBatteryStatus =>
    this.setState({ selectedBatteryStatus })
  setStatus = selectedStatus => this.setState({ selectedStatus })

  handleCancel = () => {
    this.setState({
      selectedFirmwareVersion: undefined,
      selectedAssetLocationId: undefined,
      selectedStatus: undefined,
      selectedBatteryStatus: undefined,
    })

    this.props.handleModalCancel()
  }

  handleBatchUpdateSubmit = () => {
    const { selectedPods } = this.props
    const {
      selectedFirmwareVersion,
      selectedAssetLocationId,
      selectedStatus,
      selectedBatteryStatus,
    } = this.state

    this.props.batchUpdatePods({
      podIds: selectedPods.map(x => x.id),
      assetLocationId: selectedAssetLocationId,
      firmwareVersion: selectedFirmwareVersion,
      status: selectedStatus,
      batteryStartStatus: selectedBatteryStatus,
    })

    this.handleCancel()
  }

  render() {
    const {
      selectedPods,
      assetLocations,
      podMetadata: { firmwareVersions, leakFirmwareVersions, statusOptions },
      isModalVisible,
    } = this.props
    const {
      selectedFirmwareVersion,
      selectedAssetLocationId,
      selectedBatteryStatus,
      selectedStatus,
    } = this.state

    const uniquePodStatuses = selectedPods.map(x => x.status).filter(unique)
    const allFirmwareVersions = [
      ...new Set([...firmwareVersions, ...leakFirmwareVersions]),
    ]

    return (
      <Modal
        className="BatchUpdatePodsModal Modal"
        visible={isModalVisible}
        footer={null}
        onCancel={this.handleCancel}
        destroyOnClose
      >
        <H3>Bulk Update ({selectedPods.length.toString()} Smart Pods)</H3>
        <Form onSubmit={this.handleBatchUpdateSubmit}>
          <Select
            className="w-100"
            placeholder="Asset Location"
            input={{
              value: selectedAssetLocationId,
              onChange: this.setAssetLocationId,
            }}
            filterable
          >
            {[{ pillarId: '-- Unassigned --', id: 0 }, ...assetLocations].map(
              x => (
                <Option value={x.id} key={x.id}>
                  {x.pillarId}
                </Option>
              )
            )}
          </Select>
          <Select
            className="w-100"
            placeholder="Firmware Version"
            input={{
              value: selectedFirmwareVersion,
              onChange: this.setFirmwareVersion,
            }}
            filterable
          >
            {allFirmwareVersions
              .filter(x => !!x)
              .map(x => (
                <Option value={x} key={x}>
                  {x}
                </Option>
              ))}
          </Select>
          <Select
            className="w-100"
            placeholder="Battery Start Status"
            input={{
              value: selectedBatteryStatus,
              onChange: this.setBatteryStatus,
            }}
            filterable
          >
            {Object.keys(BATTERY_STATE).map(x => (
              <Option value={x} key={x}>
                {toTitleCase(x)}
              </Option>
            ))}
          </Select>
          {statusOptions[uniquePodStatuses[0]] && (
            <Select
              className="w-100"
              placeholder="Status"
              input={{
                value: selectedStatus,
                onChange: this.setStatus,
              }}
              disabled={uniquePodStatuses.length > 1}
              filterable
            >
              {statusOptions[uniquePodStatuses[0]].map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Select>
          )}
          <Button onClick={this.handleBatchUpdateSubmit} />
        </Form>
      </Modal>
    )
  }
}

export default BatchUpdatePodsModal
