import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Steps, Step } from '../common/Ant'
import PropTypes from 'prop-types'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import Checkbox from '../common/Checkbox'
import { Spin } from '../common/Ant'
import Form from '../common/Form'
import FormError from '../common/FormError'
import { Option } from '../common/Select'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import Divider from '../common/Divider'
import Block from '../common/Block'
import { H2 } from '../common/Headers'
import { formatSIMCarrier } from '../../utils/textFormatters'
import { required, isNumeric } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { createQueryString } from '../../utils/queryParams'
import {
  GATEWAY_TYPES,
  SIM_CARRIERS,
  WATER_METER_TYPES,
  SIZES,
  BATTERY_STATE,
} from '../../constants'

const NUM_STEPS = 4

class PackingForm extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    podMetadata: PropTypes.object.isRequired,
    isPodMetadataLoading: PropTypes.bool.isRequired,
    deployment: PropTypes.object.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    getDeployment: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    touch: PropTypes.func.isRequired,
    valid: PropTypes.bool.isRequired,
    formValues: PropTypes.object,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      current: 0,
    }

    this.inputs = {}
  }

  static defaultProps = {
    formValues: {},
  }

  componentDidMount() {
    const { match, getDeployment, getAllAssetLocations } = this.props
    const { siteSlug, deploymentSlug } = match.params

    getDeployment(siteSlug, deploymentSlug)
    getAllAssetLocations(createQueryString({ perPage: 'all' }))
  }

  handleNext = event => {
    const { valid, touch, formValues } = this.props

    // requiredFields in next step have default values
    // anyTouched not needed here
    if (valid) {
      // update gateway and flow monitor supply fields
      if (this.state.current === 2) {
        const gateways = formValues.actualGateways
          ? parseInt(formValues.actualGateways)
          : 0
        const flowMonitors = formValues.actualFlowMonitors
          ? parseInt(formValues.actualFlowMonitors)
          : 0

        formValues.mediumAntenna = gateways
        formValues.fourFtCable = 0
        formValues.bagD = 0
        formValues.bagA = gateways + flowMonitors
        formValues.bagB = gateways + flowMonitors
        formValues.bagC = gateways + flowMonitors
        formValues.bagE = gateways + flowMonitors
        formValues.twelveFtPowerCable = gateways + flowMonitors
        formValues.magnetMountAntenna = gateways + flowMonitors
        formValues.connectorTypeN = gateways + flowMonitors
        formValues.smallAntenna = flowMonitors
        formValues.twentyFiveFtCable = formValues.twentyFiveFtCable
          ? formValues.twentyFiveFtCable
          : flowMonitors
        formValues.largeAntenna = formValues.largeAntenna
          ? formValues.largeAntenna
          : 0
        formValues.tenFtCable = formValues.tenFtCable
          ? formValues.tenFtCable
          : 0
      }
      const current = this.state.current + 1
      this.setState({ current })
    } else {
      // touch all the fields in input
      const fieldNames = Object.keys(this.inputs).map(
        x => this.inputs[x].props.name
      )
      touch(...fieldNames)
    }

    event.preventDefault()
  }

  handlePrev = event => {
    const current = this.state.current - 1
    this.setState({ current })

    event.preventDefault()
  }

  onChange = (key, val) => this.setState({ [key]: val })

  /* TODO: Have some error checking for the metadata */

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      podMetadata,
      deployment,
      assetLocations,
      isPodMetadataLoading,
    } = this.props
    const {
      firmwareVersions,
      productVersions,
      leakFirmwareVersions,
      leakProductVersions,
    } = podMetadata
    const {
      estimatedPods,
      estimatedLeakPods,
      estimatedGateways,
      estimatedFlowMonitors,
    } = deployment

    const { current } = this.state
    const { siteSlug } = this.props.match.params
    const submit = handleSubmit(submitAction)
    const steps = [
      {
        title: 'Smart Pods',
        content: (
          <div>
            <Block label="Estimated Smart Pods">
              {estimatedPods ? estimatedPods : '0'}
            </Block>
            <div className="flex-l">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="actualPods"
                className="w-70-ns w-100 mr3-ns"
                type="number"
                component={InputContainer}
                label="Number of Smart Pods Required"
                validate={[required, isNumeric]}
              />
              <Field
                name="stagedPods"
                className="w-30-ns w-100 mr3-ns"
                type="checkbox"
                component={Checkbox}
                label="Select From Staged Smart Pods"
              />
            </div>
            <div>
              <Field
                name="productVersions"
                type="text"
                className="w-100"
                component={SelectContainer}
                label="Product Version"
                mode="multiple"
                filterable
              >
                {Object.keys(productVersions)
                  .filter(pv => productVersions[pv] !== null)
                  .map(x => (
                    <Option value={productVersions[x]} key={x}>
                      {productVersions[x]}
                    </Option>
                  ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="firmwareVersions"
                type="text"
                component={SelectContainer}
                label="Firmware Version"
                mode="multiple"
                filterable
              >
                {Object.keys(firmwareVersions)
                  .filter(fv => firmwareVersions[fv] !== null)
                  .map(x => (
                    <Option value={firmwareVersions[x]} key={x}>
                      {firmwareVersions[x]}
                    </Option>
                  ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="podAssetLocationIds"
                component={SelectContainer}
                label="Smart Pod Asset Locations"
                mode="multiple"
                className="w-100"
                filterable
              >
                {assetLocations.map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.pillarId}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="batteryStartStatus"
                type="text"
                component={SelectContainer}
                label="Battery Start Status"
                filterable
              >
                {Object.keys(BATTERY_STATE).map(x => (
                  <Option value={BATTERY_STATE[x]} key={x}>
                    {toTitleCase(BATTERY_STATE[x])}
                  </Option>
                ))}
              </Field>
            </div>

            <Divider />
            <Block label="Estimated Leak Pucks">
              {estimatedLeakPods ? estimatedLeakPods : '0'}
            </Block>
            <div className="flex-l">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="actualLeakPods"
                className="w-70-ns w-100 mr3-ns"
                type="number"
                component={InputContainer}
                label="Number of Leak Pucks Required"
                validate={[required, isNumeric]}
              />
              <Field
                name="stagedLeakPods"
                className="w-30-ns w-100 mr3-ns"
                type="checkbox"
                component={Checkbox}
                label="Select From Staged Leak Pucks"
              />
            </div>
            <div>
              <Field
                name="leakPodProductVersions"
                type="text"
                className="w-100"
                component={SelectContainer}
                label="Leak Puck Product Version"
                mode="multiple"
                filterable
              >
                {Object.keys(leakProductVersions)
                  .filter(pv => leakProductVersions[pv] !== null)
                  .map(x => (
                    <Option value={leakProductVersions[x]} key={x}>
                      {leakProductVersions[x]}
                    </Option>
                  ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="leakPodFirmwareVersions"
                type="text"
                component={SelectContainer}
                label="Leak POD Firmware Version"
                mode="multiple"
                filterable
              >
                {Object.keys(leakFirmwareVersions)
                  .filter(fv => leakFirmwareVersions[fv] !== null)
                  .map(x => (
                    <Option value={leakFirmwareVersions[x]} key={x}>
                      {leakFirmwareVersions[x]}
                    </Option>
                  ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="leakPodAssetLocationIds"
                component={SelectContainer}
                label="Leak Puck Asset Locations"
                mode="multiple"
                className="w-100"
                filterable
              >
                {assetLocations.map(x => (
                  <Option value={x.id} key={x.id}>
                    {x.pillarId}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="w-100-ns">
              <Field
                name="leakPodBatteryStartStatus"
                type="text"
                component={SelectContainer}
                label="Leak Puck Battery Start Status"
                filterable
              >
                {Object.keys(BATTERY_STATE).map(x => (
                  <Option value={BATTERY_STATE[x]} key={x}>
                    {toTitleCase(BATTERY_STATE[x])}
                  </Option>
                ))}
              </Field>
            </div>

            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= NUM_STEPS - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Network Gateways',
        content: (
          <div>
            <Block label="Estimated Network Gateways">
              {estimatedGateways ? estimatedGateways : '0'}
            </Block>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="actualGateways"
                type="number"
                component={InputContainer}
                label="Number of Network Gateways Required"
                validate={[required, isNumeric]}
                className="w-70-ns w-100 mr3-ns"
              />
              <Field
                name="stagedGateways"
                className="w-30-ns w-100"
                type="checkbox"
                component={Checkbox}
                label="Select From Staged Network Gateways"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="gatewayType"
                type="text"
                component={SelectContainer}
                label="Network Gateway Type"
                className="w-50-ns w-100 mr3-ns"
                filterable
              >
                {Object.keys(GATEWAY_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(GATEWAY_TYPES[x])}
                  </Option>
                ))}
              </Field>
              <Field
                name="simType"
                type="text"
                component={SelectContainer}
                label="SIM Type"
                className="w-50-ns w-100"
                filterable
              >
                {Object.keys(SIM_CARRIERS).map(x => (
                  <Option value={x} key={x}>
                    {formatSIMCarrier(SIM_CARRIERS[x])}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= NUM_STEPS - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'Water Monitors',
        content: (
          <div>
            <Block label="Estimated Water Monitors">
              {estimatedFlowMonitors ? estimatedFlowMonitors : '0'}
            </Block>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="actualFlowMonitors"
                type="number"
                component={InputContainer}
                label="Number of Water Monitors Required"
                validate={[required, isNumeric]}
                className="w-50-ns w-100 mr3-ns"
              />
              <Field
                name="meterType"
                type="text"
                component={SelectContainer}
                label="Meter Type"
                className="w-50-ns w-100"
                filterable
              >
                {Object.keys(WATER_METER_TYPES).map(x => (
                  <Option value={x} key={x}>
                    {WATER_METER_TYPES[x]}
                  </Option>
                ))}
              </Field>
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= NUM_STEPS - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
      {
        title: 'All Supplies',
        content: (
          <div>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="mediumAntenna"
                type="number"
                component={InputContainer}
                label="Medium Antennas"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="fourFtCable"
                type="number"
                component={InputContainer}
                label="4 Ft Cable"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="bagD"
                type="number"
                component={InputContainer}
                label="Bag D Antenna Bracket"
                validate={[required, isNumeric]}
                className="w-third-ns w-100"
              />
            </div>
            <Divider />
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="bagA"
                type="number"
                component={InputContainer}
                label="Bag A Flush Mounting"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="bagB"
                type="number"
                component={InputContainer}
                label="Bag B Bolt kit"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="bagC"
                type="number"
                component={InputContainer}
                label="Bag C Rails kit"
                validate={[required, isNumeric]}
                className="w-third-ns w-100"
              />
            </div>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="bagE"
                type="number"
                component={InputContainer}
                label="Bag E Duck Bill Cell"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="twelveFtPowerCable"
                type="number"
                component={InputContainer}
                label="12 Ft Power Cable"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="magnetMountAntenna"
                type="number"
                component={InputContainer}
                label="Magnet Mount Antenna"
                validate={[required, isNumeric]}
                className="w-third-ns w-100"
              />
            </div>
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="connectorTypeN"
                type="number"
                component={InputContainer}
                label="Connector Type N"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="smallAntenna"
                type="number"
                component={InputContainer}
                label="Small Antennas"
                validate={[required, isNumeric]}
                className="w-third-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="twentyFiveFtCable"
                type="number"
                component={InputContainer}
                label="25 Ft Control Cable"
                validate={[required, isNumeric]}
                className="w-third-ns w-100"
              />
            </div>
            <Divider />
            <div className="flex-ns">
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="largeAntenna"
                type="number"
                component={InputContainer}
                label="Large Antenna"
                validate={[required, isNumeric]}
                className="w-50-ns w-100 mr3-ns"
              />
              <Field
                ref={el => (el ? (this.inputs[el.props.name] = el) : null)}
                name="tenFtCable"
                type="number"
                component={InputContainer}
                label="10 Ft Cable"
                validate={[required, isNumeric]}
                className="w-50-ns w-100"
              />
            </div>
            <div className="flex justify-between">
              <Button
                disabled={current === 0}
                size={SIZES.MEDIUM}
                onClick={this.handlePrev}
                icon="arrow-left"
                overrideText
              />
              <Button
                disabled={current >= NUM_STEPS - 1}
                size={SIZES.MEDIUM}
                onClick={this.handleNext}
                icon="arrow-right"
                overrideText
              />
            </div>
          </div>
        ),
      },
    ]

    return isPodMetadataLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="PackingForm">
        <H2>Packing</H2>
        <Divider />
        <Form onSubmit={submit}>
          <Steps current={current} className="mb3">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
          <div className="mb3 ba b--light-gray br2 pa2">
            {steps[this.state.current].content}
          </div>
          {current === steps.length - 1 ? (
            <div className="db flex justify-between">
              <CancelButton defaultLocation={`/sites/${siteSlug}`} />
              <Button text="Submit" type="submit" submitting={submitting} />
            </div>
          ) : null}
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}
export default reduxForm({ form: 'PackingForm' })(PackingForm)
