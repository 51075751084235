import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import { Table, Column, Menu, Dropdown, Icon, Spin } from './common/Ant'
import { H3 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import { hasPermission } from '../utils/hasPermission'
import { dateFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import {
  DEPLOYMENT_STATUS,
  DEPLOYMENT_TYPE,
  INSTALL_TYPE,
  USER_PERMISSIONS,
} from '../constants'

class AllSiteDeployments extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.deployments,
    }
  }

  handleChange = (pagination, filters, sorter) =>
    this.setState({
      sortedInfo: sorter,
    })

  static propTypes = {
    deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteSlug: PropTypes.string.isRequired,
    windowWidth: PropTypes.number.isRequired,
    getAllDeploymentsBySite: PropTypes.func,
    deleteDeployment: PropTypes.func,
    isDeploymentLoading: PropTypes.bool,
    isRenderingSiteView: PropTypes.bool,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.deployments })
  }

  componentDidMount() {
    const { getAllDeploymentsBySite, siteSlug } = this.props
    getAllDeploymentsBySite && getAllDeploymentsBySite(siteSlug)
  }

  render() {
    const {
      deleteDeployment,
      siteSlug,
      isDeploymentLoading,
      isRenderingSiteView,
      windowWidth,
    } = this.props
    const { data, sortedInfo } = this.state
    const isFixedColumn = windowWidth < 750

    return (
      <section className="mb5 AllSiteDeployments">
        {!isRenderingSiteView && <H3 inline>Deployments</H3>}
        {hasPermission(USER_PERMISSIONS.ADD_INSTALL_DEPLOYMENT) && (
          <Fragment>
            <Anchor
              to={`/sites/${siteSlug}/deployments/create`}
              className="mb3 mr3"
              button
            >
              Create New Deployment
            </Anchor>
          </Fragment>
        )}
        {isDeploymentLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            bordered
            dataSource={data}
            onChange={this.handleChange}
            pagination={false}
            rowKey="slug"
            tableLayout="auto"
            scroll={{
              x: hasPermission(USER_PERMISSIONS.DELETE_INSTALL_DEPLOYMENT)
                ? 650
                : 550,
            }}
          >
            <Column
              title="Deployment"
              dataIndex="estimatedDate"
              width={100}
              sorter={sortBy('estimatedDate')}
              sortOrder={
                sortedInfo.field === 'estimatedDate' && sortedInfo.order
              }
              render={(text, record) => {
                const deploymentName = record.description
                  ? record.description
                  : dateFormatter(record.estimatedDate)

                if (deploymentName) {
                  if (
                    hasPermission(USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT) ||
                    record.installType === INSTALL_TYPE.UNINSTALL
                  ) {
                    return (
                      <Anchor
                        to={`/sites/${siteSlug}/deployments/${record.slug}`}
                      >
                        {deploymentName}
                      </Anchor>
                    )
                  } else {
                    return deploymentName
                  }
                } else {
                  return '--'
                }
              }}
            />
            <Column
              title="Status"
              dataIndex="status"
              width={50}
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              render={(text, record) =>
                record.status ? toTitleCase(record.status) : '--'
              }
            />
            <Column
              title="Smart Pods"
              dataIndex="estimatedPods"
              width={50}
              sorter={sortBy('estimatedPods')}
              sortOrder={
                sortedInfo.field === 'estimatedPods' && sortedInfo.order
              }
              render={(text, record) => {
                if (record.status === DEPLOYMENT_STATUS.CREATED) {
                  return record.estimatedPods
                    ? `${record.estimatedPods} (Est.)`
                    : '--'
                } else {
                  return record.actualPods ? record.actualPods : '--'
                }
              }}
            />
            <Column
              title="Leak Pucks"
              dataIndex="estimatedLeakPods"
              width={50}
              sorter={sortBy('estimatedLeakPods')}
              sortOrder={
                sortedInfo.field === 'estimatedLeakPods' && sortedInfo.order
              }
              render={(text, record) => {
                if (record.status === DEPLOYMENT_STATUS.CREATED) {
                  return record.estimatedLeakPods
                    ? `${record.estimatedLeakPods} (Est.)`
                    : '--'
                } else {
                  return record.actualLeakPods ? record.actualLeakPods : '--'
                }
              }}
            />
            <Column
              title="Network Gateways"
              dataIndex="estimatedGateways"
              width={50}
              sorter={sortBy('estimatedGateways')}
              sortOrder={
                sortedInfo.field === 'estimatedGateways' && sortedInfo.order
              }
              render={(text, record) => {
                if (record.status === DEPLOYMENT_STATUS.CREATED) {
                  return record.estimatedGateways
                    ? `${record.estimatedGateways} (Est.)`
                    : '--'
                } else {
                  return record.actualGateways ? record.actualGateways : '--'
                }
              }}
            />
            <Column
              title="Water Monitors"
              dataIndex="estimatedFlowMonitors"
              width={50}
              sorter={sortBy('estimatedFlowMonitors')}
              sortOrder={
                sortedInfo.field === 'estimatedFlowMonitors' && sortedInfo.order
              }
              render={(text, record) => {
                if (record.status === DEPLOYMENT_STATUS.CREATED) {
                  return record.estimatedFlowMonitors
                    ? `${record.estimatedFlowMonitors} (Est.)`
                    : '--'
                } else {
                  return record.actualFlowMonitors
                    ? record.actualFlowMonitors
                    : '--'
                }
              }}
            />
            <Column
              title="Gateway Supplies"
              dataIndex="anyGatewaySupply"
              width={100}
              sorter={sortBy('anyGatewaySupply')}
              sortOrder={
                sortedInfo.field === 'anyGatewaySupply' && sortedInfo.order
              }
              render={(text, record) =>
                record.anyGatewaySupply ? 'Yes' : 'No'
              }
            />
            <Column
              title="Install Type"
              dataIndex="installType"
              width={100}
              sorter={sortBy('installType')}
              sortOrder={sortedInfo.field === 'installType' && sortedInfo.order}
              render={(text, record) =>
                record.installType ? toTitleCase(record.installType) : '--'
              }
            />
            <Column
              title="Deployed"
              dataIndex="deploymentType"
              width={100}
              sorter={sortBy('deploymentType')}
              sortOrder={
                sortedInfo.field === 'deploymentType' && sortedInfo.order
              }
              render={(text, record) =>
                record.deploymentType
                  ? toTitleCase(DEPLOYMENT_TYPE[record.deploymentType])
                  : '--'
              }
            />
            <Column
              title="Shipped"
              width={100}
              dataIndex="shippingDate"
              render={(text, record) =>
                record.shippingDate ? dateFormatter(record.shippingDate) : '--'
              }
            />
            <Column
              title="Delivered"
              width={100}
              dataIndex="deliveryDate"
              render={(text, record) =>
                record.deliveryDate ? dateFormatter(record.deliveryDate) : '--'
              }
            />
            {hasPermission(USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT) && (
              <Column
                fixed={isFixedColumn ? 'right' : false}
                width={50}
                render={(text, record) => {
                  const menu = (
                    <Menu>
                      {record.status === DEPLOYMENT_STATUS.CREATED && (
                        <Menu.Item key="edit-deployment">
                          <Anchor
                            to={`/sites/${siteSlug}/deployments/${record.slug}/edit`}
                          >
                            Edit
                          </Anchor>
                        </Menu.Item>
                      )}
                      <Menu.Item key="delete-deployment">
                        <ActionAnchor
                          onClick={() =>
                            deleteDeployment(siteSlug, record.slug)
                          }
                        >
                          Delete
                        </ActionAnchor>
                      </Menu.Item>
                    </Menu>
                  )

                  return (
                    <Dropdown overlay={menu} trigger={['click']}>
                      <a className="ant-dropdown-link">
                        Actions <Icon type="down" />
                      </a>
                    </Dropdown>
                  )
                }}
              />
            )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllSiteDeployments
