import React from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import US_STATES from 'datasets-us-states-abbr-names'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import { Collapse, Panel } from '../common/Ant'
import SelectContainer from '../../containers/common/SelectContainer'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import FormError from '../common/FormError'
import Divider from '../common/Divider'
import { toTitleCase } from '../../utils/textFormatters'
import { hasRole } from '../../utils/hasPermission'
import { required, isNumeric } from '../../utils/validators'
import { ROLES } from '../../constants'

const EditSiteProposalForm = ({
  handleSubmit,
  error,
  submitting,
  submitAction,
  companies,
}) => {
  const submit = handleSubmit(submitAction)

  return (
    <section className="EditSiteProposalForm">
      <Form onSubmit={submit}>
        <div>
          <div className="flex-ns">
            <Field
              name="name"
              type="text"
              component={InputContainer}
              label="Name"
              validate={[required]}
              className="w-50-ns w-100 mr3-ns"
            />
            <Field
              name="companyId"
              component={SelectContainer}
              label="Company"
              placeholder="Select a company"
              className="w-25-ns w-100 mr3-ns"
              validate={[required]}
              filterable
            >
              {companies.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.name}
                </Option>
              ))}
            </Field>
            <Field
              className="w-100 w-25-ns"
              name="proposedStartDate"
              type="date"
              component={DateSelectorContainer}
              label="Proposed Start Date"
              format={formatDateUTC}
              normalize={normalizeDate}
              validate={[required]}
            />
          </div>
          <div className="flex-ns mb3">
            <Field
              className="w-25-ns w-100 mr3-ns"
              name="estimatedPods"
              type="number"
              component={InputContainer}
              label="Estimated Smart Pods"
              validate={[required]}
            />
            <Field
              className="w-25-ns w-100 mr3-ns"
              name="estimatedGateways"
              type="number"
              component={InputContainer}
              label="Estimated Network Gateways"
              validate={[required]}
            />
            <Field
              className="w-25-ns w-100 mr3-ns"
              name="estimatedFlowMonitors"
              type="number"
              component={InputContainer}
              label="Estimated Water Monitors"
              validate={[required]}
            />
            <Field
              className="w-25-ns w-100"
              name="estimatedLeakPods"
              type="number"
              component={InputContainer}
              label="Estimated Leak Pucks"
              validate={[required]}
            />
          </div>
        </div>
        <Divider />
        <Collapse>
          <Panel header="Optional Details" key="optional-fields">
            <div>
              <Field
                name="address"
                type="text"
                component={InputContainer}
                label="Address"
              />
            </div>
            <div className="flex-ns">
              <Field
                name="city"
                type="text"
                component={InputContainer}
                label="City"
                className="w-50-ns w-100 mr3-ns"
              />
              <Field
                name="state"
                component={SelectContainer}
                label="State"
                className="w-25-ns w-100 mr3-ns"
                placeholder="Select a state"
                filterable
              >
                {Object.keys(US_STATES).map(x => (
                  <Option value={x} key={x}>
                    {toTitleCase(US_STATES[x])}
                  </Option>
                ))}
              </Field>
              <Field
                name="zipCode"
                type="string"
                component={InputContainer}
                label="Zip Code"
                className="w-25-ns w-100"
              />
            </div>
            {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
              <div className="flex-ns">
                <Field
                  name="contractStartDate"
                  type="date"
                  component={DateSelectorContainer}
                  label="Contract Start Date"
                  format={formatDateUTC}
                  normalize={normalizeDate}
                  className="w-100 mr3-ns w-third-ns"
                  disableTime
                />
                <Field
                  name="contractEndDate"
                  type="date"
                  component={DateSelectorContainer}
                  label="Contract End Date"
                  format={formatDateUTC}
                  normalize={normalizeDate}
                  className="w-100 w-third-ns mr3-ns"
                  disableTime
                />
                <Field
                  name="contractValue"
                  type="number"
                  component={InputContainer}
                  label="Contract Value"
                  className="w-third-ns w-100"
                  validate={[isNumeric]}
                />
              </div>
            )}
          </Panel>
        </Collapse>
        <Divider />
        <div className="db flex justify-between mv3">
          <CancelButton defaultLocation={'/sites'} />
          <Button
            text="Submit Proposal"
            type="submit"
            submitting={submitting}
          />
        </div>
        <FormError error={error} />
      </Form>
    </section>
  )
}

EditSiteProposalForm.propTypes = {
  submitAction: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.object),
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  submitting: PropTypes.bool.isRequired,
}

export default reduxForm({ form: 'EditSiteProposalForm' })(EditSiteProposalForm)
