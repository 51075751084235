import { fork, call, takeLatest, put, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { unauthorizedAction } from '../actions/authActions'
import {
  getAllSitesAction,
  getSiteAction,
  getAdminSiteAction,
  createSiteAction,
  updateSiteAction,
  deleteSiteAction,
  addUserToSiteAction,
  addSiteContractorAction,
  deleteSiteContractorAction,
  deleteUserFromSiteAction,
  getSiteAvailableUsersAction,
  addGatewayToSiteAction,
  deleteGatewayFromSiteAction,
  addThresholdToSiteAction,
  deleteThresholdFromSiteAction,
  generateGrafanaConfigAction,
  addEscalationPolicytoSiteThresholdAction,
  getWeatherByLatLngAction,
  getAllSiteThresholdUserMappingsAction,
  getAllSiteAggregateThresholdUserMappingsAction,
  updateSiteThresholdUserMappingAction,
  getSiteAssetsAction,
  getAllUsersBySiteAction,
  getPodAssignmentHistoryBySiteAction,
  getAllAggregateThresholdsBySiteAction,
  getActivityLogAction,
  getSiteContractorsAction,
  getRiMetricsAction,
  createSiteActionReportAction,
  updateSiteActionReportAction,
  uploadUsersCSVAction,
  uploadFloorPlanPdfAction,
  getFloorPlanSnapsAction,
  getInventoryHistoryBySiteAction,
  getAllInvoicesAction,
  createInvoiceAction,
  updateInvoiceAction,
  deleteInvoiceAction,
  uploadDocumentAction,
  getAllDocumentsAction,
} from '../actions/sitesActions'
import { getAllBuildingsAction } from '../actions/buildingsActions'
import { getAllAvailableGatewaysAction } from '../actions/gatewaysActions'
import { getAlertAction } from '../actions/alertsActions'
import {
  showSuccessMessageAction,
  showErrorMessageAction,
} from '../actions/uiActions'
import api from '../services/api'
import getWeatherByLatLng from '../services/third-party/dark-sky'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { MESSAGES } from '../constants'
import { getPathSnippetAtIndex, pickErrorMessage } from '../utils/helpers'

// selectors

const getSiteSlug = state =>
  getPathSnippetAtIndex(state.router.location.pathname, 1)

// handlers

function* handleGetAllSitesSaga({ payload }) {
  try {
    const result = yield call(api.getAllSites, payload)
    yield put(getAllSitesAction.success(result))
  } catch (error) {
    yield put(getAllSitesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteAssetsSaga({ payload }) {
  try {
    const result = yield call(api.getSiteAssets, payload)
    yield put(getSiteAssetsAction.success(result))
  } catch (error) {
    yield put(getSiteAssetsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteActivityLogSaga({ payload }) {
  try {
    const result = yield call(api.getSiteActivityLog, payload)
    yield put(getActivityLogAction.success(result))
  } catch (error) {
    yield put(getActivityLogAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteContractorsSaga({ payload }) {
  try {
    const result = yield call(api.getSiteContractors, payload)
    yield put(getSiteContractorsAction.success(result))
  } catch (error) {
    yield put(getSiteContractorsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteRiMetricsSaga({ payload }) {
  try {
    const result = yield call(api.getSiteRiMetrics, payload)
    yield put(getRiMetricsAction.success(result))
  } catch (error) {
    yield put(getRiMetricsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetSiteSaga({ payload }) {
  try {
    const {
      siteSlug,
      shouldFetchBuildings = true,
      shouldFetchAvailableUsers = false,
    } = payload
    const result = yield call(api.getSiteBySlug, siteSlug)

    yield put(getSiteAction.success(result))

    if (shouldFetchBuildings) {
      const buildingsResult = yield call(api.getAllBuildings, siteSlug)
      yield put(getAllBuildingsAction.success(buildingsResult))
    }

    if (shouldFetchAvailableUsers) {
      const { items } = yield call(
        api.getAllAvailableUsersBySite,
        result.slug,
        createQueryString({ perPage: 'all' })
      )
      yield put(getSiteAvailableUsersAction.success(items))
    }
  } catch (error) {
    yield put(getSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAdminSiteSaga({ payload }) {
  try {
    const [result, { items: usersResult = [] }] = yield all([
      call(api.getSiteBySlug, payload),
      // TODO may be possible to remove this request
      call(
        api.getAllUsersBySite,
        payload,
        createQueryString({ perPage: 'all' })
      ),
    ])
    const buildingsResult = yield call(api.getAllBuildings, payload)

    yield put(getSiteAction.success({ ...result, users: usersResult }))
    yield put(getAllBuildingsAction.success(buildingsResult))

    const { items: availableUsersResult } = yield call(
      api.getAllAvailableUsersBySite,
      result.slug,
      createQueryString({ perPage: 'all' })
    )
    yield put(getSiteAvailableUsersAction.success(availableUsersResult))
  } catch (error) {
    yield put(getSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSiteSaga({ payload }) {
  try {
    const result = yield call(api.createSite, payload)
    yield put(createSiteAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
    yield put(push(`/sites/${result.slug}`))
  } catch (error) {
    yield put(createSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSiteSaga({ payload }) {
  try {
    const { ignorePush } = payload
    delete payload.ignorePush
    const result = yield call(api.updateSite, payload)

    yield put(updateSiteAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    if (!ignorePush) {
      yield put(push(`/sites/${result.slug}`))
    }
  } catch (error) {
    yield put(updateSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteSiteSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteSite, payload)
      const qs = yield call(createQueryString, {
        ...parseQueryParams(global.location),
        full: 't',
      })
      yield fork(handleGetAllSitesSaga, { payload: qs })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddUserToSiteSaga({ payload }) {
  try {
    const { siteSlug, userId } = payload

    const confirmAddition = yield call(global.confirm, MESSAGES.CONFIRM)

    if (confirmAddition) {
      yield call(api.addUserToSite, siteSlug, userId)
      yield put(getAllUsersBySiteAction.request({ siteSlug }))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_USER))
    }
  } catch (error) {
    yield put(addUserToSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddSiteContractorSaga({ payload }) {
  try {
    const { siteSlug, companySlug } = payload
    yield call(api.addSiteContractor, siteSlug, companySlug)
    yield put(getSiteContractorsAction.request(payload))

    yield fork(handleGetSiteAvailableUsersSaga, {
      payload: {
        siteSlug,
        qs: createQueryString({ perPage: 'all' }),
      },
    })

    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_CONTRACTOR))
  } catch (error) {
    yield put(addSiteContractorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteSiteContractorSaga({ payload }) {
  try {
    const { siteSlug, contractorId } = payload
    yield call(api.deleteSiteContractor, siteSlug, contractorId)
    yield put(getSiteContractorsAction.request(payload))

    yield fork(handleGetSiteAvailableUsersSaga, {
      payload: {
        siteSlug,
        qs: createQueryString({ perPage: 'all' }),
      },
    })

    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_CONTRACTOR)
    )
  } catch (error) {
    yield put(deleteSiteContractorAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteUserFromSiteSaga({ payload }) {
  try {
    const { siteSlug, userId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteUserFromSite, siteSlug, userId)
      yield put(getAllUsersBySiteAction.request({ siteSlug }))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_USER))
    }
  } catch (error) {
    yield put(deleteUserFromSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddGatewayToSiteSaga({ payload }) {
  try {
    const { siteSlug, gatewayId } = payload

    yield call(api.addGatewayToSite, siteSlug, gatewayId)
    yield put(getAllAvailableGatewaysAction.request(siteSlug))
    yield put(getSiteAction.request({ siteSlug }))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_GATEWAY))
  } catch (error) {
    yield put(addGatewayToSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteGatewayFromSiteSaga({ payload }) {
  try {
    const { siteSlug, gatewayId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteGatewayFromSite, siteSlug, gatewayId)
      yield put(getAllAvailableGatewaysAction.request(siteSlug))
      yield put(getSiteAction.request({ siteSlug }))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_GATEWAY))
    }
  } catch (error) {
    yield put(deleteGatewayFromSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddThresholdToSiteSaga({ payload }) {
  try {
    const isConfirmed = yield call(global.confirm, MESSAGES.CONFIRM)

    if (isConfirmed) {
      const { siteSlug, thresholdId, thresholdGroupSlug } = payload

      yield call(
        api.addThresholdToSite,
        siteSlug,
        thresholdId,
        thresholdGroupSlug
      )
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_ADDED_THRESHOLD))
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(addThresholdToSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteThresholdFromSiteSaga({ payload }) {
  try {
    const { siteSlug, thresholdId } = payload

    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      yield call(api.deleteThresholdFromSite, siteSlug, thresholdId)
      yield put(
        getAllAggregateThresholdsBySiteAction.request({
          siteSlug,
          qs: createQueryString({ full: 't' }),
        })
      )
      yield put(
        showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED_THRESHOLD)
      )
      yield put(
        getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug)
      )
    }
  } catch (error) {
    yield put(deleteThresholdFromSiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGenerateGrafanaConfigSaga({ payload }) {
  try {
    const result = yield call(api.generateGrafanaJSON, payload)
    yield put(generateGrafanaConfigAction.success(result))
  } catch (error) {
    yield put(generateGrafanaConfigAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleAddEscalationPolicytoSiteThresholdSaga({ payload }) {
  try {
    const { thresholdId, escalationPolicySlug } = payload

    const siteSlug = yield select(getSiteSlug)
    yield call(
      api.addEscalationPolicytoSiteThreshold,
      siteSlug,
      thresholdId,
      escalationPolicySlug
    )
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED_ESCALATION_POLICY)
    )
  } catch (error) {
    yield put(addEscalationPolicytoSiteThresholdAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetWeatherByLatLngSaga({ payload }) {
  try {
    const { lat, lng } = payload

    const result = yield call(getWeatherByLatLng, lat, lng)
    yield put(getWeatherByLatLngAction.success(result))
  } catch (error) {
    yield put(getWeatherByLatLngAction.failure(error))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllSiteThresholdUserMappingsSaga({ payload }) {
  try {
    const result = yield call(api.getAllSiteThresholdUserMappings, payload)
    yield put(getAllSiteThresholdUserMappingsAction.success(result))
  } catch (error) {
    yield put(getAllSiteThresholdUserMappingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllSiteAggregateThresholdUserMappingsSaga({ payload }) {
  try {
    const result = yield call(
      api.getAllSiteAggregateThresholdUserMappings,
      payload
    )
    yield put(getAllSiteAggregateThresholdUserMappingsAction.success(result))
  } catch (error) {
    yield put(getAllSiteAggregateThresholdUserMappingsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSiteThresholdUserMappingSaga({ payload }) {
  try {
    const siteSlug = yield select(getSiteSlug)
    const result = yield call(api.updateSiteThresholdUserMapping, {
      ...payload,
      siteSlug,
    })
    yield put(getAllSiteAggregateThresholdUserMappingsAction.request(siteSlug))
    yield put(updateSiteThresholdUserMappingAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
    yield fork(handleGetAllSiteThresholdUserMappingsSaga, { payload: siteSlug })
  } catch (error) {
    yield put(updateSiteThresholdUserMappingAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllUsersBySiteSaga({ payload }) {
  try {
    const { siteSlug } = payload
    const result = yield call(
      api.getAllUsersBySite,
      siteSlug,
      createQueryString({ perPage: 'all' })
    )
    yield put(getAllUsersBySiteAction.success(result))
  } catch (error) {
    yield put(getAllUsersBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAggregateThresholdsBySiteSaga({ payload }) {
  try {
    const { siteSlug, qs } = payload
    const result = yield call(api.getAllAggregateThresholdsBySite, siteSlug, qs)
    yield put(getAllAggregateThresholdsBySiteAction.success(result))
  } catch (error) {
    yield put(getAllAggregateThresholdsBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetPodAssignmentHistoryBySiteSaga({ payload }) {
  try {
    const result = yield call(api.getPodAssignmentHistoryBySite, payload)
    yield put(getPodAssignmentHistoryBySiteAction.success(result))
  } catch (error) {
    yield put(getPodAssignmentHistoryBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))
    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSiteActionReportSaga({ payload }) {
  try {
    const { siteSlug, actionReport } = payload
    yield call(api.createSiteActionReport, siteSlug, actionReport)
    yield put(createSiteActionReportAction.success())
    yield put(getAlertAction.request(actionReport.alertSlug))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))
  } catch (error) {
    yield put(createSiteActionReportAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSiteActionReportSaga({ payload }) {
  try {
    const { siteSlug, actionReportSlug, actionReport } = payload
    yield call(
      api.updateSiteActionReport,
      siteSlug,
      actionReportSlug,
      actionReport
    )
    yield put(getAlertAction.request(actionReport.alertSlug))
    yield put(updateSiteActionReportAction.success())
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(updateSiteActionReportAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUploadCSVSaga({ payload }) {
  try {
    const { siteSlug, formData } = payload

    const result = yield call(api.uploadUsersCSV, siteSlug, formData)
    yield put(uploadUsersCSVAction.success(result))
    if (!result.failedCount) {
      yield put(getSiteAction.request({ siteSlug }))
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_IMPORTED_USERS))
    }
  } catch (error) {
    yield put(uploadUsersCSVAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

function* handleGetSiteAvailableUsersSaga({ payload }) {
  try {
    const { siteSlug, qs } = payload
    const { items } = yield call(api.getAllAvailableUsersBySite, siteSlug, qs)

    yield put(getSiteAvailableUsersAction.success(items))
  } catch (error) {
    yield put(getSiteAvailableUsersAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUploadFloorPlanPdfSaga({ payload }) {
  try {
    const { siteSlug, formData } = payload
    const result = yield call(api.uploadFloorPlanPdf, siteSlug, formData)
    yield put(uploadFloorPlanPdfAction.success(result))
    yield put(
      showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPLOADED_FLOORPLAN_PDF)
    )
    yield put(push(`/sites/${siteSlug}`))
  } catch (error) {
    yield put(uploadFloorPlanPdfAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetFloorPlanSnapsSaga({ payload }) {
  try {
    const result = yield call(api.getFloorPlanSnaps, payload)

    yield put(getFloorPlanSnapsAction.success(result))
  } catch (error) {
    yield put(getFloorPlanSnapsAction.failure(error))
    if (error.status !== 404) {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetInventoryHistoryBySiteSaga({ payload }) {
  try {
    const result = yield call(api.getInventoryHistoryBySite, payload)

    yield put(getInventoryHistoryBySiteAction.success(result))
  } catch (error) {
    yield put(getInventoryHistoryBySiteAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleGetAllSiteInvoicesSaga({ payload }) {
  try {
    const result = yield call(api.getAllSiteInvoices, payload)

    yield put(getAllInvoicesAction.success(result))
  } catch (error) {
    yield put(getAllInvoicesAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleCreateSiteInvoiceSaga({ payload }) {
  try {
    const { siteSlug, invoice } = payload
    const result = yield call(api.createSiteInvoice, siteSlug, invoice)

    yield put(createInvoiceAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_CREATED))

    yield fork(handleGetAllSiteInvoicesSaga, { payload: siteSlug })
  } catch (error) {
    yield put(createInvoiceAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUpdateSiteInvoiceSaga({ payload }) {
  try {
    const { siteSlug, invoice } = payload
    const result = yield call(api.updateSiteInvoice, siteSlug, invoice)

    yield put(updateInvoiceAction.success(result))
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))

    yield fork(handleGetAllSiteInvoicesSaga, { payload: siteSlug })
  } catch (error) {
    yield put(updateInvoiceAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleDeleteSiteInvoiceSaga({ payload }) {
  try {
    const confirmDeletion = yield call(global.confirm, MESSAGES.CONFIRM)
    if (confirmDeletion) {
      const { siteSlug, slug } = payload
      yield call(api.deleteSiteInvoice, siteSlug, slug)
      yield fork(handleGetAllSiteInvoicesSaga, { payload: siteSlug })
      yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_DELETED))
    }
  } catch (error) {
    yield put(deleteInvoiceAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}
// site documents saga
function* handleGetAllSiteDocumentsSaga({ payload }) {
  try {
    const result = yield call(api.getAllSiteDocuments, payload)

    yield put(getAllDocumentsAction.success(result))
  } catch (error) {
    yield put(getAllDocumentsAction.failure(error))
    yield put(showErrorMessageAction(pickErrorMessage(error)))

    if (error.status === 401) {
      yield put(unauthorizedAction())
    }
  }
}

function* handleUploadSiteDocumentSaga({ payload }) {
  try {
    const { siteSlug, formData } = payload

    const result = yield call(api.uploadSiteDocument, siteSlug, formData)
    yield put(uploadDocumentAction.success(result))
    yield fork(handleGetAllSiteInvoicesSaga, { payload: siteSlug })
    yield fork(handleGetAllSiteDocumentsSaga, { payload: siteSlug })
    yield put(showSuccessMessageAction(MESSAGES.SUCCESSFULLY_UPDATED))
  } catch (error) {
    yield put(uploadDocumentAction.failure(error))

    if (error.status === 413) {
      const apiError = { message: 'file_too_large' }
      yield put(showErrorMessageAction(pickErrorMessage({ apiError })))
    } else if (error.status === 401) {
      yield put(unauthorizedAction())
    } else {
      yield put(showErrorMessageAction(pickErrorMessage(error)))
    }
  }
}

// watchers

function* watchUploadFloorPlanPdfSaga() {
  yield takeLatest(
    uploadFloorPlanPdfAction.REQUEST,
    handleUploadFloorPlanPdfSaga
  )
}

function* watchGetSiteActivityLogSaga() {
  yield takeLatest(getActivityLogAction.REQUEST, handleGetSiteActivityLogSaga)
}

function* watchGetSiteContractorsSaga() {
  yield takeLatest(
    getSiteContractorsAction.REQUEST,
    handleGetSiteContractorsSaga
  )
}

function* watchGetSiteRiMetricsSaga() {
  yield takeLatest(getRiMetricsAction.REQUEST, handleGetSiteRiMetricsSaga)
}

function* watchGetAllSitesSaga() {
  yield takeLatest(getAllSitesAction.REQUEST, handleGetAllSitesSaga)
}

function* watchGetSiteAssetsSaga() {
  yield takeLatest(getSiteAssetsAction.REQUEST, handleGetSiteAssetsSaga)
}

function* watchGetSiteSaga() {
  yield takeLatest(getSiteAction.REQUEST, handleGetSiteSaga)
}

function* watchGetAdminSiteSaga() {
  yield takeLatest(getAdminSiteAction.REQUEST, handleGetAdminSiteSaga)
}

function* watchCreateSiteSaga() {
  yield takeLatest(createSiteAction.REQUEST, handleCreateSiteSaga)
}

function* watchUpdateSiteSaga() {
  yield takeLatest(updateSiteAction.REQUEST, handleUpdateSiteSaga)
}

function* watchDeleteSiteSaga() {
  yield takeLatest(deleteSiteAction.REQUEST, handleDeleteSiteSaga)
}

function* watchAddUserToSiteSaga() {
  yield takeLatest(addUserToSiteAction.REQUEST, handleAddUserToSiteSaga)
}

function* watchAddSiteContractorSaga() {
  yield takeLatest(addSiteContractorAction.REQUEST, handleAddSiteContractorSaga)
}

function* watchDeleteSiteContractorSaga() {
  yield takeLatest(
    deleteSiteContractorAction.REQUEST,
    handleDeleteSiteContractorSaga
  )
}

function* watchDeleteUserFromSiteSaga() {
  yield takeLatest(
    deleteUserFromSiteAction.REQUEST,
    handleDeleteUserFromSiteSaga
  )
}

function* watchAddGatewayToSiteSaga() {
  yield takeLatest(addGatewayToSiteAction.REQUEST, handleAddGatewayToSiteSaga)
}

function* watchDeleteGatewayFromSiteSaga() {
  yield takeLatest(
    deleteGatewayFromSiteAction.REQUEST,
    handleDeleteGatewayFromSiteSaga
  )
}

function* watchAddThresholdToSiteSaga() {
  yield takeLatest(
    addThresholdToSiteAction.REQUEST,
    handleAddThresholdToSiteSaga
  )
}

function* watchDeleteThresholdFromSiteSaga() {
  yield takeLatest(
    deleteThresholdFromSiteAction.REQUEST,
    handleDeleteThresholdFromSiteSaga
  )
}

function* watchGenerateGrafanaConfig() {
  yield takeLatest(
    generateGrafanaConfigAction.REQUEST,
    handleGenerateGrafanaConfigSaga
  )
}

function* watchAddEscalationPolicytoSiteThresholdSaga() {
  yield takeLatest(
    addEscalationPolicytoSiteThresholdAction.REQUEST,
    handleAddEscalationPolicytoSiteThresholdSaga
  )
}

function* watchGetWeatherByLatLngSaga() {
  yield takeLatest(
    getWeatherByLatLngAction.REQUEST,
    handleGetWeatherByLatLngSaga
  )
}

function* watchGetAllSiteThresholdUserMappingsSaga() {
  yield takeLatest(
    getAllSiteThresholdUserMappingsAction.REQUEST,
    handleGetAllSiteThresholdUserMappingsSaga
  )
}

function* watchGetAllSiteAggregateThresholdUserMappingsSaga() {
  yield takeLatest(
    getAllSiteAggregateThresholdUserMappingsAction.REQUEST,
    handleGetAllSiteAggregateThresholdUserMappingsSaga
  )
}

function* watchUpdateSiteThresholdUserMappingSaga() {
  yield takeLatest(
    updateSiteThresholdUserMappingAction.REQUEST,
    handleUpdateSiteThresholdUserMappingSaga
  )
}

function* watchGetAllUsersBySiteSaga() {
  yield takeLatest(getAllUsersBySiteAction.REQUEST, handleGetAllUsersBySiteSaga)
}

function* watchGetAggregateThresholdsBySite() {
  yield takeLatest(
    getAllAggregateThresholdsBySiteAction.REQUEST,
    handleGetAggregateThresholdsBySiteSaga
  )
}

function* watchGetPodAssignmentHistoryBySiteSaga() {
  yield takeLatest(
    getPodAssignmentHistoryBySiteAction.REQUEST,
    handleGetPodAssignmentHistoryBySiteSaga
  )
}

function* watchCreateSiteActionReportSaga() {
  yield takeLatest(
    createSiteActionReportAction.REQUEST,
    handleCreateSiteActionReportSaga
  )
}

function* watchUpdateSiteActionReportSaga() {
  yield takeLatest(
    updateSiteActionReportAction.REQUEST,
    handleUpdateSiteActionReportSaga
  )
}

function* watchUploadCSVSaga() {
  yield takeLatest(uploadUsersCSVAction.REQUEST, handleUploadCSVSaga)
}

function* watchGetSiteAvailableUsersSaga() {
  yield takeLatest(
    getSiteAvailableUsersAction.REQUEST,
    handleGetSiteAvailableUsersSaga
  )
}

function* watchGetFloorPlanSnapsSaga() {
  yield takeLatest(getFloorPlanSnapsAction.REQUEST, handleGetFloorPlanSnapsSaga)
}

function* watchGetInventoryHistoryBySiteSaga() {
  yield takeLatest(
    getInventoryHistoryBySiteAction.REQUEST,
    handleGetInventoryHistoryBySiteSaga
  )
}

function* watchGetAllSiteInvoicesSaga() {
  yield takeLatest(getAllInvoicesAction.REQUEST, handleGetAllSiteInvoicesSaga)
}

function* watchCreateSiteInvoiceSaga() {
  yield takeLatest(createInvoiceAction.REQUEST, handleCreateSiteInvoiceSaga)
}

function* watchUpdateSiteInvoiceSaga() {
  yield takeLatest(updateInvoiceAction.REQUEST, handleUpdateSiteInvoiceSaga)
}

function* watchDeleteSiteInvoiceSaga() {
  yield takeLatest(deleteInvoiceAction.REQUEST, handleDeleteSiteInvoiceSaga)
}

function* watchUploadSiteDocumentSaga() {
  yield takeLatest(uploadDocumentAction.REQUEST, handleUploadSiteDocumentSaga)
}

function* watchGetAllSiteDocumentsSaga() {
  yield takeLatest(getAllDocumentsAction.REQUEST, handleGetAllSiteDocumentsSaga)
}

function* sitesSaga() {
  yield all([
    fork(watchGetAllSitesSaga),
    fork(watchGetSiteSaga),
    fork(watchGetAdminSiteSaga),
    fork(watchCreateSiteSaga),
    fork(watchUpdateSiteSaga),
    fork(watchDeleteSiteSaga),
    fork(watchAddUserToSiteSaga),
    fork(watchAddSiteContractorSaga),
    fork(watchDeleteSiteContractorSaga),
    fork(watchDeleteUserFromSiteSaga),
    fork(watchAddGatewayToSiteSaga),
    fork(watchDeleteGatewayFromSiteSaga),
    fork(watchAddThresholdToSiteSaga),
    fork(watchDeleteThresholdFromSiteSaga),
    fork(watchGenerateGrafanaConfig),
    fork(watchAddEscalationPolicytoSiteThresholdSaga),
    fork(watchGetWeatherByLatLngSaga),
    fork(watchGetAggregateThresholdsBySite),
    fork(watchGetAllSiteThresholdUserMappingsSaga),
    fork(watchGetAllSiteAggregateThresholdUserMappingsSaga),
    fork(watchUpdateSiteThresholdUserMappingSaga),
    fork(watchGetSiteAssetsSaga),
    fork(watchGetAllUsersBySiteSaga),
    fork(watchGetPodAssignmentHistoryBySiteSaga),
    fork(watchGetSiteActivityLogSaga),
    fork(watchGetSiteContractorsSaga),
    fork(watchGetSiteRiMetricsSaga),
    fork(watchCreateSiteActionReportSaga),
    fork(watchUpdateSiteActionReportSaga),
    fork(watchUploadCSVSaga),
    fork(watchUploadFloorPlanPdfSaga),
    fork(watchGetFloorPlanSnapsSaga),
    fork(watchGetSiteAvailableUsersSaga),
    fork(watchGetInventoryHistoryBySiteSaga),
    fork(watchGetAllSiteInvoicesSaga),
    fork(watchCreateSiteInvoiceSaga),
    fork(watchUpdateSiteInvoiceSaga),
    fork(watchDeleteSiteInvoiceSaga),
    fork(watchUploadSiteDocumentSaga),
    fork(watchGetAllSiteDocumentsSaga),
  ])
}

export {
  sitesSaga as default,
  watchGetAllSitesSaga,
  watchGetSiteSaga,
  watchGetAdminSiteSaga,
  watchCreateSiteSaga,
  watchUpdateSiteSaga,
  watchDeleteSiteSaga,
  watchAddUserToSiteSaga,
  watchAddSiteContractorSaga,
  watchDeleteSiteContractorSaga,
  watchDeleteUserFromSiteSaga,
  watchAddGatewayToSiteSaga,
  watchDeleteGatewayFromSiteSaga,
  watchAddThresholdToSiteSaga,
  watchDeleteThresholdFromSiteSaga,
  watchGenerateGrafanaConfig,
  watchAddEscalationPolicytoSiteThresholdSaga,
  watchGetPodAssignmentHistoryBySiteSaga,
  watchGetWeatherByLatLngSaga,
  watchGetAggregateThresholdsBySite,
  watchGetAllSiteThresholdUserMappingsSaga,
  watchGetAllSiteAggregateThresholdUserMappingsSaga,
  watchUpdateSiteThresholdUserMappingSaga,
  watchGetSiteAssetsSaga,
  watchGetSiteActivityLogSaga,
  watchGetSiteContractorsSaga,
  watchGetSiteRiMetricsSaga,
  watchCreateSiteActionReportSaga,
  watchUpdateSiteActionReportSaga,
  watchUploadCSVSaga,
  watchGetSiteAvailableUsersSaga,
  watchGetFloorPlanSnapsSaga,
  watchUploadFloorPlanPdfSaga,
  watchGetInventoryHistoryBySiteSaga,
  handleGetSiteActivityLogSaga,
  handleGetSiteContractorsSaga,
  handleGetSiteRiMetricsSaga,
  handleGetAllSitesSaga,
  handleGetSiteSaga,
  handleGetAdminSiteSaga,
  handleCreateSiteSaga,
  handleUpdateSiteSaga,
  handleDeleteSiteSaga,
  handleAddUserToSiteSaga,
  handleAddSiteContractorSaga,
  handleDeleteSiteContractorSaga,
  handleDeleteUserFromSiteSaga,
  handleAddGatewayToSiteSaga,
  handleDeleteGatewayFromSiteSaga,
  handleAddThresholdToSiteSaga,
  handleDeleteThresholdFromSiteSaga,
  handleGetAggregateThresholdsBySiteSaga,
  handleGetAllSiteThresholdUserMappingsSaga,
  handleGetAllSiteAggregateThresholdUserMappingsSaga,
  handleGetPodAssignmentHistoryBySiteSaga,
  handleGetSiteAssetsSaga,
  watchGetAllSiteInvoicesSaga,
  watchCreateSiteInvoiceSaga,
  watchUpdateSiteInvoiceSaga,
  watchDeleteSiteInvoiceSaga,
  watchUploadSiteDocumentSaga,
  watchGetAllSiteDocumentsSaga,
}
