import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { Tabs, TabPane, Spin, Table, Column, Icon, Tooltip } from './common/Ant'
import { H4 } from './common/Headers'
import { Anchor } from './common/Anchor'
import Checkbox from './common/Checkbox'
import Block from './common/Block'
import Divider from './common/Divider'
import DateRange from './common/DateRange'
import Select, { Option } from './common/Select'
import LocationHeader from './LocationHeader'
import SensorChart from './charts/SensorChart'
import AssignmentHistory from './AssignmentHistory'
import UptimeChart from './charts/UptimeChart'
import TinyFloorPlan from './TinyFloorPlan'
import AllPodAlerts from './AllPodAlerts'
import AllFlowAlerts from './AllFlowAlerts'
import AllComments from './AllComments'
import { hasRole, hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { dateTimeFormatter } from '../utils/date'
import { getSensorReading } from '../utils/sensorData'
import {
  SIZES,
  SENSOR_READING_TYPES,
  POD_TYPES,
  RESOURCE_TYPES,
  SMOOTH_INTERVAL,
  ROLES,
  FLOW_SENSOR_READING_TYPES,
  USER_PERMISSIONS,
} from '../constants'
import { getPathSnippets } from '../utils/helpers'
import { toTitleCase } from '../utils/textFormatters'
import { Switch, Route, Redirect } from 'react-router-dom'
import RiMetrics from './RiMetrics'
import FlowMonitorData from './FlowMonitorData'
import { flagEnabled } from '../utils/config'

const isFlowMonitorEnabled = flagEnabled('REACT_APP_ENABLE_FLOW_MONITOR')

class Location extends Component {
  constructor() {
    super()

    this.now = moment()

    this.state = {
      now: this.now,
      endValue: this.now,
      startValue: moment(this.now).subtract(1, 'd'),
      commentStartTime: undefined,
      commentEndTime: undefined,
      smoothInterval: SMOOTH_INTERVAL.FIVE_MINUTES,
    }

    this.onCloseDateRange = this.onCloseDateRange.bind(this)
  }

  static propTypes = {
    getLocation: PropTypes.func.isRequired,
    getLocationChartData: PropTypes.func.isRequired,
    getLocationAlertHistory: PropTypes.func.isRequired,
    locationAlertHistory: PropTypes.arrayOf(PropTypes.object),
    toggleLocationOption: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    markPodLost: PropTypes.func.isRequired,
    currentLocationChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentLocationChartColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    currentLocation: PropTypes.object.isRequired,
    accountProfile: PropTypes.object.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    isSnapshotLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool,
    isCommentLoading: PropTypes.bool,
    windowWidth: PropTypes.number.isRequired,
    match: PropTypes.object,
    currentSite: PropTypes.object,
    currentFloor: PropTypes.object,
    getSite: PropTypes.func,
    getFloor: PropTypes.func,
    meta: PropTypes.object,
    getPodAssignmentHistory: PropTypes.func.isRequired,
    podAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    comments: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFlowMonitorData: PropTypes.func.isRequired,
    flowMonitorData: PropTypes.object.isRequired,
    locationUptimeData: PropTypes.object.isRequired,
    getLocationUptimeData: PropTypes.func.isRequired,
  }

  state = { activeTab: undefined }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const {
      getLocation,
      getLocationChartData,
      getSite,
      getFloor,
      currentSite,
      match,
    } = this.props
    const { startValue, endValue } = this.state

    const searchParams = parseQueryParams(global.location)
    let query

    if (searchParams.startTime && searchParams.endTime) {
      query = {
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
      }
      this.setState({
        startValue: moment(searchParams.startTime * 1000),
        endValue: moment(searchParams.endTime * 1000),
      })
    } else {
      query = {
        startTime: startValue.unix(),
        endTime: endValue.unix(),
      }
    }

    if (searchParams.smoothInterval || searchParams.smooth) {
      query = {
        ...query,
        smoothInterval: searchParams.smoothInterval,
        smooth: searchParams.smooth,
      }

      this.setState({
        smoothInterval: searchParams.smoothInterval
          ? searchParams.smoothInterval
          : !searchParams.smooth || searchParams.smooth === 't'
          ? SMOOTH_INTERVAL.FIVE_MINUTES
          : SMOOTH_INTERVAL.RAW,
      })
    }

    const { siteSlug, floorId, id } = match.params
    const qs = createQueryString(query)

    getLocation(siteSlug, floorId, id)
    getLocationChartData(siteSlug, floorId, id, qs)

    !currentSite.slug && getSite(siteSlug)
    getFloor(siteSlug, floorId)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet, query)
  }

  handleTabSelection = (entity, query) => {
    const { updateQueryParams, match } = this.props
    const { smoothInterval } = this.state
    const qs = createQueryString({
      startTime: query.startTime,
      endTime: query.endTime,
      smoothInterval,
    })

    if (entity !== match.params.id) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === 'chart' ? qs : '',
      })
    } else {
      this.setState({ activeTab: 'chart' })
    }
  }

  onCloseDateRange(startValue, endValue) {
    const { getLocationChartData, updateQueryParams, match } = this.props
    const { siteSlug, floorId, id } = match.params
    const { smoothInterval } = this.state

    this.setState({ startValue, endValue })

    const qs = createQueryString({
      startTime: startValue.unix(),
      endTime: endValue.unix(),
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })

    getLocationChartData(siteSlug, floorId, id, qs)
  }

  handleFilterChange = smoothInterval => {
    const { getLocationChartData, updateQueryParams, match } = this.props
    const { siteSlug, floorId, id } = match.params
    const { startValue, endValue } = this.state

    this.setState({ smoothInterval })

    const qs = createQueryString({
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
      startTime: startValue.unix(),
      endTime: endValue.unix(),
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })

    getLocationChartData(siteSlug, floorId, id, qs)
  }

  renderReading(type, value) {
    const data = getSensorReading(type, value)

    return `${data.value} ${data.unit}`
  }

  setCommentTime = (commentStartTime, commentEndTime) =>
    this.setState({ commentStartTime, commentEndTime })

  render() {
    const {
      currentLocation,
      currentSite,
      currentFloor,
      currentLocationChartData,
      currentLocationChartColumns,
      toggleLocationOption,
      match,
      accountProfile,
      isChartDataLoading,
      isLocationLoading,
      isHistoryLoading,
      isSnapshotLoading,
      locationAlertHistory,
      getLocationAlertHistory,
      getPodAssignmentHistory,
      podAssignmentHistory,
      windowWidth,
      meta,
      isCommentLoading,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      comments,
      markPodLost,
      riMetrics,
      getRiMetrics,
      getFlowMonitorData,
      flowMonitorData,
      updateQueryParams,
      locationUptimeData,
      getLocationUptimeData,
    } = this.props
    const {
      startValue,
      endValue,
      commentStartTime,
      commentEndTime,
      smoothInterval,
    } = this.state
    const { siteSlug, floorId, id } = match.params
    const {
      name: locationName,
      podPillarId,
      flowMonitorId,
      flowMonitorPillarId,
      description,
      thresholds,
      lastReading,
      podType,
      activeAlerts,
      skipSiteThresholds,
      slug,
    } = currentLocation
    const { thresholds: siteThresholds } = currentSite

    const readingsPrefix = cx('w-100 w-50-ns mb3 ph1')
    const shouldRenderTinyFloorPlan =
      !!currentLocation.coordinates && !!currentFloor.planFileName
    const shouldRenderReadings = !!lastReading && !!lastReading.time
    const isLeakPod = podType === POD_TYPES.LEAK_POD

    return isLocationLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Location">
        <LocationHeader
          site={currentSite}
          floor={currentFloor}
          location={currentLocation}
          markPodLost={markPodLost}
          match={match}
        />
        <Divider />
        {activeAlerts.length > 0 && (
          <div>
            <H4>Active Alerts</H4>
            <Table
              dataSource={activeAlerts}
              rowKey="slug"
              pagination={false}
              size="small"
              tableLayout="auto"
            >
              {flowMonitorPillarId ? (
                <Column
                  title="Name"
                  dataIndex="thresholdName"
                  render={(text, { slug }) => (
                    <Anchor to={`/alerts/flow-monitors/${slug}`}>
                      <div className="flex items-center">
                        <div className="mr2">
                          {text ? (
                            <Icon type="exclamation-circle-o" className="red" />
                          ) : (
                            <Icon type="poweroff" className="orange" />
                          )}
                        </div>
                        <div>{text || 'Water Monitor Offline'}</div>
                      </div>
                    </Anchor>
                  )}
                />
              ) : (
                <Column
                  title="Name"
                  dataIndex="thresholdName"
                  render={(text, { slug }) => (
                    <Anchor to={`/alerts/pods/${slug}`}>
                      <div className="flex items-center">
                        <div className="mr2">
                          {text ? (
                            <Icon type="exclamation-circle-o" className="red" />
                          ) : (
                            <Icon type="poweroff" className="orange" />
                          )}
                        </div>
                        <div>{text || 'Smart Pod Offline'}</div>
                      </div>
                    </Anchor>
                  )}
                />
              )}
              <Column
                title="Start Time"
                dataIndex="startTime"
                render={text => (
                  <Tooltip title={dateTimeFormatter(text)}>
                    {dateTimeFormatter(text, true)}
                  </Tooltip>
                )}
              />
            </Table>
          </div>
        )}
        <div className="flex-ns">
          {shouldRenderTinyFloorPlan && (
            <div className="w-100 w-50-ns ph1">
              <TinyFloorPlan floor={currentFloor} location={currentLocation} />
            </div>
          )}
          {shouldRenderReadings ? (
            isSnapshotLoading ? (
              <Spin size="large" className="w-100 center mv6" />
            ) : (
              <div className={readingsPrefix}>
                <H4>Latest Reading: {dateTimeFormatter(lastReading.time)}</H4>
                {flowMonitorPillarId && isFlowMonitorEnabled ? (
                  <div className="flex flex-wrap">
                    <Block
                      className="white bg-blue t-shadow-1"
                      label="Water Flow"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        FLOW_SENSOR_READING_TYPES.FLOW_VALUE,
                        lastReading.flowValue
                      )}
                    </Block>
                    <Block
                      className="white bg-navy t-shadow-1"
                      label="Pipe Temperature"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        FLOW_SENSOR_READING_TYPES.TEMPERATURE,
                        lastReading.temp
                      )}
                    </Block>
                  </div>
                ) : podType === POD_TYPES.PDS ? (
                  <div className="flex flex-wrap">
                    <Block
                      className="white bg-purple t-shadow-1"
                      label="Differential Pressure - Low"
                      size={SIZES.MEDIUM}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.DP_LOW,
                        lastReading.diffPressureLow
                      )}
                    </Block>
                    <Block
                      className="white bg-green t-shadow-1"
                      label="Differential Pressure - Average"
                      size={SIZES.MEDIUM}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.DP_AVG,
                        lastReading.diffPressureAvg
                      )}
                    </Block>
                    <Block
                      className="white bg-orange t-shadow-1"
                      label="Differential Pressure - High"
                      size={SIZES.MEDIUM}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.DP_HIGH,
                        lastReading.diffPressureHigh
                      )}
                    </Block>
                  </div>
                ) : (
                  <div className="flex flex-wrap">
                    <Block
                      className="white bg-dark-blue t-shadow-1"
                      label="Temperature"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.TEMPERATURE,
                        lastReading.temp
                      )}
                    </Block>
                    <Block
                      className="white bg-green t-shadow-1"
                      label="Humidity"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.HUMIDITY,
                        lastReading.hum
                      )}
                    </Block>
                    <Block
                      className="white bg-light-purple t-shadow-1"
                      label="Dust"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.DUST,
                        lastReading.dust
                      )}
                    </Block>
                    <Block
                      className="white bg-orange t-shadow-1"
                      label="Pressure"
                      size={SIZES.LARGE}
                    >
                      {this.renderReading(
                        SENSOR_READING_TYPES.PRESSURE,
                        lastReading.pres
                      )}
                    </Block>
                  </div>
                )}
              </div>
            )
          ) : (
            <div className={readingsPrefix}>
              <div className="flex items-center justify-center h-100">
                <H4>No Recent Reading Available</H4>
              </div>
            </div>
          )}
        </div>
        {(!!description || !!currentLocation.tags.length) && (
          <Fragment>
            <Divider />
            <div className="flex flex-wrap">
              <Block size={SIZES.LARGE} label="Description">
                {description}
              </Block>
              <Block size={SIZES.LARGE} label="Tags">
                {!!currentLocation.tags.length && (
                  <ul className="pl4 mb0">
                    {currentLocation.tags.map(tag => (
                      <li key={tag.id}>{tag.name}</li>
                    ))}
                  </ul>
                )}
              </Block>
            </div>
          </Fragment>
        )}
        <Divider />
        {!flowMonitorPillarId &&
          hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) && (
            <Fragment>
              <div className="flex-l items-center">
                <div className="w-100 w-25-l">
                  <Checkbox
                    type="checkbox"
                    label="Ignore Site Thresholds"
                    input={{
                      checked: skipSiteThresholds,
                      onChange: value =>
                        toggleLocationOption({
                          ...currentLocation,
                          skipSiteThresholds: value,
                        }),
                    }}
                  />
                </div>
                <div className="w-100 w-25-l">
                  <Checkbox
                    type="checkbox"
                    label="Mute Notifications"
                    input={{
                      checked: currentLocation.notificationMuted,
                      onChange: value =>
                        toggleLocationOption({
                          ...currentLocation,
                          notificationMuted: value,
                        }),
                    }}
                  />
                </div>
                <div className="w-100 w-25-l mr3-l">
                  <Checkbox
                    label="Mute Offline Smart Pod Notifications"
                    type="checkbox"
                    input={{
                      checked: currentLocation.systemNotificationMuted,
                      onChange: value =>
                        toggleLocationOption({
                          ...currentLocation,
                          systemNotificationMuted: value,
                        }),
                    }}
                  />
                </div>
              </div>
              <Divider />
            </Fragment>
          )}
        {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) ? (
          <Switch>
            <Route
              path={`${match.url}/`}
              exact
              render={props => <Redirect to={`${match.url}/chart`} />}
            />
            <Fragment>
              <Tabs
                defaultActiveKey="chart"
                type="card"
                activeKey={this.state.activeTab}
                onTabClick={this.handleTabSelection}
              >
                {!flowMonitorPillarId && (
                  <TabPane tab="Chart" key="chart">
                    <Route
                      path={`${match.url}/chart`}
                      exact
                      render={props => (
                        <Fragment>
                          <div className="charts">
                            <div className="mb2 flex-ns">
                              <DateRange
                                label="Select date range to view location readings"
                                startValue={startValue}
                                endValue={endValue}
                                onClose={this.onCloseDateRange}
                              />
                              <Select
                                className="ml4-ns"
                                label="Smoothing Interval"
                                input={{
                                  value: smoothInterval,
                                  onChange: x => this.handleFilterChange(x),
                                }}
                              >
                                {Object.keys(SMOOTH_INTERVAL).map(x => (
                                  <Option value={SMOOTH_INTERVAL[x]} key={x}>
                                    {toTitleCase(x)}
                                  </Option>
                                ))}
                              </Select>
                            </div>
                            <SensorChart
                              data={currentLocationChartData}
                              columns={currentLocationChartColumns}
                              updateQueryParams={updateQueryParams}
                              isLoading={isChartDataLoading}
                              locationName={locationName}
                              isLeakPod={isLeakPod}
                              podPillarId={podPillarId}
                            />
                          </div>
                          <Divider />
                          <div className="flex flex-wrap">
                            {podPillarId && (
                              <Block label="Smart Pod Pillar ID">
                                <Anchor to={`/inventory/pods/${podPillarId}`}>
                                  {podPillarId}
                                </Anchor>
                              </Block>
                            )}
                            {!skipSiteThresholds && siteThresholds.length > 0 && (
                              <Block label="Site Thresholds">
                                <ul className="pl4 mb0">
                                  {siteThresholds.map(x => (
                                    <li key={x.id}>
                                      <Anchor
                                        to={`/rules/thresholds/${x.thresholdId}`}
                                      >
                                        {x.thresholdName}
                                      </Anchor>
                                    </li>
                                  ))}
                                </ul>
                              </Block>
                            )}
                            {thresholds.length > 0 && (
                              <Block label="Location Thresholds">
                                <ul className="pl4 mb0">
                                  {thresholds.map(x => (
                                    <li key={x.id}>
                                      <Anchor
                                        to={`/rules/thresholds/${x.thresholdId}`}
                                      >
                                        {x.thresholdName}
                                      </Anchor>
                                    </li>
                                  ))}
                                </ul>
                              </Block>
                            )}
                          </div>
                        </Fragment>
                      )}
                    />
                  </TabPane>
                )}
                {flowMonitorPillarId && isFlowMonitorEnabled && (
                  <TabPane tab="Water Monitor Chart" key="flow-monitor-chart">
                    <Route
                      path={`${match.url}/flow-monitor-chart`}
                      exact
                      render={props => (
                        <FlowMonitorData
                          pillarId={id}
                          isByLocation
                          updateQueryParams={updateQueryParams}
                          isLoading={isChartDataLoading}
                          getFlowMonitorData={getFlowMonitorData}
                          flowMonitorData={flowMonitorData}
                          {...props}
                        />
                      )}
                    />
                    <Divider />
                    <div className="flex flex-wrap">
                      {flowMonitorPillarId && (
                        <Block label="Water Monitor">
                          <Anchor
                            to={`/inventory/flow-monitors/${flowMonitorId}`}
                          >
                            {flowMonitorPillarId}
                          </Anchor>
                        </Block>
                      )}
                      {thresholds.length > 0 && (
                        <Block label="Location Thresholds">
                          <ul className="pl4 mb0">
                            {thresholds.map(x => (
                              <li key={x.id}>
                                <Anchor
                                  to={`/rules/thresholds/${x.thresholdId}`}
                                >
                                  {x.thresholdName}
                                </Anchor>
                              </li>
                            ))}
                          </ul>
                        </Block>
                      )}
                    </div>
                  </TabPane>
                )}
                <TabPane tab="Alert Timeline" key="alert-timeline">
                  <Route
                    path={`${match.url}/alert-timeline`}
                    exact
                    render={props => (
                      <UptimeChart
                        uptimeData={locationUptimeData}
                        getLocationUptimeData={getLocationUptimeData}
                        updateQueryParams={updateQueryParams}
                        isChartDataLoading={isChartDataLoading}
                        isByLocation
                        {...this.props}
                      />
                    )}
                  />
                </TabPane>
                <TabPane tab="Location Alert History" key="alert-history">
                  <Route
                    path={`${match.url}/alert-history`}
                    exact
                    render={props =>
                      flowMonitorPillarId ? (
                        <AllFlowAlerts
                          getLocationAlertHistory={getLocationAlertHistory}
                          alerts={locationAlertHistory}
                          accountProfile={accountProfile}
                          windowWidth={windowWidth}
                          isLoading={isHistoryLoading}
                          sectionTitle="Location Alert History"
                          locationId={id}
                          locationFloorId={floorId}
                          locationSiteSlug={siteSlug}
                          meta={meta}
                          isLocationAlertHistory
                        />
                      ) : (
                        <AllPodAlerts
                          getLocationAlertHistory={getLocationAlertHistory}
                          alerts={locationAlertHistory}
                          accountProfile={accountProfile}
                          windowWidth={windowWidth}
                          isLoading={isHistoryLoading}
                          sectionTitle="Location Alert History"
                          locationId={id}
                          locationFloorId={floorId}
                          locationSiteSlug={siteSlug}
                          meta={meta}
                          isLocationAlertHistory
                        />
                      )
                    }
                  />
                </TabPane>
                <TabPane
                  tab="Location Assignment History"
                  key="assignment-history"
                >
                  <Route
                    path={`${match.url}/assignment-history`}
                    exact
                    render={props => (
                      <AssignmentHistory
                        assignmentHistory={podAssignmentHistory}
                        getAssignmentHistory={getPodAssignmentHistory}
                        locationId={id}
                        floorId={floorId}
                        siteSlug={siteSlug}
                        resourceId={podPillarId}
                        isLoading={isHistoryLoading}
                        isByLocation={true}
                      />
                    )}
                  />
                </TabPane>
                {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
                  <TabPane tab="Reliability Index Metrics" key="ri-metrics">
                    <Route
                      path={`${match.url}/ri-metrics`}
                      exact
                      render={props => (
                        <RiMetrics
                          updateQueryParams={updateQueryParams}
                          getRiMetrics={getRiMetrics}
                          riMetrics={riMetrics}
                          siteSlug={siteSlug}
                          isLoading={isHistoryLoading}
                          floorId={floorId}
                          locationId={id}
                        />
                      )}
                    />
                  </TabPane>
                )}
              </Tabs>
            </Fragment>
          </Switch>
        ) : (
          <div>
            {!flowMonitorPillarId && (
              <div className="charts">
                <div className="mb2">
                  <DateRange
                    label="Select date range to view location readings"
                    startValue={startValue}
                    endValue={endValue}
                    onClose={this.onCloseDateRange}
                    shouldLimitRange
                  />
                </div>
                <SensorChart
                  data={currentLocationChartData}
                  columns={currentLocationChartColumns}
                  updateQueryParams={updateQueryParams}
                  isLoading={isChartDataLoading}
                  locationName={locationName}
                  podPillarId={podPillarId}
                  isLeakPod={isLeakPod}
                />
                <div>
                  <Divider />
                  <H4>Thresholds</H4>
                  <div className="flex flex-wrap">
                    {thresholds.length > 0 && (
                      <Block label="Location Thresholds" size={SIZES.LARGE}>
                        <ul className="pl4 mb0">
                          {thresholds.map(x => (
                            <li key={x.id}>{x.thresholdName}</li>
                          ))}
                        </ul>
                      </Block>
                    )}
                    {siteThresholds.length > 0 && (
                      <Block label="Site Thresholds" size={SIZES.LARGE}>
                        <ul className="pl4 mb0">
                          {siteThresholds.map(x => (
                            <li key={x.id}>{x.thresholdName}</li>
                          ))}
                        </ul>
                      </Block>
                    )}
                  </div>
                </div>
              </div>
            )}
            {flowMonitorPillarId && isFlowMonitorEnabled && (
              <div>
                <FlowMonitorData
                  pillarId={id}
                  isByLocation
                  updateQueryParams={updateQueryParams}
                  isLoading={isChartDataLoading}
                  getFlowMonitorData={getFlowMonitorData}
                  flowMonitorData={flowMonitorData}
                />
                <div>
                  <Divider />
                  <H4>Thresholds</H4>
                  <div className="flex flex-wrap">
                    {thresholds.length > 0 && (
                      <Block label="Location Thresholds" size={SIZES.LARGE}>
                        <ul className="pl4 mb0">
                          {thresholds.map(x => (
                            <li key={x.id}>{x.thresholdName}</li>
                          ))}
                        </ul>
                      </Block>
                    )}
                  </div>
                </div>
              </div>
            )}
            {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
              <div>
                <Divider />
                <H4>Comments</H4>
                <AllComments
                  resourceType={RESOURCE_TYPES.LOCATION}
                  resourceSlug={slug}
                  isCommentLoading={isCommentLoading}
                  getAllComments={getAllComments}
                  deleteComment={deleteComment}
                  createComment={createComment}
                  editComment={editComment}
                  setCommentTime={this.setCommentTime}
                  getAllTags={getAllTags}
                  startTime={commentStartTime}
                  endTime={commentEndTime}
                  tags={tags}
                  comments={comments}
                />
              </div>
            )}
          </div>
        )}
      </section>
    )
  }
}

export default Location
