import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import NetworkSelect from '../NetworkSelect'
import { ActionAnchor } from '../common/Anchor'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import { H4 } from '../common/Headers'
import { required, isNumeric } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import {
  POD_STATUS,
  POD_TYPES,
  BATTERY_STATE,
  DEFECTIVE_SENSOR_TYPES,
  ROLES,
  USER_PERMISSIONS,
} from '../../constants'
import { hasRole, hasPermission } from '../../utils/hasPermission'

class EditPodForm extends Component {
  static propTypes = {
    tags: PropTypes.arrayOf(PropTypes.object),
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    podMetadata: PropTypes.object,
    initialValues: PropTypes.object,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  state = {
    isNewFirmwareVersion: false,
    podType: undefined,
  }

  handleToggleFirmwareVersionInput = () =>
    this.setState({ isNewFirmwareVersion: !this.state.isNewFirmwareVersion })

  componentWillReceiveProps(nextProps) {
    if (!this.state.podType && !!nextProps.initialValues.podType) {
      this.setState({ podType: nextProps.initialValues.podType })
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      assetLocations,
      podMetadata,
      initialValues,
      tags,
    } = this.props
    const { isNewFirmwareVersion, podType } = this.state
    const {
      firmwareVersions,
      productVersions,
      leakFirmwareVersions,
      leakProductVersions,
    } = podMetadata
    const selectedFirmwareVersions =
      podType === POD_TYPES.LEAK_POD ? leakFirmwareVersions : firmwareVersions
    const selectedProductVersions =
      podType === POD_TYPES.LEAK_POD ? leakProductVersions : productVersions

    const submit = handleSubmit(submitAction)

    return (
      <section className="EditPodForm">
        <Form onSubmit={submit}>
          <div className="flex-ns">
            <Field
              name="pillarId"
              type="text"
              component={InputContainer}
              label="Pillar ID"
              validate={[required]}
              disabled={!hasRole(ROLES.SUPER_ADMIN)}
              className="w-50-ns mr3-ns"
            />
            <Field
              name="moduleId"
              type="text"
              component={InputContainer}
              label="Module ID"
              validate={[required]}
              className="w-50-ns"
            />
          </div>
          <div className="flex-ns">
            <Field
              name="podType"
              component={SelectContainer}
              label="Type"
              className={initialValues ? 'w-third-ns mr3-ns' : 'w-50-ns mr3-ns'}
              validate={[required]}
              props={{ placeholder: 'Select a POD Type' }}
              onChange={(ev, newValue) => this.setState({ podType: newValue })}
              filterable
            >
              {Object.keys(POD_TYPES).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(POD_TYPES[x])}
                </Option>
              ))}
            </Field>
            {initialValues && (
              <Field
                name="status"
                component={SelectContainer}
                label="Status"
                validate={[required]}
                className="w-third-ns mr3-ns"
                props={{ placeholder: 'Select a Status' }}
                filterable
              >
                {!hasRole(ROLES.SUPER_ADMIN)
                  ? initialValues.statusOptions.map(x => (
                      <Option value={x} key={x}>
                        {toTitleCase(x)}
                      </Option>
                    ))
                  : Object.keys(POD_STATUS).map(x => (
                      <Option value={x} key={x}>
                        {toTitleCase(POD_STATUS[x])}
                      </Option>
                    ))}
              </Field>
            )}
            <Field
              name="assetLocationId"
              component={SelectContainer}
              label="Asset Location"
              className={initialValues ? 'w-third-ns' : 'w-50-ns'}
              disabled={
                initialValues && initialValues.status === POD_STATUS.DEPLOYED
              }
              props={{ placeholder: 'Select an Asset Location' }}
              filterable
            >
              {[{ id: 0, pillarId: '-- Unassigned --' }, ...assetLocations].map(
                x => (
                  <Option value={x.id} key={x.id}>
                    {x.pillarId}
                  </Option>
                )
              )}
            </Field>
          </div>
          <Divider />
          <div className="flex-ns">
            <Field
              name="batteryStartStatus"
              component={SelectContainer}
              label="Battery Start Status"
              className="w-third-ns mr3-ns"
              props={{ placeholder: 'Select a Battery Status' }}
              filterable
            >
              {Object.keys(BATTERY_STATE).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(BATTERY_STATE[x])}
                </Option>
              ))}
            </Field>
            <Field
              name="batteryChangeDate"
              type="date"
              component={DateSelectorContainer}
              label="Battery Start Date"
              format={formatDateUTC}
              normalize={normalizeDate}
              className="w-third-ns mr3-ns"
            />
            <Field
              name="batteryCurrentStatus"
              component={SelectContainer}
              label="Battery Current Status"
              className="w-third-ns"
              props={{ placeholder: 'Select a Battery Status' }}
              filterable
            >
              {Object.keys(BATTERY_STATE).map(x => (
                <Option value={x} key={x}>
                  {toTitleCase(BATTERY_STATE[x])}
                </Option>
              ))}
            </Field>
          </div>
          <Divider />
          <div className="flex-ns">
            <div className="mr3-ns w-25-ns">
              {isNewFirmwareVersion ? (
                <div className="mb3">
                  <Field
                    name="firmwareVersion"
                    type="text"
                    component={InputContainer}
                    label="Firmware Version"
                    validate={[required]}
                    className="w-100"
                    noMargin
                  />
                  <ActionAnchor
                    className="f7"
                    onClick={this.handleToggleFirmwareVersionInput}
                  >
                    Use existing version
                  </ActionAnchor>
                </div>
              ) : (
                <div className="mb3">
                  <Field
                    name="firmwareVersion"
                    component={SelectContainer}
                    label="Firmware Version"
                    validate={[required]}
                    props={{ placeholder: 'Select a firmware version' }}
                    className="w-100"
                    noMargin
                    filterable
                  >
                    {selectedFirmwareVersions
                      .filter(x => !!x)
                      .map(x => (
                        <Option value={x} key={x}>
                          {x}
                        </Option>
                      ))}
                  </Field>
                  <ActionAnchor
                    className="f7"
                    onClick={this.handleToggleFirmwareVersionInput}
                  >
                    Add new version
                  </ActionAnchor>
                </div>
              )}
            </div>
            <Field
              name="productVersion"
              component={SelectContainer}
              label="Product Version"
              className="mr3-ns w-25-ns"
              validate={[required]}
              props={{ placeholder: 'Select a product version' }}
              filterable
            >
              {selectedProductVersions
                .filter(x => !!x)
                .map(x => (
                  <Option value={x} key={x}>
                    {x}
                  </Option>
                ))}
            </Field>
            <Field
              name="builtDate"
              type="date"
              component={DateSelectorContainer}
              label="Date Built"
              format={formatDateUTC}
              normalize={normalizeDate}
              className="w-25-ns mr3-ns"
            />
            <Field
              name="defectiveSensors"
              component={SelectContainer}
              label="Defective Sensors"
              mode="multiple"
              className="w-25-ns"
              props={{ placeholder: 'Select Defective Sensors' }}
              filterable
            >
              {Object.keys(DEFECTIVE_SENSOR_TYPES).map(x => (
                <Option value={DEFECTIVE_SENSOR_TYPES[x]} key={x}>
                  {toTitleCase(x)}
                </Option>
              ))}
            </Field>
          </div>
          {hasPermission(USER_PERMISSIONS.MANAGE_NETWORK) && (
            <>
              <Divider />
              <div className="flex-ns">
                <NetworkSelect className="w-50-ns mr3-ns" />
                <Field
                  name="pinnedGatewayPillarId"
                  component={SelectContainer}
                  label="Pinned Network Gateway"
                  className="w-50-ns mr3-ns"
                  props={{ placeholder: 'Select a Pinned Network Gateway' }}
                >
                  {[null, ...initialValues.availableNetworkGateways].map(x => (
                    <Option value={x} key={x}>
                      {x ? x : 'Unassigned'}
                    </Option>
                  ))}
                </Field>
              </div>
            </>
          )}
          <Divider />
          <div className="flex-ns">
            <Field
              name="description"
              type="text"
              component={InputContainer}
              label="Description"
              className="w-100"
            />
          </div>
          <Divider />
          <div className="flex-ns">
            <Field
              name="tagIds"
              component={SelectContainer}
              label="Tags"
              mode="multiple"
              className="w-100"
              props={{ placeholder: 'Select Tags' }}
              filterable
            >
              {tags.map(tag => (
                <Option value={tag.id} key={tag.id}>
                  {tag.name}
                </Option>
              ))}
            </Field>
          </div>
          <Divider />
          <H4>Sensor Calibration</H4>
          {podType === POD_TYPES.SMART ? (
            <>
              <div className="ba b--moon-gray br2 pa2 mb2">
                <div className="b mb2">Humidity</div>
                <div className="flex-ns">
                  <Field
                    name="calibrationBme680Humidity"
                    type="number"
                    component={InputContainer}
                    label="BME680"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBme680HumidityTwoPointSlope"
                    type="number"
                    component={InputContainer}
                    label="BME680 2 pt Slope"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBme680HumidityTwoPointIntercept"
                    type="number"
                    component={InputContainer}
                    label="BME680 2 pt Intercept"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBme280Humidity"
                    type="number"
                    component={InputContainer}
                    label="BME280"
                    validate={[isNumeric]}
                    className="w-25-ns"
                  />
                </div>
              </div>
              <div className="ba b--moon-gray br2 pa2 mb2">
                <div className="b mb2">Temperature</div>
                <div className="flex-ns">
                  <Field
                    name="calibrationBme680Temperature"
                    type="number"
                    component={InputContainer}
                    label="BME680"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBme280Temperature"
                    type="number"
                    component={InputContainer}
                    label="BME280"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationLmt87Temperature"
                    type="number"
                    component={InputContainer}
                    label="LMT87"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <div className="w-25-ns" />
                </div>
              </div>
              <div className="ba b--moon-gray br2 pa2 mb2">
                <div className="b mb2">Pressure</div>
                <div className="flex-ns">
                  <Field
                    name="calibrationBme680Pressure"
                    type="number"
                    component={InputContainer}
                    label="BME680"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBme280Pressure"
                    type="number"
                    component={InputContainer}
                    label="BME280"
                    validate={[isNumeric]}
                    className="w-25-ns mr3-ns"
                  />
                  <div className="w-25-ns mr3-ns" />
                  <div className="w-25-ns" />
                </div>
              </div>
              <div className="ba b--moon-gray br2 pa2 mb2">
                <div className="b mb2">Other</div>
                <div className="flex-ns">
                  <Field
                    name="calibrationBme680Voc"
                    type="number"
                    component={InputContainer}
                    label="BME680 VOC"
                    validate={[isNumeric]}
                    className="w-20-ns mr3-ns"
                  />
                  <Field
                    name="calibrationSharp1023Dust"
                    type="number"
                    component={InputContainer}
                    label="Sharp1023 Dust"
                    validate={[isNumeric]}
                    className="w-20-ns mr3-ns"
                  />
                  <Field
                    name="calibrationBma222eAcceleration"
                    type="number"
                    component={InputContainer}
                    label="BMA222E Acceleration"
                    validate={[isNumeric]}
                    className="w-20-ns mr3-ns"
                  />
                  <Field
                    name="calibrationOpt3002dnptAmbientLight"
                    type="number"
                    component={InputContainer}
                    label="OPT3002 Ambient Light"
                    validate={[isNumeric]}
                    className="w-20-ns mr3-ns"
                  />
                  <Field
                    name="coCalibration"
                    type="text"
                    component={InputContainer}
                    label="TGS5141 CO"
                    validate={[isNumeric]}
                    className="w-20-ns"
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="ba b--moon-gray br2 pa2 mb2">
              <div className="flex-ns">
                <Field
                  name="calibrationShtc3Temperature"
                  type="number"
                  component={InputContainer}
                  label="Temperature SHTC3"
                  validate={[isNumeric]}
                  className="w-25-ns mr3-ns"
                />
                <Field
                  name="calibrationShtc3Humidity"
                  type="number"
                  component={InputContainer}
                  label="Humidity SHTC3"
                  validate={[isNumeric]}
                  className="w-25-ns mr3-ns"
                />
                <div className="w-25-ns" />
              </div>
            </div>
          )}
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation="/inventory/all/pods" />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditPodForm' })(EditPodForm)
