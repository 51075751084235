import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import { Spin } from '../common/Ant'
import { Bread, Crumb } from '../common/Bread'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import { Option } from '../common/Select'
import SelectContainer from '../../containers/common/SelectContainer'
import DateSelectorContainer from '../../containers/common/DateSelectorContainer'
import Button from '../common/Button'
import Divider from '../common/Divider'
import FormError from '../common/FormError'
import CancelButton from '../common/CancelButton'
import Logo from '../common/Logo'
import { Anchor } from '../common/Anchor'
import { normalizeDate, formatDateUTC } from '../common/DateSelector'
import { updateDeploymentEstimatesAction } from '../../actions/deploymentsActions'
import { required, isNumeric } from '../../utils/validators'
import { dateTimeFormatter } from '../../utils/date'

class EditDeploymentForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    error: PropTypes.string,
    site: PropTypes.object.isRequired,
    deployment: PropTypes.object.isRequired,
    getSite: PropTypes.func.isRequired,
    getDeployment: PropTypes.func.isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object,
    isDeploymentLoading: PropTypes.bool.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
  }

  componentDidMount() {
    const { siteSlug, deploymentSlug } = this.props.match.params
    const { getDeployment, getSite } = this.props

    getSite(siteSlug, false, true)
    getDeployment(siteSlug, deploymentSlug)
  }

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      site,
      deployment,
      siteUsers,
      isSiteLoading,
      isDeploymentLoading,
    } = this.props

    const submit = handleSubmit(updateDeploymentEstimatesAction)

    return isDeploymentLoading || isSiteLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditDeploymentForm">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {site.slug && (
              <Crumb>
                <Anchor to={`/sites/${site.slug}`}>{site.name}</Anchor>
              </Crumb>
            )}
            <Crumb>
              <Anchor to={`/sites/${site.slug}/deployments`}>
                Deployments
              </Anchor>
            </Crumb>
            <Crumb>
              {deployment.description
                ? deployment.description
                : dateTimeFormatter(deployment.estimatedDate, true)}
            </Crumb>
          </Bread>
        </div>
        <div className="flex justify-center mb3">
          <Logo companyName={site.companyName} companyLogo={site.companyLogo} />
        </div>
        <Divider />
        <Form onSubmit={submit}>
          <div className="flex-ns">
            <Field
              name="estimatedDate"
              type="date"
              className="w-25-ns mr1-ns w-100"
              component={DateSelectorContainer}
              label="Estimated Date"
              format={formatDateUTC}
              normalize={normalizeDate}
              validate={[required]}
              disabledDateRangeType="before"
            />
            <Field
              name="responsiblePartyUserId"
              component={SelectContainer}
              label="Responsible Party"
              placeholder="Select a user to handle this Deployment"
              className="w-75-ns w-100"
              filterable
            >
              {siteUsers.map(x => (
                <Option value={x.id} key={x.id}>
                  {x.firstName} {x.lastName}
                </Option>
              ))}
            </Field>
          </div>
          <div className="flex-ns">
            <Field
              name="estimatedPods"
              type="text"
              component={InputContainer}
              label="Estimated Smart Pods"
              validate={[isNumeric, required]}
              className="mr3-ns w-20-ns w-100"
            />
            <Field
              name="estimatedLeakPods"
              type="text"
              component={InputContainer}
              label="Estimated Leak Pucks"
              validate={[isNumeric, required]}
              className="mr3-ns w-20-ns w-100"
            />
            <Field
              name="estimatedGateways"
              type="text"
              component={InputContainer}
              label="Estimated Network Gateways"
              validate={[isNumeric, required]}
              className="mr3-ns w-20-ns w-100"
            />
            <Field
              name="estimatedFlowMonitors"
              type="text"
              component={InputContainer}
              label="Estimated Water Monitors"
              validate={[isNumeric, required]}
              className="w-20-ns w-100"
            />
          </div>
          <Divider />
          <FormError error={error} />
          <div className="flex justify-between">
            <CancelButton defaultLocation={`/sites/${site.slug}/deployments`} />
            <Button text="Submit" type="submit" submitting={submitting} />
          </div>
        </Form>
      </section>
    )
  }
}

export default reduxForm({ form: 'EditDeploymentForm' })(EditDeploymentForm)
