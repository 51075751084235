import {
  SITE_STATUS,
  SIM_CARRIERS,
  SHIPPING_VENDOR,
  REPORT_TYPES,
  THRESHOLD_GROUP_TYPES,
  POD_TYPES,
  THRESHOLD_TYPES,
} from '../constants'
import verizon from '../assets/verizon.svg'
import aTAndT from '../assets/a-t-and-t.svg'
import tMobile from '../assets/t-mobile.svg'
import telit from '../assets/telit.svg'
import twilio from '../assets/twilio.svg'
import cleanRoom from '../assets/group--clean-room.svg'
import occupationalSafety from '../assets/group--co-occupational-safety.svg'
import fire from '../assets/group--fire.svg'
import freezingTemps from '../assets/group--freezing-temperatures.svg'
import generalCleanliness from '../assets/group--general-cleanliness.svg'
import highHeat from '../assets/group--high-heat.svg'
import materialCondition from '../assets/group--material-condition.svg'
import moldGrowth from '../assets/group--mold.svg'
import cleanRoomDark from '../assets/group--clean-room--dark.svg'
import occupationalSafetyDark from '../assets/group--co-occupational-safety--dark.svg'
import fireDark from '../assets/group--fire--dark.svg'
import freezingTempsDark from '../assets/group--freezing-temperatures--dark.svg'
import generalCleanlinessDark from '../assets/group--general-cleanliness--dark.svg'
import highHeatDark from '../assets/group--high-heat--dark.svg'
import materialConditionDark from '../assets/group--material-condition--dark.svg'
import moldGrowthDark from '../assets/group--mold--dark.svg'

const IGNORE_LIST = ['VOC', 'PDS']

const toTitleCase = str => {
  if (!str || IGNORE_LIST.indexOf(str) !== -1) {
    return str
  }

  return (
    str
      .split('_')
      .join(' ')
      .replace(
        /\w\S*/g,
        txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
      )
      .replace(/Readonly/, 'Read-Only')
      .replace(/Onsite/, 'On-Site')
      .replace(/Rssi/, 'RSSI')
      .replace(/Sub Contractor/, 'Sub-Contractor')
      // .replace(/\bPod\b/, 'POD')
      .replace(/\bOta\b/, 'OTA')
      .replace(/Sms/, 'SMS')
      .replace(/Flow Value/, 'Water Flow')
  )
}

const toTitleCaseArray = str => {
  if (!str || IGNORE_LIST.indexOf(str) !== -1) {
    return str
  }

  return str
    .split(',')
    .join(' ')
    .replace(
      /\w\S*/g,
      txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
    .replace(/Sms/, 'SMS')
    .split(' ')
    .join(', ')
}

const toCamelCase = str => {
  if (!str) return str

  let words = str.split(' ')
  const newFirstWord = words[0].toLowerCase()
  words[0] = newFirstWord
  return words.join('')
}

const snakeCaseToCamelCase = str => {
  if (!str) return str

  let words = str.split('_')
  const newFirstWord = words[0].toLowerCase()
  words[0] = newFirstWord

  for (let everyWord = 1; everyWord < words.length; everyWord++) {
    let oneWord = null
    let singleWord = words[everyWord]
    for (let everyLetter = 0; everyLetter < singleWord.length; everyLetter++) {
      if (everyLetter === 0) oneWord = singleWord[everyLetter].toUpperCase()
      else {
        words[everyWord] = oneWord.concat(singleWord[everyLetter].toLowerCase())
        oneWord = words[everyWord]
      }
    }
    words[everyWord] = oneWord
  }
  return words.join('')
}

const renderSiteStatus = status => {
  switch (status) {
    case SITE_STATUS.PLANNING:
      return 'Planning'
    case SITE_STATUS.PROPOSED:
      return 'Proposed'
    case SITE_STATUS.ACTIVE:
      return 'Active'
    case SITE_STATUS.COMPLETED:
      return 'Completed'
    case SITE_STATUS.INSTALLING:
      return 'Installing'
    case SITE_STATUS.UNINSTALL:
      return 'Uninstall'
  }
}

const formatSIMCarrier = type => {
  switch (type) {
    case SIM_CARRIERS.ATT:
      return 'AT&T'
    case SIM_CARRIERS.VERIZON:
      return 'Verizon'
    case SIM_CARRIERS.T_MOBILE:
      return 'T-Mobile'
    case SIM_CARRIERS.TELIT:
      return 'Telit'
    case SIM_CARRIERS.TWILIO:
      return 'Twilio'
    default:
      return type
  }
}

const formatSIMCarrierIconName = type => {
  switch (type) {
    case 'AT&T':
      return aTAndT
    case 'Verizon':
      return verizon
    case 'T-Mobile':
      return tMobile
    case 'Telit':
      return telit
    case 'Twilio':
      return twilio
    default:
      return type
  }
}

const formatShippingVendors = type => {
  switch (type) {
    case SHIPPING_VENDOR.UPS:
      return 'UPS'
    case SHIPPING_VENDOR.FEDEX:
      return 'FedEx'
    case SHIPPING_VENDOR.USPS:
      return 'USPS'
    default:
      return toTitleCase(type)
  }
}

const getShippingVendorsURL = (type, trackingNumber) => {
  switch (type) {
    case SHIPPING_VENDOR.UPS:
      return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}&requester=WT/`
    case SHIPPING_VENDOR.FEDEX:
      return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`
    case SHIPPING_VENDOR.USPS:
      return `https://tools.usps.com/go/TrackConfirmAction?tLabels=${trackingNumber}`
  }
}

const renderReportType = type => {
  switch (type) {
    case REPORT_TYPES.LOCATIONS_PER_GRAPH:
      return 'All Locations'
    case REPORT_TYPES.FLOOR_LOCATIONS_PER_GRAPH:
      return 'All Locations by Floor'
    case REPORT_TYPES.FLOOR_AVG_PER_GRAPH:
      return 'Reading Average by Floor'
    case REPORT_TYPES.ALL_FLOORS_AVG_PER_GRAPH:
      return 'Reading Average of all Floors'
    case REPORT_TYPES.LOCATION_GROUPS_PER_GRAPH:
      return 'All Locations by Room'
  }
}

const renderThresholdType = type => {
  switch (type) {
    case THRESHOLD_TYPES.LEVEL:
      return 'Level'
    case THRESHOLD_TYPES.LEVEL_OVER_DURATION:
      return 'Level over duration of time'
    case THRESHOLD_TYPES.EQUAL:
      return 'Equal'
  }
}

const renderThresholdGroupType = type => {
  switch (type) {
    case THRESHOLD_GROUP_TYPES.HIGH_HEAT:
      return 'High Heat'
    case THRESHOLD_GROUP_TYPES.FIRE:
      return 'Fire'
    case THRESHOLD_GROUP_TYPES.FREEZING_TEMPERATURES:
      return 'Freezing Temperatures'
    case THRESHOLD_GROUP_TYPES.CO_OCCUPATIONAL_SAFETY:
      return 'CO Occupational Safety'
    case THRESHOLD_GROUP_TYPES.CLEAN_ROOM:
      return 'Clean Room'
    case THRESHOLD_GROUP_TYPES.GENERAL_CLEANLINESS:
      return 'General Cleanliness'
    case THRESHOLD_GROUP_TYPES.MATERIAL_CONDITION:
      return 'Material Condition'
    case THRESHOLD_GROUP_TYPES.MOLD_GROWTH:
      return 'Mold Growth'
  }
}

const renderThresholdGroupSVG = (type, darkMode) => {
  switch (type) {
    case THRESHOLD_GROUP_TYPES.HIGH_HEAT:
      return darkMode ? highHeatDark : highHeat
    case THRESHOLD_GROUP_TYPES.FIRE:
      return darkMode ? fireDark : fire
    case THRESHOLD_GROUP_TYPES.FREEZING_TEMPERATURES:
      return darkMode ? freezingTempsDark : freezingTemps
    case THRESHOLD_GROUP_TYPES.CO_OCCUPATIONAL_SAFETY:
      return darkMode ? occupationalSafetyDark : occupationalSafety
    case THRESHOLD_GROUP_TYPES.CLEAN_ROOM:
      return darkMode ? cleanRoomDark : cleanRoom
    case THRESHOLD_GROUP_TYPES.GENERAL_CLEANLINESS:
      return darkMode ? generalCleanlinessDark : generalCleanliness
    case THRESHOLD_GROUP_TYPES.MATERIAL_CONDITION:
      return darkMode ? materialConditionDark : materialCondition
    case THRESHOLD_GROUP_TYPES.MOLD_GROWTH:
      return darkMode ? moldGrowthDark : moldGrowth
  }
}

const renderPodType = type => {
  switch (type) {
    case POD_TYPES.LEAK_POD:
      return 'Leak'
    case POD_TYPES.SMART:
    default:
      return 'Smart'
  }
}

export {
  toTitleCase,
  renderSiteStatus,
  toCamelCase,
  snakeCaseToCamelCase,
  formatSIMCarrier,
  formatSIMCarrierIconName,
  formatShippingVendors,
  toTitleCaseArray,
  getShippingVendorsURL,
  renderReportType,
  renderThresholdGroupType,
  renderThresholdGroupSVG,
  renderPodType,
  renderThresholdType,
}
