import produce from 'immer'
import { combineReducers } from 'redux'
import { camelize } from 'humps'
import moment from 'moment'
import {
  getAllPodsAction,
  getAllAvailablePodsAction,
  getAllAvailablePodsBySiteAction,
  getPodAction,
  getPodChartDataAction,
  getPodMetadataAction,
  getAllPodFirmwareVersionsAction,
  getPodEditHistoryAction,
  getPodAssignmentHistoryAction,
  batchUpdatePodsAction,
  uploadPodsCSVAction,
  getPodAssetsAction,
  getPodWorkflowResponsesAction,
  getPodWorkflowResponseAction,
  getPodCalibrationHistoryAction,
  getMultiplePodsDataAction,
} from '../actions/podsActions'
import { toTitleCase } from '../utils/textFormatters'
import { showErrorMessageAction } from '../actions/uiActions'
import {
  normalizeAmbientLightReading,
  pickErrorMessage,
} from '../utils/helpers'
import { POD_ASSET_TYPES, POD_TYPES } from '../constants'

const byId = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllPodsAction.SUCCESS:
        payload.items.forEach(pod => {
          draft[pod.id] = pod
        })
        break
      case batchUpdatePodsAction.SUCCESS:
        payload.forEach(pod => {
          draft[pod.id] = { ...draft[pod.id], ...pod }
        })
        break
    }
  })

const visibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPodsAction.SUCCESS:
        draft.splice(
          0,
          draft.length,
          ...action.payload.items.map(pod => pod.id)
        )
        break
    }
  })

const availableById = (state = {}, action) =>
  produce(state, draft => {
    const { type, payload } = action

    switch (type) {
      case getAllAvailablePodsAction.SUCCESS:
      case getAllAvailablePodsBySiteAction.SUCCESS:
        payload.forEach(pod => {
          draft[pod.id] = pod
        })
        break
    }
  })

const availableVisibleIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailablePodsAction.SUCCESS:
      case getAllAvailablePodsBySiteAction.SUCCESS:
        const result = action.payload
          .filter(x => x.podType === POD_TYPES.SMART)
          .map(pod => pod.id)
        if (result.length > 0) {
          draft.splice(0, draft.length, ...result)
        }
        break
    }
  })

const availableVisibleLeakPodIds = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllAvailablePodsAction.SUCCESS:
      case getAllAvailablePodsBySiteAction.SUCCESS:
        const result = action.payload
          .filter(x => x.podType === POD_TYPES.LEAK_POD)
          .map(pod => pod.id)

        if (result.length > 0) {
          draft.splice(0, draft.length, ...result)
        }
        break
    }
  })

const podMetadata = (
  state = {
    firmwareVersions: [],
    productVersions: [],
    builtDates: [],
    leakFirmwareVersions: [],
    leakProductVersions: [],
    leakBuiltDates: [],
    statusOptions: {},
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodMetadataAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const csvUploadResults = (
  state = {
    addedCount: 0,
    existsCount: 0,
    failedCount: 0,
    exists: [],
    failed: [],
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case uploadPodsCSVAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const firmwareVersions = (state = [], { type, payload = [] }) =>
  produce(state, draft => {
    switch (type) {
      case getAllPodFirmwareVersionsAction.SUCCESS:
        return payload
    }
  })

const meta = (
  state = {
    statusCounts: [],
    firmwareCounts: [],
    productVersionCounts: [],
    total: 0,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getAllPodsAction.SUCCESS:
        Object.assign(draft, action.payload.meta)
        break
    }
  })

const current = (
  state = {
    availableNetworkGateways: [],
    statusOptions: [],
    defectiveSensors: [],
    tags: [],
    tagIds: [],
    calibrationBme680Humidity: 0,
    calibrationBme680Temperature: 0,
    calibrationBme680Pressure: 0,
    calibrationBme680Voc: 0,
    calibrationBme280Humidity: 0,
    calibrationBme280Temperature: 0,
    calibrationBme280Pressure: 0,
    calibrationLmt87Temperature: 0,
    calibrationSharp1023Dust: 0,
    calibrationBma222eAcceleration: 0,
    calibrationOpt3002dnptAmbientLight: 0,
    calibrationBme680HumidityTwoPointIntercept: 0,
    calibrationBme680HumidityTwoPointSlope: 0,
    coCalibration: 0,
  },
  action
) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodAction.SUCCESS:
        Object.assign(draft, action.payload)
        break
    }
  })

const chartData = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodChartDataAction.SUCCESS:
        const data = action.payload.data.map(x => {
          const datum = normalizeAmbientLightReading(x)
          return {
            ...datum,
            time: moment(datum.time).valueOf(),
          }
        })
        draft.splice(0, draft.length, ...data)
        break
    }
  })

const chartColumns = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getPodChartDataAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.columns.map(camelize).sort())
        break
    }
  })

const editHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodEditHistoryAction.SUCCESS:
        const editHistory = action.payload.items
        const newEditHistory = []

        if (editHistory.length) {
          editHistory.forEach((row, i) => {
            const { updatedBy, date, changedFields } = row
            let changes
            try {
              changes = JSON.parse(changedFields)
            } catch (error) {
              showErrorMessageAction(pickErrorMessage(error))
            }

            // each row has an updatedBy, date, field changed, to, from
            if (changes) {
              Object.keys(changes).forEach((field, j) => {
                let to = changes[field].to
                let from = changes[field].from

                // Dates are in the format YYYY/MM/DD
                try {
                  to = JSON.parse(to)
                } catch (error) {
                  if (!to) {
                    to = '--'
                  } else if (field === 'built_date') {
                    to = to
                      .split('-')
                      .map(date => parseInt(date.trim(), 10))
                      .join('/')
                  } else if (field === 'status') {
                    to = toTitleCase(to)
                  } else {
                    showErrorMessageAction(pickErrorMessage(error))
                  }
                }

                try {
                  from = JSON.parse(from)
                } catch (error) {
                  if (!from) {
                    from = '--'
                  } else if (field === 'built_date') {
                    from = from
                      .split('-')
                      .map(date => parseInt(date.trim(), 10))
                      .join('/')
                  } else if (field === 'status') {
                    from = toTitleCase(from)
                  } else {
                    showErrorMessageAction(pickErrorMessage(error))
                  }
                }

                // unique IDs are hard to come by...
                const tableId = i.toString() + j.toString()

                // push the new value
                newEditHistory.push({
                  updatedBy,
                  date,
                  field,
                  to,
                  from,
                  tableId,
                })
              })
            }
          })
        }

        draft.splice(0, draft.length, ...newEditHistory)
        break
    }
  })

const assignmentHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodAssignmentHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items)
        break
    }
  })

const calibrationHistory = (state = [], action) =>
  produce(state, draft => {
    switch (action.type) {
      case getPodCalibrationHistoryAction.SUCCESS:
        draft.splice(0, draft.length, ...action.payload.items)
        break
    }
  })

const assets = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getPodAssetsAction.SUCCESS:
        return payload
    }
  })

const workflowResponses = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getPodWorkflowResponsesAction.SUCCESS:
        return payload
    }
  })

const currentWorkflowResponse = (
  state = { userResponse: [] },
  { type, payload }
) =>
  produce(state, draft => {
    switch (type) {
      case getPodWorkflowResponseAction.SUCCESS:
        return payload[0]
    }
  })

const timeSeriesDataByPillarId = (state = {}, { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getMultiplePodsDataAction.SUCCESS:
        payload.pods.forEach(x => (draft[x.info.pillarId] = x))
        break
    }
  })

const timeSeriesDataVisiblePillarIds = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getMultiplePodsDataAction.SUCCESS:
        draft.splice(0, draft.length, ...payload.pods.map(x => x.info.pillarId))
        break
    }
  })

const podsReducer = combineReducers({
  byId,
  visibleIds,
  availableById,
  availableVisibleIds,
  availableVisibleLeakPodIds,
  current,
  chartData,
  chartColumns,
  meta,
  podMetadata,
  firmwareVersions,
  assignmentHistory,
  calibrationHistory,
  editHistory,
  csvUploadResults,
  assets,
  workflowResponses,
  currentWorkflowResponse,
  timeSeriesDataByPillarId,
  timeSeriesDataVisiblePillarIds,
})

const getPod = (state, id) => state.byId[id]
const getAvailablePod = (state, id) => state.availableById[id]
const getCurrentPod = ({ current }) => current
const getCurrentPodChartData = ({ chartData }) => chartData
const getCurrentPodChartColumns = ({ chartColumns }) => chartColumns
const getVisiblePods = state => state.visibleIds.map(id => getPod(state, id))
const getPodsMeta = state => state.meta
const getAvailableVisiblePods = state =>
  state.availableVisibleIds.map(id => getAvailablePod(state, id))
const getAvailableVisibleLeakPods = state =>
  state.availableVisibleLeakPodIds.map(id => getAvailablePod(state, id))
const getPodMetadata = state => state.podMetadata
const getPodFirmwareVersions = ({ firmwareVersions }) => firmwareVersions
const getVisiblePodEditHistory = state => state.editHistory
const getVisiblePodAssignmentHistory = state => state.assignmentHistory
const getVisiblePodCalibrationHistory = state => state.calibrationHistory
const getCSVUploadResults = state => state.csvUploadResults
const getPodImages = ({ assets }) =>
  assets.filter(x => x.fileType === POD_ASSET_TYPES.IMAGE)
const getPodWorkflowResponses = ({ workflowResponses }) => workflowResponses
const getPodWorkflowResponse = ({ currentWorkflowResponse }) =>
  currentWorkflowResponse
const getTimeSeriesData = (state, pillarId) =>
  state.timeSeriesDataByPillarId[pillarId]
const getVisibleTimeSeriesData = state =>
  state.timeSeriesDataVisiblePillarIds.map(pillarId =>
    getTimeSeriesData(state, pillarId)
  )

export {
  podsReducer as default,
  getCurrentPod,
  getCurrentPodChartData,
  getCurrentPodChartColumns,
  getVisiblePods,
  getPodsMeta,
  getAvailableVisiblePods,
  getAvailableVisibleLeakPods,
  getPodMetadata,
  getPodFirmwareVersions,
  getVisiblePodEditHistory,
  getVisiblePodAssignmentHistory,
  getVisiblePodCalibrationHistory,
  getCSVUploadResults,
  getPodImages,
  getPodWorkflowResponses,
  getPodWorkflowResponse,
  getTimeSeriesData,
  getVisibleTimeSeriesData,
}
