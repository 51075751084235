import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Switch, Route } from 'react-router-dom'
import MainContent from '../common/MainContent'
import AllSites from '../AllSites'
import CreateSiteProposal from '../CreateSiteProposal'
import Site from '../Site'
import EditSite from '../EditSite'
import EditSiteFloorPlan from '../EditSiteFloorPlan'
import EditShipment from '../EditShipment'
import CreateFloor from '../CreateFloor'
import Floor from '../Floor'
import Deployment from '../Deployment'
import EditFloor from '../EditFloor'
import Building from '../Building'
import CreateBuilding from '../CreateBuilding'
import EditBuilding from '../EditBuilding'
import CreateLocation from '../CreateLocation'
import Location from '../Location'
import EditLocation from '../EditLocation'
import CreateDeployment from '../CreateDeployment'
import Packing from '../Packing'
import ShipmentDetail from '../ShipmentDetail'
import EditDeploymentForm from '../forms/EditDeploymentForm'
import MultipleLocationsContainer from '../../containers/MultipleLocationsContainer'
import PageWidth from '../PageWidth'
import { dateTimeFormatter } from '../../utils/date'
import { KEYCODES } from '../../constants'
import { H3 } from '../common/Headers'
import { Modal, Icon } from '../common/Ant'
import FloorPlan from '../FloorPlan'
import { ActionAnchor } from '../common/Anchor'
import EvacuateBuilding from '../EvacuateBuilding'
import EvacuateDisable from '../EvacuateDisable'


import LocationGroups from '../LocationGroups'

class SitesPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentFloorPlanIndex: 0,
      currentFloorPlan: this.props.floors[0] || {},
      isModalVisible: false,
    }
  }

  static propTypes = {
    sites: PropTypes.arrayOf(PropTypes.object),
    locationAlertHistory: PropTypes.arrayOf(PropTypes.object),
    getLocationAlertHistory: PropTypes.func.isRequired,
    podMetadata: PropTypes.object,
    getPodMetadata: PropTypes.func.isRequired,
    currentSite: PropTypes.object.isRequired,
    currentDeployment: PropTypes.object.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    deployments: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableGateways: PropTypes.arrayOf(PropTypes.object),
    getAllSites: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    getAdminSite: PropTypes.func.isRequired,
    deleteSite: PropTypes.func.isRequired,
    getAllCompanies: PropTypes.func.isRequired,
    getAllAvailableGateways: PropTypes.func.isRequired,
    addUserToSite: PropTypes.func.isRequired,
    addSiteContractor: PropTypes.func.isRequired,
    deleteSiteContractor: PropTypes.func.isRequired,
    addGatewayToSite: PropTypes.func.isRequired,
    deleteGatewayFromSite: PropTypes.func.isRequired,
    deleteUserFromSite: PropTypes.func.isRequired,
    getAllBuildings: PropTypes.func.isRequired,
    buildings: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentBuilding: PropTypes.object.isRequired,
    getBuilding: PropTypes.func.isRequired,
    floors: PropTypes.arrayOf(PropTypes.object),
    currentFloor: PropTypes.object.isRequired,
    getAllFloors: PropTypes.func.isRequired,
    getFloor: PropTypes.func.isRequired,
    removeBuildingFromFloor: PropTypes.func.isRequired,
    updateFloor: PropTypes.func.isRequired,
    uploadFloorPlan: PropTypes.func.isRequired,
    uploadFloorPlanPdf: PropTypes.func.isRequired,
    deleteFloor: PropTypes.func.isRequired,
    availableFlowMonitors: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableFlowMonitorsBySite: PropTypes.func.isRequired,
    getFlowMonitorData: PropTypes.func.isRequired,
    flowMonitorData: PropTypes.object.isRequired,
    deleteBuilding: PropTypes.func.isRequired,
    match: PropTypes.shape({ url: PropTypes.string }).isRequired,
    sitesMeta: PropTypes.object.isRequired,
    alertsMeta: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object),
    currentLocation: PropTypes.object.isRequired,
    currentLocationChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentLocationChartColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    getAllLocationsBySite: PropTypes.func.isRequired,
    getSiteAssets: PropTypes.func.isRequired,
    getAllGatewaysBySite: PropTypes.func.isRequired,
    siteGateways: PropTypes.arrayOf(PropTypes.object),
    getAllLocationsByFloor: PropTypes.func.isRequired,
    getLocation: PropTypes.func.isRequired,
    getLocationChartData: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    getAllAvailablePods: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    allAvailableLeakPods: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllThresholds: PropTypes.func.isRequired,
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    addThresholdToSite: PropTypes.func.isRequired,
    markPodLost: PropTypes.func.isRequired,
    deleteThresholdFromSite: PropTypes.func.isRequired,
    addThresholdToLocation: PropTypes.func.isRequired,
    deleteThresholdFromLocation: PropTypes.func.isRequired,
    removePodFromLocation: PropTypes.func.isRequired,
    removeFlowMonitorFromLocation: PropTypes.func.isRequired,
    accountProfile: PropTypes.object.isRequired,
    toggleLocationOption: PropTypes.func.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
    isFloorLoading: PropTypes.bool.isRequired,
    isCommentLoading: PropTypes.bool.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    isShipmentLoading: PropTypes.bool.isRequired,
    isLocationLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    isGatewayLoading: PropTypes.bool.isRequired,
    isSnapshotLoading: PropTypes.bool.isRequired,
    isPodMetadataLoading: PropTypes.bool.isRequired,
    setMapLocationCoordinates: PropTypes.func.isRequired,
    updateMapLocationCoordinates: PropTypes.func.isRequired,
    deleteMapLocation: PropTypes.func.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    isFlowMonitorLoading: PropTypes.bool.isRequired,
    windowWidth: PropTypes.number.isRequired,
    deleteDeployment: PropTypes.func.isRequired,
    getAllDeploymentsBySite: PropTypes.func.isRequired,
    getAllShipments: PropTypes.func.isRequired,
    siteShipments: PropTypes.arrayOf(PropTypes.object),
    getDeployment: PropTypes.func.isRequired,
    packingFormValues: PropTypes.object,
    editSiteFloorPlanFormValues: PropTypes.object,
    createDeploymentFormValues: PropTypes.object,
    siteFormValues: PropTypes.object,
    shipmentFormValues: PropTypes.object,
    getShipment: PropTypes.func.isRequired,
    currentShipment: PropTypes.object.isRequired,
    generateGrafanaConfig: PropTypes.func.isRequired,
    clearVisibleLocations: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    updateSortOrder: PropTypes.func.isRequired,
    getAllEscalationPolicies: PropTypes.func.isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFloorSummary: PropTypes.func.isRequired,
    getAllFloorSummaries: PropTypes.func.isRequired,
    getWeather: PropTypes.func.isRequired,
    clearWeather: PropTypes.func.isRequired,
    currentSiteWeather: PropTypes.object.isRequired,
    getPodAssignmentHistory: PropTypes.func.isRequired,
    getAllPodsAssignmentHistory: PropTypes.func.isRequired,
    currentPodAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    allPodsAssignmentHistory: PropTypes.arrayOf(PropTypes.object),
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    currentComment: PropTypes.object,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    createComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateSiteThresholdUserMapping: PropTypes.func.isRequired,
    getSiteAggregateThresholdUserMappings: PropTypes.func.isRequired,
    siteAggregateThresholdUserMappings: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    getLocationThresholdUserMappings: PropTypes.func.isRequired,
    updateLocationThresholdUserMapping: PropTypes.func.isRequired,
    locationThresholdUserMappings: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    siteAssets: PropTypes.object,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    updateGatewayMapLocation: PropTypes.func.isRequired,
    deleteGatewayMapLocation: PropTypes.func.isRequired,
    addThresholdToBuilding: PropTypes.func.isRequired,
    deleteThresholdFromBuilding: PropTypes.func.isRequired,
    addThresholdToFloor: PropTypes.func.isRequired,
    deleteThresholdFromFloor: PropTypes.func.isRequired,
    getAggregateThresholdsBySite: PropTypes.func.isRequired,
    siteThresholdMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFloorThresholdUserMappings: PropTypes.func.isRequired,
    updateFloorThresholdUserMapping: PropTypes.func.isRequired,
    floorThresholdUserMappings: PropTypes.arrayOf(PropTypes.object).isRequired,
    regeneratePackingList: PropTypes.func.isRequired,
    getActivityLog: PropTypes.func.isRequired,
    activityLog: PropTypes.arrayOf(PropTypes.object).isRequired,
    activityLogMeta: PropTypes.object.isRequired,
    getRiMetrics: PropTypes.func.isRequired,
    riMetrics: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFloorPlanSnaps: PropTypes.func.isRequired,
    siteFloorPlanSnaps: PropTypes.arrayOf(PropTypes.object).isRequired,
    uploadDocument: PropTypes.func.isRequired,
    getSiteContractors: PropTypes.func.isRequired,
    siteContractors: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCompanyLoading: PropTypes.bool.isRequired,
    uploadUsersCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
    getLocationUptimeData: PropTypes.func.isRequired,
    currentLocationUptimeData: PropTypes.object.isRequired,
    getAllFloorsByBuilding: PropTypes.func.isRequired,
    getBuildingThresholdUserMappings: PropTypes.func.isRequired,
    updateBuildingThresholdUserMapping: PropTypes.func.isRequired,
    buildingThresholdUserMappings: PropTypes.arrayOf(PropTypes.object)
      .isRequired,
    getDeploymentEditHistory: PropTypes.func.isRequired,
    deploymentEditHistory: PropTypes.arrayOf(PropTypes.object),
    getAllAssetLocations: PropTypes.func.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllNetworks: PropTypes.func.isRequired,
    networks: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllLocationGroups: PropTypes.func.isRequired,
    locationGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
    siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllUsersBySite: PropTypes.func.isRequired,
    createBuilding: PropTypes.func.isRequired,
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    this.props.clearWeather()
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  handleModalOpen = slug => {
    const floorSlugs = this.props.floors.map(x => x.slug)
    const index = floorSlugs.indexOf(slug)
    const currentFloorPlan = this.props.floors[index]

    this.setState({
      isModalVisible: true,
      currentFloorPlan,
    })
  }

  handleModalCancel = () => {
    this.setState({
      isModalVisible: false,
    })
  }

  handlePrevFloor = () => {
    const index = this.state.currentFloorPlanIndex - 1

    if (index >= 0) {
      const currentFloorPlan = this.props.floors[index]
      this.setState({
        currentFloorPlan,
        currentFloorPlanIndex: index,
      })
    }
  }

  handleNextFloor = () => {
    const index = this.state.currentFloorPlanIndex + 1

    if (index < this.props.floors.length) {
      const currentFloorPlan = this.props.floors[index]
      this.setState({
        currentFloorPlan,
        currentFloorPlanIndex: index,
      })
    }
  }

  handleKeyDown = ({ keyCode }) => {
    if (keyCode === KEYCODES.ARROW_RIGHT) {
      this.handleNextFloor()
    } else if (keyCode === KEYCODES.ARROW_LEFT) {
      this.handlePrevFloor()
    }
  }

  render() {
    const {
      match: { url },
      locationAlertHistory,
      getLocationAlertHistory,
      podMetadata,
      companies,
      deployments,
      allAvailableGateways,
      sites,
      currentSite,
      getAllSites,
      getAllBuildings,
      getSite,
      getAdminSite,
      deleteSite,
      addUserToSite,
      addSiteContractor,
      deleteSiteContractor,
      deleteUserFromSite,
      addGatewayToSite,
      deleteGatewayFromSite,
      sitesMeta,
      alertsMeta,
      buildings,
      currentBuilding,
      getBuilding,
      currentFloor,
      floors,
      getFloor,
      removeBuildingFromFloor,
      getAllFloors,
      updateFloor,
      uploadFloorPlan,
      uploadFloorPlanPdf,
      deleteFloor,
      deleteBuilding,
      getAllAvailableFlowMonitorsBySite,
      availableFlowMonitors,
      getFlowMonitorData,
      flowMonitorData,
      locations,
      currentLocation,
      currentLocationChartData,
      currentLocationChartColumns,
      getAllLocationsBySite,
      getSiteAssets,
      getAllLocationsByFloor,
      getLocation,
      getLocationChartData,
      deleteLocation,
      allAvailablePods,
      allAvailableLeakPods,
      thresholds,
      addThresholdToSite,
      deleteThresholdFromSite,
      addThresholdToLocation,
      deleteThresholdFromLocation,
      removePodFromLocation,
      removeFlowMonitorFromLocation,
      accountProfile,
      toggleLocationOption,
      isSiteLoading,
      isFloorLoading,
      isCommentLoading,
      isGatewayLoading,
      isLocationLoading,
      isHistoryLoading,
      isDeploymentLoading,
      isShipmentLoading,
      isPodMetadataLoading,
      setMapLocationCoordinates,
      updateMapLocationCoordinates,
      deleteMapLocation,
      isChartDataLoading,
      windowWidth,
      getAllDeploymentsBySite,
      deleteDeployment,
      currentDeployment,
      getDeployment,
      packingFormValues,
      editSiteFloorPlanFormValues,
      createDeploymentFormValues,
      siteFormValues,
      shipmentFormValues,
      currentShipment,
      getShipment,
      getAllCompanies,
      getAllThresholds,
      getPodMetadata,
      getAllAvailableGateways,
      getAllAvailablePods,
      generateGrafanaConfig,
      clearVisibleLocations,
      updateQueryParams,
      updateSortOrder,
      getAllEscalationPolicies,
      escalationPolicies,
      getFloorSummary,
      getAllFloorSummaries,
      siteShipments,
      getAllShipments,
      getWeather,
      clearWeather,
      currentSiteWeather,
      currentPodAssignmentHistory,
      allPodsAssignmentHistory,
      getPodAssignmentHistory,
      getAllPodsAssignmentHistory,
      getAllComments,
      deleteComment,
      editComment,
      createComment,
      currentComment,
      getAllTags,
      comments,
      tags,
      updateSiteThresholdUserMapping,
      getSiteAggregateThresholdUserMappings,
      siteAggregateThresholdUserMappings,
      getLocationThresholdUserMappings,
      updateLocationThresholdUserMapping,
      locationThresholdUserMappings,
      siteAssets,
      snapshotData,
      getLocationSnapshot,
      getAllGatewaysBySite,
      siteGateways,
      updateGatewayMapLocation,
      deleteGatewayMapLocation,
      markPodLost,
      isSnapshotLoading,
      addThresholdToBuilding,
      deleteThresholdFromBuilding,
      addThresholdToFloor,
      deleteThresholdFromFloor,
      getAggregateThresholdsBySite,
      siteThresholdMappings,
      getFloorThresholdUserMappings,
      updateFloorThresholdUserMapping,
      floorThresholdUserMappings,
      regeneratePackingList,
      activityLog,
      getActivityLog,
      riMetrics,
      getRiMetrics,
      siteFloorPlanSnaps,
      getFloorPlanSnaps,
      uploadDocument,
      siteContractors,
      getSiteContractors,
      isCompanyLoading,
      activityLogMeta,
      uploadUsersCSV,
      csvUploadResults,
      currentLocationUptimeData,
      getLocationUptimeData,
      getAllFloorsByBuilding,
      getBuildingThresholdUserMappings,
      updateBuildingThresholdUserMapping,
      buildingThresholdUserMappings,
      deploymentEditHistory,
      getDeploymentEditHistory,
      getAllAssetLocations,
      assetLocations,
      getAllNetworks,
      networks,
      getAllLocationGroups,
      locationGroups,
      getAllUsersBySite,
      siteUsers,
      createBuilding,
    } = this.props

    const {
      currentFloorPlan,
      currentFloorPlanIndex,
      isModalVisible,
    } = this.state

    return (
      <MainContent>
        <div className="SitesPage with-side-nav">
          <PageWidth>
            <Switch>
              <Route
                path={`${url}/`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Sites</title>
                    </Helmet>
                    <AllSites
                      sites={sites}
                      companies={companies}
                      getAllSites={getAllSites}
                      getAllCompanies={getAllCompanies}
                      deleteSite={deleteSite}
                      meta={sitesMeta}
                      accountProfile={accountProfile}
                      windowWidth={windowWidth}
                      isSiteLoading={isSiteLoading}
                      updateQueryParams={updateQueryParams}
                      url={url}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/create-proposal`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Site</title>
                    </Helmet>
                    <CreateSiteProposal
                      getAllCompanies={getAllCompanies}
                      companies={companies}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/buildings/:id/evacuate`}
                render={props => (
                    <Fragment>
                        <Helmet>
                            <title>
                            {currentBuilding.name
                                ? `Evacuate ${currentBuilding.name}`
                                : 'Loading...'}
                            </title>
                        </Helmet>
                        <EvacuateBuilding
                            building={currentBuilding}
                            getBuilding={getBuilding}
                            getSite={getSite}
                            siteName={currentSite.name}
                            {...props}
                        />
                    </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/buildings/:id/disable-evac`}
                render={props => (
                    <Fragment>
                        <Helmet>
                            <title>
                            {currentBuilding.name
                                ? `Evacuate ${currentBuilding.name}`
                                : 'Loading...'}
                            </title>
                        </Helmet>
                        <EvacuateDisable
                            building={currentBuilding}
                            getBuilding={getBuilding}
                            getSite={getSite}
                            siteName={currentSite.name}
                            {...props}
                        />
                    </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentSite.name
                          ? `Edit ${currentSite.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditSite
                      accountProfile={accountProfile}
                      site={currentSite}
                      companies={companies}
                      getSite={getAdminSite}
                      getAllCompanies={getAllCompanies}
                      formValues={siteFormValues}
                      getAllNetworks={getAllNetworks}
                      networks={networks}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/create`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Floor</title>
                    </Helmet>
                    <CreateFloor
                      buildings={buildings}
                      getAllBuildings={getAllBuildings}
                      getSite={getSite}
                      siteName={currentSite.name}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/create`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Deployment</title>
                    </Helmet>
                    <CreateDeployment
                      formValues={createDeploymentFormValues}
                      siteUsers={currentSite.availableUsers}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/:deploymentSlug/packing`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentDeployment.slug || 'Loading...'}</title>
                    </Helmet>
                    <Packing
                      formValues={packingFormValues}
                      deployment={currentDeployment}
                      getDeployment={getDeployment}
                      isDeploymentLoading={isDeploymentLoading}
                      isPodMetadataLoading={isPodMetadataLoading}
                      accountProfile={accountProfile}
                      getPodMetadata={getPodMetadata}
                      podMetadata={podMetadata}
                      siteName={currentSite.name}
                      getAllAssetLocations={getAllAssetLocations}
                      assetLocations={assetLocations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/:deploymentSlug/shipments/:shipmentId/edit`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentDeployment.slug || 'Loading...'}</title>
                    </Helmet>
                    <EditShipment
                      shipment={currentShipment}
                      getShipment={getShipment}
                      accountProfile={accountProfile}
                      isShipmentLoading={isShipmentLoading}
                      siteName={currentSite.name}
                      getSiteAssets={getSiteAssets}
                      siteAssets={siteAssets}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/:deploymentSlug/shipments/:shipmentId`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentDeployment.slug || 'Loading...'}</title>
                    </Helmet>
                    <ShipmentDetail
                      site={currentSite}
                      shipment={currentShipment}
                      deployment={currentDeployment}
                      getShipment={getShipment}
                      isShipmentLoading={isShipmentLoading}
                      getDeployment={getDeployment}
                      getSite={getSite}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/:deploymentSlug/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentDeployment
                          ? currentDeployment.description
                            ? currentDeployment.description
                            : dateTimeFormatter(
                                currentDeployment.createdAt,
                                true
                              )
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditDeploymentForm
                      initialValues={currentDeployment}
                      deployment={currentDeployment}
                      site={currentSite}
                      getDeployment={getDeployment}
                      getSite={getSite}
                      isDeploymentLoading={isDeploymentLoading}
                      isSiteLoading={isSiteLoading}
                      siteUsers={currentSite.availableUsers}
                      enableReinitialize
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/deployments/:deploymentSlug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentDeployment
                          ? currentDeployment.description
                            ? currentDeployment.description
                            : dateTimeFormatter(
                                currentDeployment.createdAt,
                                true
                              )
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <Deployment
                      deployment={currentDeployment}
                      getDeployment={getDeployment}
                      getSite={getSite}
                      siteAssets={siteAssets}
                      getSiteAssets={getSiteAssets}
                      siteShipments={siteShipments}
                      getAllShipments={getAllShipments}
                      isDeploymentLoading={isDeploymentLoading}
                      isSiteLoading={isSiteLoading}
                      shipmentFormValues={shipmentFormValues}
                      site={currentSite}
                      isCommentLoading={isCommentLoading}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      comments={comments}
                      tags={tags}
                      regeneratePackingList={regeneratePackingList}
                      updateQueryParams={updateQueryParams}
                      editHistory={deploymentEditHistory}
                      getEditHistory={getDeploymentEditHistory}
                      isHistoryLoading={isHistoryLoading}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentFloor.name
                          ? `Edit ${currentFloor.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditFloor
                      currentFloor={currentFloor}
                      buildings={buildings}
                      getAllBuildings={getAllBuildings}
                      getFloor={getFloor}
                      getSite={getSite}
                      siteName={currentSite.name}
                      removeBuildingFromFloor={removeBuildingFromFloor}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/buildings/create`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Building</title>
                    </Helmet>
                    <CreateBuilding
                      getSite={getSite}
                      siteName={currentSite.name}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/buildings/:id/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentBuilding.name
                          ? `Edit ${currentBuilding.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditBuilding
                      building={currentBuilding}
                      getBuilding={getBuilding}
                      getSite={getSite}
                      siteName={currentSite.name}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/buildings/:id`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentBuilding.name || 'Loading...'}</title>
                    </Helmet>
                    <Building
                      site={currentSite}
                      building={currentBuilding}
                      floors={floors}
                      riMetrics={riMetrics}
                      getSite={getSite}
                      getBuilding={getBuilding}
                      getAllFloorsByBuilding={getAllFloorsByBuilding}
                      getRiMetrics={getRiMetrics}
                      updateQueryParams={updateQueryParams}
                      deleteFloor={deleteFloor}
                      getBuildingThresholdUserMappings={
                        getBuildingThresholdUserMappings
                      }
                      updateBuildingThresholdUserMapping={
                        updateBuildingThresholdUserMapping
                      }
                      buildingThresholdUserMappings={
                        buildingThresholdUserMappings
                      }
                      isFloorLoading={isFloorLoading}
                      isHistoryLoading={isHistoryLoading}
                      accountProfile={accountProfile}
                      windowWidth={windowWidth}
                      handleModalOpen={this.handleModalOpen}
                      isLocationLoading={isLocationLoading}
                      getAllLocationsByFloor={getAllLocationsByFloor}
                      snapshotData={snapshotData}
                      getLocationSnapshot={getLocationSnapshot}
                      locations={locations}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId/locations/create`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Create Location</title>
                    </Helmet>
                    <CreateLocation
                      getAllTags={getAllTags}
                      tags={tags}
                      getAllAvailableFlowMonitorsBySite={
                        getAllAvailableFlowMonitorsBySite
                      }
                      availableFlowMonitors={availableFlowMonitors}
                      getAllAvailablePods={getAllAvailablePods}
                      allAvailablePods={allAvailablePods}
                      allAvailableLeakPods={allAvailableLeakPods}
                      accountProfile={accountProfile}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId/locations/:id/edit`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>
                        {currentLocation.name
                          ? `Edit ${currentLocation.name}`
                          : 'Loading...'}
                      </title>
                    </Helmet>
                    <EditLocation
                      getAllTags={getAllTags}
                      tags={tags}
                      currentLocation={currentLocation}
                      getLocation={getLocation}
                      getAllAvailablePods={getAllAvailablePods}
                      allAvailablePods={allAvailablePods}
                      allAvailableLeakPods={allAvailableLeakPods}
                      getAllAvailableFlowMonitorsBySite={
                        getAllAvailableFlowMonitorsBySite
                      }
                      availableFlowMonitors={availableFlowMonitors}
                      addThresholdToLocation={addThresholdToLocation}
                      deleteThresholdFromLocation={deleteThresholdFromLocation}
                      accountProfile={accountProfile}
                      removePodFromLocation={removePodFromLocation}
                      removeFlowMonitorFromLocation={
                        removeFlowMonitorFromLocation
                      }
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId/locations/:id`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentLocation.name || 'Loading...'}</title>
                    </Helmet>
                    <Location
                      getFlowMonitorData={getFlowMonitorData}
                      flowMonitorData={flowMonitorData}
                      currentLocation={currentLocation}
                      currentLocationChartData={currentLocationChartData}
                      currentLocationChartColumns={currentLocationChartColumns}
                      podAssignmentHistory={currentPodAssignmentHistory}
                      getPodAssignmentHistory={getPodAssignmentHistory}
                      windowWidth={windowWidth}
                      getLocation={getLocation}
                      meta={alertsMeta}
                      getLocationChartData={getLocationChartData}
                      getLocationAlertHistory={getLocationAlertHistory}
                      locationAlertHistory={locationAlertHistory}
                      accountProfile={accountProfile}
                      toggleLocationOption={toggleLocationOption}
                      isLocationLoading={isLocationLoading}
                      isHistoryLoading={isHistoryLoading}
                      isCommentLoading={isCommentLoading}
                      isSnapshotLoading={isSnapshotLoading}
                      isChartDataLoading={isChartDataLoading}
                      getSite={getSite}
                      currentSite={currentSite}
                      getFloor={getFloor}
                      currentFloor={currentFloor}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      currentComment={currentComment}
                      comments={comments}
                      tags={tags}
                      updateQueryParams={updateQueryParams}
                      getLocationThresholdUserMappings={
                        getLocationThresholdUserMappings
                      }
                      updateLocationThresholdUserMapping={
                        updateLocationThresholdUserMapping
                      }
                      locationThresholdUserMappings={
                        locationThresholdUserMappings
                      }
                      markPodLost={markPodLost}
                      riMetrics={riMetrics}
                      getRiMetrics={getRiMetrics}
                      locationUptimeData={currentLocationUptimeData}
                      getLocationUptimeData={getLocationUptimeData}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId/rooms`}
                render={props => (
                  <>
                    <Helmet>
                      <title>Rooms</title>
                    </Helmet>
                    <LocationGroups {...props} />
                  </>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floors/:floorId`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentFloor.name || 'Loading...'}</title>
                    </Helmet>
                    <Floor
                      getSite={getSite}
                      site={currentSite}
                      currentFloor={currentFloor}
                      getFloor={getFloor}
                      getAllLocationsByFloor={getAllLocationsByFloor}
                      deleteLocation={deleteLocation}
                      locations={locations}
                      getAllAvailablePods={getAllAvailablePods}
                      allAvailablePods={allAvailablePods}
                      allAvailableLeakPods={allAvailableLeakPods}
                      getAllAvailableFlowMonitorsBySite={
                        getAllAvailableFlowMonitorsBySite
                      }
                      availableFlowMonitors={availableFlowMonitors}
                      getAllGatewaysBySite={getAllGatewaysBySite}
                      siteGateways={siteGateways}
                      updateGatewayMapLocation={updateGatewayMapLocation}
                      deleteGatewayMapLocation={deleteGatewayMapLocation}
                      accountProfile={accountProfile}
                      setMapLocationCoordinates={setMapLocationCoordinates}
                      updateMapLocationCoordinates={
                        updateMapLocationCoordinates
                      }
                      deleteMapLocation={deleteMapLocation}
                      windowWidth={windowWidth}
                      isFloorLoading={isFloorLoading}
                      isChartDataLoading={isChartDataLoading}
                      isGatewayLoading={isGatewayLoading}
                      isLocationLoading={isLocationLoading}
                      isHistoryLoading={isHistoryLoading}
                      updateFloor={updateFloor}
                      uploadFloorPlan={uploadFloorPlan}
                      clearVisibleLocations={clearVisibleLocations}
                      getFloorSummary={getFloorSummary}
                      snapshotData={snapshotData}
                      getLocationSnapshot={getLocationSnapshot}
                      getFloorThresholdUserMappings={
                        getFloorThresholdUserMappings
                      }
                      updateFloorThresholdUserMapping={
                        updateFloorThresholdUserMapping
                      }
                      floorThresholdUserMappings={floorThresholdUserMappings}
                      updateQueryParams={updateQueryParams}
                      riMetrics={riMetrics}
                      getRiMetrics={getRiMetrics}
                      getAllLocationGroups={getAllLocationGroups}
                      locationGroups={locationGroups}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/compare-locations`}
                exact
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Compare Locations</title>
                    </Helmet>
                    <MultipleLocationsContainer
                      updateQueryParams={updateQueryParams}
                      isChartDataLoading={isChartDataLoading}
                      getSite={getSite}
                      siteName={currentSite.name}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug/floorplan/edit`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>Edit Floor Plan</title>
                    </Helmet>
                    <EditSiteFloorPlan
                      isFloorPlanEdit
                      createBuilding={createBuilding}
                      isSiteLoading={isHistoryLoading}
                      siteFloorPlanSnaps={siteFloorPlanSnaps}
                      getFloorPlanSnaps={getFloorPlanSnaps}
                      buildings={buildings}
                      getAllBuildings={getAllBuildings}
                      formValues={editSiteFloorPlanFormValues}
                      {...props}
                    />
                  </Fragment>
                )}
              />
              <Route
                path={`${url}/:siteSlug`}
                render={props => (
                  <Fragment>
                    <Helmet>
                      <title>{currentSite.name || 'Loading...'}</title>
                    </Helmet>
                    <Site
                      uploadDocument={uploadDocument}
                      getFloorPlanSnaps={getFloorPlanSnaps}
                      siteFloorPlanSnaps={siteFloorPlanSnaps}
                      createBuilding={createBuilding}
                      currentSite={currentSite}
                      getSite={getSite}
                      getAdminSite={getAdminSite}
                      companies={companies}
                      getAllCompanies={getAllCompanies}
                      siteContractors={siteContractors}
                      getSiteContractors={getSiteContractors}
                      isCompanyLoading={isCompanyLoading}
                      addSiteContractor={addSiteContractor}
                      deleteSiteContractor={deleteSiteContractor}
                      getAllAvailableGateways={getAllAvailableGateways}
                      getAllThresholds={getAllThresholds}
                      allAvailableGateways={allAvailableGateways}
                      allPodsAssignmentHistory={allPodsAssignmentHistory}
                      getAllPodsAssignmentHistory={getAllPodsAssignmentHistory}
                      addUserToSite={addUserToSite}
                      addGatewayToSite={addGatewayToSite}
                      deleteGatewayFromSite={deleteGatewayFromSite}
                      deleteUserFromSite={deleteUserFromSite}
                      getAllFloors={getAllFloors}
                      deleteFloor={deleteFloor}
                      deleteBuilding={deleteBuilding}
                      buildings={buildings}
                      floors={floors}
                      deployments={deployments}
                      thresholds={thresholds}
                      addThresholdToSite={addThresholdToSite}
                      deleteThresholdFromSite={deleteThresholdFromSite}
                      addThresholdToBuilding={addThresholdToBuilding}
                      deleteThresholdFromBuilding={deleteThresholdFromBuilding}
                      addThresholdToFloor={addThresholdToFloor}
                      deleteThresholdFromFloor={deleteThresholdFromFloor}
                      addThresholdToLocation={addThresholdToLocation}
                      deleteThresholdFromLocation={deleteThresholdFromLocation}
                      currentBuilding={currentBuilding}
                      currentFloor={currentFloor}
                      currentLocation={currentLocation}
                      updateBuildingThresholdUserMapping={
                        updateBuildingThresholdUserMapping
                      }
                      updateFloorThresholdUserMapping={
                        updateFloorThresholdUserMapping
                      }
                      updateLocationThresholdUserMapping={
                        updateLocationThresholdUserMapping
                      }
                      accountProfile={accountProfile}
                      locations={locations}
                      isSiteLoading={isSiteLoading}
                      isLocationLoading={isLocationLoading}
                      isFloorLoading={isFloorLoading}
                      isDeploymentLoading={isDeploymentLoading}
                      isShipmentLoading={isShipmentLoading}
                      isHistoryLoading={isHistoryLoading}
                      windowWidth={windowWidth}
                      getAllLocationsByFloor={getAllLocationsByFloor}
                      getAllLocationsBySite={getAllLocationsBySite}
                      getAllDeploymentsBySite={getAllDeploymentsBySite}
                      deleteDeployment={deleteDeployment}
                      generateGrafanaConfig={generateGrafanaConfig}
                      getAllEscalationPolicies={getAllEscalationPolicies}
                      escalationPolicies={escalationPolicies}
                      getAllFloorSummaries={getAllFloorSummaries}
                      getWeather={getWeather}
                      clearWeather={clearWeather}
                      currentSiteWeather={currentSiteWeather}
                      updateSiteThresholdUserMapping={
                        updateSiteThresholdUserMapping
                      }
                      getSiteAggregateThresholdUserMappings={
                        getSiteAggregateThresholdUserMappings
                      }
                      siteAggregateThresholdUserMappings={
                        siteAggregateThresholdUserMappings
                      }
                      snapshotData={snapshotData}
                      getLocationSnapshot={getLocationSnapshot}
                      getAggregateThresholdsBySite={
                        getAggregateThresholdsBySite
                      }
                      siteThresholdMappings={siteThresholdMappings}
                      getActivityLog={getActivityLog}
                      activityLog={activityLog}
                      activityLogMeta={activityLogMeta}
                      riMetrics={riMetrics}
                      getRiMetrics={getRiMetrics}
                      uploadFloorPlanPdf={uploadFloorPlanPdf}
                      uploadUsersCSV={uploadUsersCSV}
                      csvUploadResults={csvUploadResults}
                      updateQueryParams={updateQueryParams}
                      handleModalOpen={this.handleModalOpen}
                      handleModalCancel={this.handleModalCancel}
                      getAllComments={getAllComments}
                      deleteComment={deleteComment}
                      createComment={createComment}
                      editComment={editComment}
                      getAllTags={getAllTags}
                      tags={tags}
                      comments={comments}
                      updateSortOrder={updateSortOrder}
                      getAllUsersBySite={getAllUsersBySite}
                      siteUsers={siteUsers}
                      {...props}
                    />
                  </Fragment>
                )}
              />
            </Switch>
          </PageWidth>

          <Modal
            className="Modal Modal--dark"
            visible={isModalVisible}
            footer={null}
            width={windowWidth - 32}
            onCancel={this.handleModalCancel}
            style={{ top: 16 }}
            destroyOnClose
          >
            <H3 className="white t-shadow-1">
              {currentSite.name} &mdash; {currentFloorPlan.name} [
              {currentFloorPlan.floor}]
            </H3>
            <FloorPlan
              siteSlug={currentSite.slug}
              floorId={currentFloorPlan.id}
              isLocationLoading={isLocationLoading}
              getAllLocationsByFloor={getAllLocationsByFloor}
              snapshotData={snapshotData}
              getLocationSnapshot={getLocationSnapshot}
              getAllGatewaysBySite={getAllGatewaysBySite}
              siteGateways={siteGateways}
              currentFloor={currentFloorPlan}
              locations={locations}
              accountProfile={accountProfile}
              handleFloorPlanModalCancel={this.handleModalCancel}
              deleteMapLocation={deleteMapLocation}
            />
            <div className="paginate flex justify-between absolute">
              <div className="z-9999">
                {currentFloorPlanIndex > 0 && (
                  <ActionAnchor onClick={this.handlePrevFloor}>
                    <Icon
                      type="left"
                      className="f1 light-gray hover-dark-blue"
                    />
                  </ActionAnchor>
                )}
              </div>
              <div className="z-9999">
                {currentFloorPlanIndex < this.props.floors.length - 1 && (
                  <ActionAnchor onClick={this.handleNextFloor}>
                    <Icon
                      type="right"
                      className="f1 light-gray hover-dark-blue"
                    />
                  </ActionAnchor>
                )}
              </div>
            </div>
          </Modal>
        </div>
      </MainContent>
    )
  }
}

export default SitesPage
