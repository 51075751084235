import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PackingForm from './forms/PackingForm'
import { updateDeploymentAction } from '../actions/deploymentsActions'

class Packing extends Component {
  static propTypes = {
    getPodMetadata: PropTypes.func.isRequired,
    getDeployment: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    podMetadata: PropTypes.object.isRequired,
    isPodMetadataLoading: PropTypes.bool.isRequired,
    deployment: PropTypes.object.isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object).isRequired,
    formValues: PropTypes.object.isRequired,
    match: PropTypes.object,
  }

  static defaultProps = {
    formValues: {},
  }

  componentDidMount() {
    this.props.getPodMetadata()
  }

  render() {
    const {
      match,
      formValues,
      podMetadata,
      getDeployment,
      getAllAssetLocations,
      deployment,
      assetLocations,
      isPodMetadataLoading,
    } = this.props

    const {
      actualPods,
      actualLeakPods,
      actualGateways,
      actualFlowMonitors,
      ...rest
    } = deployment
    return (
      <section className="Packing">
        <PackingForm
          match={match}
          podMetadata={podMetadata}
          isPodMetadataLoading={isPodMetadataLoading}
          getDeployment={getDeployment}
          getAllAssetLocations={getAllAssetLocations}
          deployment={deployment}
          assetLocations={assetLocations}
          submitAction={updateDeploymentAction}
          formValues={formValues}
          initialValues={{
            actualPods: rest.estimatedPods,
            actualLeakPods: rest.estimatedLeakPods,
            actualGateways: rest.estimatedGateways,
            actualFlowMonitors: rest.estimatedFlowMonitors,
            podAssetLocationIds: [],
            firmwareVersions: [],
            productVersions: [],
            leakFirmwareVersions: [],
            leakProductVersions: [],
            leakPodAssetLocationIds: [],
            leakPodProductVersions: [],
            leakPodFirmwareVersions: [],
            ...rest,
          }}
        />
      </section>
    )
  }
}

export default Packing
