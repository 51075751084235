import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AllGateways from './AllGateways'
import { Table, Column } from './common/Ant'
import { H3, H4 } from './common/Headers'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import { Option } from './common/Select'
import SelectContainer from '../containers/common/SelectContainer'
import { getInventoryHistory, getLostInventory } from '../reducers/sitesReducer'
import { getInventoryHistoryBySiteAction } from '../actions/sitesActions'
import { dateFormatter } from '../utils/date'
import { SIZES } from '../constants'

const mapStateToProps = ({ sites }) => ({
  inventoryHistory: getInventoryHistory(sites),
  lostInventory: getLostInventory(sites),
})

const mapDispatchToProps = dispatch => ({
  getInventoryHistory: payload =>
    dispatch(getInventoryHistoryBySiteAction.request(payload)),
})

const SiteEquipment = ({
  siteSlug,
  currentSite,
  getInventoryHistory,
  inventoryHistory,
  lostInventory,
  allAvailableGateways,
  windowWidth,
  handleAddGatewayToSite,
  handleDeleteGatewayFromSite,
}) => {
  useEffect(() => {
    getInventoryHistory(siteSlug)
  }, [getInventoryHistory, siteSlug])

  const { gateways, stats, networkSlug, networkName } = currentSite

  const {
    // buildingCount,
    // floorCount,
    // locationCount,
    smartPod: { totalCount: smartPodTotalCount },
    leakPod: { totalCount: leakPodTotalCount },
    flowMonitor: { totalCount: flowMonitorTotalCount },
  } = stats

  return (
    <div className="SiteEquipment">
      <H3>Current Equipment on Site</H3>
      <div className="flex flex-wrap mb3">
        <Block label="Total Smart Pods" size={SIZES.MEDIUM}>
          {smartPodTotalCount.toString()}
        </Block>
        <Block label="Total Leak Pucks" size={SIZES.MEDIUM}>
          {leakPodTotalCount.toString()}
        </Block>
        <Block label="Total Water Monitors" size={SIZES.MEDIUM}>
          {flowMonitorTotalCount.toString()}
        </Block>
      </div>

      <>
        <H3>Network Gateways</H3>
        {networkSlug && (
          <>
            <div className="mb3">
              <H4>Network Details</H4>
              <Anchor to={`/networks/${networkSlug}/view`}>
                {networkName}
              </Anchor>
            </div>
            <Divider />
          </>
        )}
        <div className="mb2">
          <SelectContainer
            placeholder="Select a gateway"
            label="Add Network Gateway to Site"
            input={{
              onChange: handleAddGatewayToSite,
            }}
            filterable
          >
            {allAvailableGateways.map(x => (
              <Option value={x.id} key={x.id}>
                {x.gatewayName}
              </Option>
            ))}
          </SelectContainer>
        </div>
        <AllGateways
          deleteGatewayFromSite={handleDeleteGatewayFromSite}
          gateways={gateways}
          windowWidth={windowWidth}
          isRenderingSiteView
        />
      </>

      <H3>Monthly Equipment on Site</H3>
      <Table
        dataSource={inventoryHistory}
        rowKey="date"
        pagination={false}
        size="small"
        scroll={{ x: 815 }}
        tableLayout="auto"
      >
        <Column
          title="Month"
          dataIndex="date"
          render={text => (
            <span className="b">{dateFormatter(text, false, false, true)}</span>
          )}
        />
        <Column title="Assigned Locations" dataIndex="assignedLocationCount" />

        <Column title="Network Gateways" dataIndex="gatewaysCount" />
        <Column title="Smart Pods" dataIndex="smartPodsCount" />
        <Column title="Leak Pucks" dataIndex="leakPucksCount" />
        <Column title="Water Monitors" dataIndex="waterMetersCount" />
        <Column title="Water Valves" dataIndex="waterValvesCount" />
      </Table>
      <H3>Lost Equipment</H3>
      <div className="flex flex-wrap mb3">
        <Block label="Network Gateways" size={SIZES.MEDIUM}>
          {lostInventory.gateways.length.toString()}
        </Block>
        <Block label="Smart Pods" size={SIZES.MEDIUM}>
          {lostInventory.smartPods.length.toString()}
        </Block>
        <Block label="Leak Pucks" size={SIZES.MEDIUM}>
          {lostInventory.leakPucks.length.toString()}
        </Block>
        <Block label="Water Monitors" size={SIZES.MEDIUM}>
          {lostInventory.waterMeters.length.toString()}
        </Block>
        <Block label="Water Valves" size={SIZES.MEDIUM}>
          {lostInventory.waterValves.length.toString()}
        </Block>
      </div>
    </div>
  )
}

SiteEquipment.propTypes = {
  inventoryHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
  lostInventory: PropTypes.object.isRequired,
  siteSlug: PropTypes.string.isRequired,
  currentSite: PropTypes.object.isRequired,
  getInventoryHistory: PropTypes.func.isRequired,
  allAvailableGateways: PropTypes.arrayOf(PropTypes.object).isRequired,
  windowWidth: PropTypes.number.isRequired,
  handleAddGatewayToSite: PropTypes.func.isRequired,
  handleDeleteGatewayFromSite: PropTypes.func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteEquipment)
