import React from 'react'
import PropTypes from 'prop-types'
import ShipmentForm from '../components/forms/ShipmentForm'
import { updateDeploymentAction } from '../actions/deploymentsActions'

const Shipment = props => {
  const {
    getDeployment,
    match,
    shipmentFormValues,
    deployment,
    siteName,
    isDeploymentLoading,
    isUninstall,
    siteAssets,
    getSiteAssets,
    areShipmentsFinished,
    hasShipped,
    isDelivered,
    siteShipments,
    getAllShipments,
    siteSlug,
    regeneratePackingList,
  } = props

  const shipment = {
    pods: [],
    gateways: [],
    flowMonitors: [],
    leakPods: [],
    anyGatewaySupply: false,
    vendor: '',
    trackingNumber: '',
  }
  return (
    <section className="Shipment">
      <ShipmentForm
        submitAction={updateDeploymentAction}
        formValues={shipmentFormValues}
        getDeployment={getDeployment}
        match={match}
        deployment={deployment}
        siteName={siteName}
        isDeploymentLoading={isDeploymentLoading}
        initialValues={{ shipment }}
        isUninstall={isUninstall}
        areShipmentsFinished={areShipmentsFinished}
        hasShipped={hasShipped}
        isDelivered={isDelivered}
        getAllShipments={getAllShipments}
        siteShipments={siteShipments}
        siteSlug={siteSlug}
        siteAssets={siteAssets}
        getSiteAssets={getSiteAssets}
        regeneratePackingList={regeneratePackingList}
        enableReinitialize={true}
        {...props}
      />
    </section>
  )
}

Shipment.propTypes = {
  deployment: PropTypes.object.isRequired,
  getDeployment: PropTypes.func,
  isDeploymentLoading: PropTypes.bool,
  match: PropTypes.object,
  shipmentFormValues: PropTypes.object,
  siteName: PropTypes.string,
  siteSlug: PropTypes.string,
  siteAssets: PropTypes.object,
  getSiteAssets: PropTypes.func.isRequired,
  regeneratePackingList: PropTypes.func.isRequired,
  isUninstall: PropTypes.bool,
  areShipmentsFinished: PropTypes.bool,
  hasShipped: PropTypes.bool,
  isDelivered: PropTypes.bool,
  siteShipments: PropTypes.arrayOf(PropTypes.object),
  getAllShipments: PropTypes.func,
}

export default Shipment
