const getWeatherByLatLng = async (lat, lng) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_DARK_SKY_URL}?lat=${lat}&lng=${lng}`
    )

    if (response.ok) {
      return await response.json()
    } else {
      return Promise.reject('TODO ERRRRRRRRRRRRRRRRRRROR')
    }
  } catch (error) {
    return Promise.reject(error)
  }
}

export default getWeatherByLatLng
