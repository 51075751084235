import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Bread, Crumb } from './common/Bread'
import { Anchor } from './common/Anchor'
import Logo from './common/Logo'
import PillarTag from './common/PillarTag'
import { Icon, Tooltip } from './common/Ant'
import Divider from './common/Divider'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { renderSiteStatus } from '../utils/textFormatters'
import {
  SITE_STATUS,
  USER_PERMISSIONS,
  ALERT_TYPES,
  ALERT_STATUS,
  THRESHOLD_ALERT_TYPES,
  ROLES,
} from '../constants'
import { createQueryString } from '../utils/queryParams'

const FloorHeader = ({ site, floor, locations, match }) => {
  const {
    name: siteName,
    slug: siteSlug,
    companyName,
    companyLogo,
    status,
    gateways,
  } = site

  const {
    id,
    name: floorName,
    active,
    buildingId,
    buildingName,
    stats: {
      // TODO handle flow monitors & leak PODs
      smartPod: {
        systemAlertsLocationCount,
        actionableAlertsLocationCount,
        totalCount,
      },
    },
  } = floor

  const offlineGateways = gateways.filter(x => !x.online)
  const qs = createQueryString({
    locationIds: locations.map(x => x.id).join(','),
    columns: ['temp'],
  })

  return (
    <div className="FloorHeader">
      <div className="mb3">
        <div className="mb2">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {siteName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
              </Crumb>
            )}
            {buildingName && (
              <Crumb>
                <Anchor to={`/sites/${siteSlug}/buildings/${buildingId}`}>
                  {buildingName}
                </Anchor>
              </Crumb>
            )}
            <Crumb>
              {floorName}
              {!active && (
                <Tooltip title="Inactive">
                  <Icon type="poweroff" className="pl2 moon-gray" />
                </Tooltip>
              )}
            </Crumb>
          </Bread>
        </div>
        {hasPermission(USER_PERMISSIONS.EDIT_FLOOR) ? (
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
            {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
              <span>
                <Divider vertical />
                <Anchor to={`/sites/${siteSlug}/floors/${id}/rooms`}>
                  Rooms
                </Anchor>
              </span>
            )}
            <span>
              <Divider vertical />
              <Anchor to={`/sites/${siteSlug}/compare-locations${qs}`}>
                Compare All Locations on Floor
              </Anchor>
            </span>
          </div>
        ) : (
          <div className="flex justify-center mb3">
            <Anchor to={`/sites/${siteSlug}/compare-locations${qs}`}>
              Compare All Locations on Floor
            </Anchor>
          </div>
        )}
        <div className="flex justify-center">
          <Logo companyName={companyName} companyLogo={companyLogo} />
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center mb3">
        <div className="mb2">
          <PillarTag
            iconType="environment-o"
            label="Site"
            className={
              status === SITE_STATUS.ACTIVE ? 'bg-green' : 'bg-dark-blue'
            }
          >
            {renderSiteStatus(status)}
          </PillarTag>
        </div>
        <Link
          className="mb2"
          to={`/alerts/all/pods?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&threshold_alert_type=${THRESHOLD_ALERT_TYPES.ACTIONABLE}&site_slug=${siteSlug}&floor_id=${id}`}
        >
          <PillarTag
            iconType="alert"
            label="Smart Pod Actionable Alerts"
            className={
              actionableAlertsLocationCount > 0 ? 'bg-dark-red' : 'bg-green'
            }
          >
            {actionableAlertsLocationCount}
          </PillarTag>
        </Link>
        <div className="mb2">
          <PillarTag
            iconType="compass"
            label="Smart Pod Locations"
            className={
              totalCount > 0 && totalCount - systemAlertsLocationCount <= 0
                ? 'bg-dark-red'
                : systemAlertsLocationCount > 0
                ? 'bg-orange'
                : 'bg-green'
            }
          >
            {totalCount > 0 &&
              `${totalCount - systemAlertsLocationCount} online`}
            {systemAlertsLocationCount > 0 &&
              ` | ${systemAlertsLocationCount} offline`}
          </PillarTag>
        </div>
        <div className="mb2">
          <PillarTag
            iconType="global"
            label="Network Gateways"
            className={
              gateways.length > 0 &&
              gateways.length - offlineGateways.length <= 0
                ? 'bg-dark-red'
                : offlineGateways.length > 0
                ? 'bg-orange'
                : 'bg-green'
            }
          >
            {gateways.length > 0 &&
              `${gateways.length - offlineGateways.length} online`}
            {offlineGateways.length > 0 &&
              ` | ${offlineGateways.length} offline`}
            {gateways.length <= 0 && offlineGateways.length <= 0 && '0'}
          </PillarTag>
        </div>
      </div>
    </div>
  )
}

FloorHeader.propTypes = {
  site: PropTypes.object.isRequired,
  floor: PropTypes.object.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
  match: PropTypes.object,
}

export default FloorHeader
