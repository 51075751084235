import { connect } from 'react-redux'
import { replace } from 'connected-react-router'
import { getFormValues } from 'redux-form'
import SitesPage from '../components/pages/SitesPage'
import {
  getAllSitesAction,
  getSiteAction,
  getAdminSiteAction,
  deleteSiteAction,
  addUserToSiteAction,
  addSiteContractorAction,
  deleteSiteContractorAction,
  addGatewayToSiteAction,
  deleteUserFromSiteAction,
  deleteGatewayFromSiteAction,
  addThresholdToSiteAction,
  deleteThresholdFromSiteAction,
  generateGrafanaConfigAction,
  getWeatherByLatLngAction,
  clearWeatherAction,
  getAllSiteAggregateThresholdUserMappingsAction,
  updateSiteThresholdUserMappingAction,
  getSiteAssetsAction,
  getPodAssignmentHistoryBySiteAction,
  getAllAggregateThresholdsBySiteAction,
  getActivityLogAction,
  getRiMetricsAction,
  uploadFloorPlanPdfAction,
  getFloorPlanSnapsAction,
  getSiteContractorsAction,
  uploadUsersCSVAction,
  getAllUsersBySiteAction,
  uploadDocumentAction,
} from '../actions/sitesActions'
import {
  getBuildingAction,
  getAllBuildingsAction,
  deleteBuildingAction,
  createBuildingAction,
  addThresholdToBuildingAction,
  deleteThresholdFromBuildingAction,
  getAllBuildingThresholdUserMappingsAction,
  updateBuildingThresholdUserMappingAction,
} from '../actions/buildingsActions'
import {
  getAllDeploymentsBySiteAction,
  getAllShipmentsAction,
  deleteDeploymentAction,
  getDeploymentAction,
  getShipmentAction,
  regeneratePackingListAction,
  getDeploymentEditHistoryAction,
} from '../actions/deploymentsActions'
import {
  getAllFloorsAction,
  updateFloorAction,
  uploadFloorPlanAction,
  deleteFloorAction,
  getFloorAction,
  removeBuildingFromFloorAction,
  clearCurrentFloorAction,
  getAllFloorSummariesAction,
  getFloorSummaryAction,
  addThresholdToFloorAction,
  deleteThresholdFromFloorAction,
  getAllFloorThresholdUserMappingsAction,
  updateFloorThresholdUserMappingAction,
  getAllFloorsByBuildingAction,
  updateSortOrderAction,
} from '../actions/floorsActions'
import {
  getFlowMonitorDataAction,
  getAllFlowMonitorsAction,
  getAllAvailableFlowMonitorsBySiteAction,
} from '../actions/flowMonitorsActions'
import {
  getLocationAlertHistoryAction,
  getLocationUptimeDataAction,
} from '../actions/alertsActions'
import {
  getAllLocationsBySiteAction,
  getAllLocationsByFloorAction,
  getLocationAction,
  getLocationChartDataAction,
  deleteLocationAction,
  addThresholdToLocationAction,
  deleteThresholdFromLocationAction,
  removePodFromLocationAction,
  removeFlowMonitorFromLocationAction,
  toggleLocationOptionAction,
  setMapLocationCoordinatesAction,
  updateMapLocationCoordinatesAction,
  deleteMapLocationAction,
  clearVisibleLocationsAction,
  getAllLocationThresholdUserMappingsAction,
  updateLocationThresholdUserMappingAction,
  updateGatewayMapLocationAction,
  deleteGatewayMapLocationAction,
  getPodAssignmentHistoryByLocationAction,
  getLocationSnapshotAction,
} from '../actions/locationsActions'
import { getAllCompaniesAction } from '../actions/companiesActions'
import { getAllEscalationPoliciesAction } from '../actions/escalationPoliciesActions'
import {
  getAllAvailablePodsBySiteAction,
  getPodMetadataAction,
  markPodAsLostAction,
} from '../actions/podsActions'
import {
  getAllCommentsAction,
  getAllTagsAction,
  deleteCommentAction,
  createCommentAction,
  updateCommentAction,
} from '../actions/commentsActions'
import {
  getAllAvailableGatewaysAction,
  getSiteGatewaysAction,
} from '../actions/gatewaysActions'
import { getAllThresholdsAction } from '../actions/thresholdsActions'
import { getAllAssetLocationsAction } from '../actions/assetLocationsActions'
import { getAllNetworksAction } from '../actions/networksActions'
import { getAllLocationGroupsAction } from '../actions/locationGroupActions'
import {
  getVisibleSites,
  getVisibleSiteContractors,
  getCurrentSite,
  getSitesMeta,
  getCurrentWeather,
  getSiteAggregateThresholdUserMappings,
  getCurrentSiteAssets,
  getVisibleAggregateThresholds,
  getVisiblePodAssignmentBySiteHistory,
  getVisibleActivityLog,
  getActivityLogMeta,
  getVisibleRiMetrics,
  getSiteFloorPlanSnaps,
  getCSVUploadResults,
  getVisibleSiteUsers,
} from '../reducers/sitesReducer'
import {
  getVisibleAlertsByLocation,
  getCurrentLocationUptimeData,
  getAlertsMeta,
} from '../reducers/alertsReducer'
import { getVisibleCompanies } from '../reducers/companiesReducer'
import {
  getAvailableVisibleGateways,
  getVisibleSiteGateways,
} from '../reducers/gatewaysReducer'
import {
  getVisibleBuildings,
  getCurrentBuilding,
  getBuildingThresholdUserMappings,
} from '../reducers/buildingsReducer'
import {
  getVisibleFloorsWithSummaries,
  getVisibleFloors,
  getCurrentFloor,
  getFloorThresholdUserMappings,
} from '../reducers/floorsReducer'
import {
  getVisibleFlowMonitorData,
  getAvailableVisibleFlowMonitors,
} from '../reducers/flowMonitorsReducer'
import {
  getVisibleLocations,
  getCurrentLocation,
  getCurrentLocationChartData,
  getCurrentLocationChartColumns,
  getLocationThresholdUserMappings,
  getLocationSnapshot,
  getVisiblePodAssignmentByLocationHistory,
} from '../reducers/locationsReducer'
import {
  getAvailableVisiblePods,
  getAvailableVisibleLeakPods,
  getPodMetadata,
} from '../reducers/podsReducer'
import { getVisibleThresholds } from '../reducers/thresholdsReducer'
import { getAccountProfile } from '../reducers/accountReducer'
import {
  getVisibleDeployments,
  getCurrentDeployment,
  getVisibleDeploymentEditHistory,
} from '../reducers/deploymentsReducer'
import {
  getVisibleShipments,
  getCurrentShipment,
} from '../reducers/shipmentsReducer'
import { getVisibleEscalationPolicies } from '../reducers/escalationPoliciesReducer'
import {
  getWindowWidth,
  isSiteLoading,
  isFloorLoading,
  isDeploymentLoading,
  isShipmentLoading,
  isLocationLoading,
  isChartDataLoading,
  isPodLoading,
  isHistoryLoading,
  isCommentLoading,
  isGatewayLoading,
  isCompanyLoading,
  isFlowMonitorLoading,
  isSnapshotLoading,
} from '../reducers/uiReducer'
import { getVisibleComments, getVisibleTags } from '../reducers/commentsReducer'
import { getVisibleAssetLocations } from '../reducers/assetLocationsReducer'
import { getVisibleNetworks } from '../reducers/networksReducer'
import { getVisibleLocationGroups } from '../reducers/locationGroupsReducer'
import { hasDemoModeAccess } from '../utils/hasPermission'
import { createQueryString } from '../utils/queryParams'
import { INSTALL_TYPE, POD_STATUS, ALL } from '../constants'

const filterFloorsIfDemoMode = (floors, sites, account) => {
  const allFloors = getVisibleFloorsWithSummaries(floors)
  const currentSite = getCurrentSite(sites)

  const floorsWithGatewayCounts = currentSite.gateways
    ? allFloors.map(floor => {
        const floorGateways = currentSite.gateways.filter(
          gateway => gateway.floorId === floor.id
        )
        const offlineGatewayCount = floorGateways.filter(
          gateway => !gateway.online
        ).length
        const onlineGatewayCount = floorGateways.filter(
          gateway => gateway.online
        ).length
        return { offlineGatewayCount, onlineGatewayCount, ...floor }
      })
    : allFloors

  return hasDemoModeAccess(getAccountProfile(account))
    ? floorsWithGatewayCounts.filter(x => x.stats.systemAlertsLocationCount < 1)
    : floorsWithGatewayCounts
}

const mergeSiteGatewaysFloorsBuildings = (sites, floors) => {
  const { gateways, ...rest } = getCurrentSite(sites)
  const currentSiteFloors = getVisibleFloors(floors)

  if (!gateways.length) {
    return { gateways, ...rest }
  }

  return {
    ...rest,
    gateways: gateways.map(gateway => {
      let floorName = ''
      let buildingName = ''
      if (gateway.floorId) {
        const floor = currentSiteFloors.filter(
          floor => floor.id === gateway.floorId
        )
        if (floor.length) {
          floorName = floor[0].name
          buildingName = floor[0].buildingName
        }
      }
      return { floorName, buildingName, ...gateway }
    }),
  }
}

const filterSiteAssetsIfUninstall = (sites, deployments) => {
  const deployment = getCurrentDeployment(deployments)
  const assets = getCurrentSiteAssets(sites)

  if (deployment.installType === INSTALL_TYPE.UNINSTALL) {
    const { nonDeployedPods, ...rest } = assets
    let nonReservedPods = {}
    Object.keys(nonDeployedPods).forEach(podStatus => {
      if (podStatus.toUpperCase() !== POD_STATUS.RESERVED) {
        nonReservedPods = Object.assign(nonReservedPods, {
          [podStatus]: nonDeployedPods[podStatus],
        })
      }
    })
    return {
      ...rest,
      nonDeployedPods: nonReservedPods,
    }
  }
  return assets
}

const mapStateToProps = ({
  alerts,
  sites,
  companies,
  gateways,
  buildings,
  floors,
  flowMonitors,
  locations,
  pods,
  thresholds,
  escalationPolicies,
  account,
  deployments,
  shipments,
  ui,
  comments,
  assetLocations,
  form,
  networks,
  locationGroups,
}) => ({
  currentShipment: getCurrentShipment(shipments),
  podMetadata: getPodMetadata(pods),
  comments: getVisibleComments(comments),
  tags: getVisibleTags(comments),
  snapshotData: getLocationSnapshot(locations),
  sites: getVisibleSites(sites),
  siteContractors: getVisibleSiteContractors(sites),
  currentSite: mergeSiteGatewaysFloorsBuildings(sites, floors),
  currentDeployment: getCurrentDeployment(deployments),
  currentPodAssignmentHistory: getVisiblePodAssignmentByLocationHistory(
    locations
  ),
  allPodsAssignmentHistory: getVisiblePodAssignmentBySiteHistory(sites),
  companies: getVisibleCompanies(companies),
  allAvailableGateways: getAvailableVisibleGateways(gateways),
  sitesMeta: getSitesMeta(sites),
  alertsMeta: getAlertsMeta(alerts),
  buildings: getVisibleBuildings(buildings),
  currentBuilding: getCurrentBuilding(buildings),
  floors: filterFloorsIfDemoMode(floors, sites, account),
  availableFlowMonitors: getAvailableVisibleFlowMonitors(flowMonitors),
  flowMonitorData: getVisibleFlowMonitorData(flowMonitors),
  deployments: getVisibleDeployments(deployments),
  siteShipments: getVisibleShipments(shipments),
  siteGateways: getVisibleSiteGateways(gateways),
  siteAssets: filterSiteAssetsIfUninstall(sites, deployments),
  currentFloor: getCurrentFloor(floors),
  locations: getVisibleLocations(locations),
  locationAlertHistory: getVisibleAlertsByLocation(alerts),
  currentLocation: getCurrentLocation(locations),
  currentLocationChartData: getCurrentLocationChartData(locations),
  currentLocationChartColumns: getCurrentLocationChartColumns(locations),
  allAvailablePods: getAvailableVisiblePods(pods),
  allAvailableLeakPods: getAvailableVisibleLeakPods(pods),
  thresholds: getVisibleThresholds(thresholds),
  escalationPolicies: getVisibleEscalationPolicies(escalationPolicies),
  accountProfile: getAccountProfile(account),
  isSiteLoading: isSiteLoading(ui),
  isFloorLoading: isFloorLoading(ui),
  isFlowMonitorLoading: isFlowMonitorLoading(ui),
  isLocationLoading: isLocationLoading(ui),
  isHistoryLoading: isHistoryLoading(ui),
  isChartDataLoading: isChartDataLoading(ui),
  isDeploymentLoading: isDeploymentLoading(ui),
  isShipmentLoading: isShipmentLoading(ui),
  isPodMetadataLoading: isPodLoading(ui),
  isCommentLoading: isCommentLoading(ui),
  isGatewayLoading: isGatewayLoading(ui),
  isCompanyLoading: isCompanyLoading(ui),
  isSnapshotLoading: isSnapshotLoading(ui),
  windowWidth: getWindowWidth(ui),
  packingFormValues: getFormValues('PackingForm')({ form }),
  editSiteFloorPlanFormValues: getFormValues('EditSiteFloorPlanForm')({ form }),
  createDeploymentFormValues: getFormValues('CreateDeploymentForm')({ form }),
  siteFormValues: getFormValues('EditSiteForm')({ form }),
  shipmentFormValues: getFormValues('ShipmentForm')({ form }),
  currentSiteWeather: getCurrentWeather(sites),
  siteAggregateThresholdUserMappings: getSiteAggregateThresholdUserMappings(
    sites
  ),
  floorThresholdUserMappings: getFloorThresholdUserMappings(floors),
  locationThresholdUserMappings: getLocationThresholdUserMappings(locations),
  siteThresholdMappings: getVisibleAggregateThresholds(sites),
  activityLog: getVisibleActivityLog(sites),
  activityLogMeta: getActivityLogMeta(sites),
  riMetrics: getVisibleRiMetrics(sites),
  siteFloorPlanSnaps: getSiteFloorPlanSnaps(sites),
  currentLocationUptimeData: getCurrentLocationUptimeData(alerts),
  csvUploadResults: getCSVUploadResults(sites),
  buildingThresholdUserMappings: getBuildingThresholdUserMappings(buildings),
  deploymentEditHistory: getVisibleDeploymentEditHistory(deployments),
  assetLocations: getVisibleAssetLocations(assetLocations),
  networks: getVisibleNetworks(networks),
  locationGroups: getVisibleLocationGroups(locationGroups),
  siteUsers: getVisibleSiteUsers(sites),
})

const mapDispatchToProps = dispatch => ({
  getLocationAlertHistory: (siteSlug, floorId, locationId, qs) =>
    dispatch(
      getLocationAlertHistoryAction.request({
        siteSlug,
        floorId,
        locationId,
        qs,
      })
    ),
  getAllAvailablePods: (siteSlug, qs) =>
    dispatch(getAllAvailablePodsBySiteAction.request({ siteSlug, qs })),
  markPodLost: (id, locationId, floorId, siteSlug) =>
    dispatch(
      markPodAsLostAction.request({ id, locationId, floorId, siteSlug })
    ),
  getPodMetadata: () => dispatch(getPodMetadataAction.request()),
  getAllSites: qs => dispatch(getAllSitesAction.request(qs)),
  deleteSite: siteSlug => dispatch(deleteSiteAction.request(siteSlug)),
  getSite: (
    siteSlug,
    shouldFetchBuildings = true,
    shouldFetchAvailableUsers = false
  ) =>
    dispatch(
      getSiteAction.request({
        siteSlug,
        shouldFetchBuildings,
        shouldFetchAvailableUsers,
      })
    ),
  getDeployment: (siteSlug, deploymentSlug) =>
    dispatch(getDeploymentAction.request({ siteSlug, deploymentSlug })),
  getPodAssignmentHistory: (siteSlug, floorId, locationId) =>
    dispatch(
      getPodAssignmentHistoryByLocationAction.request({
        siteSlug,
        floorId,
        locationId,
      })
    ),
  getAllPodsAssignmentHistory: siteSlug =>
    dispatch(getPodAssignmentHistoryBySiteAction.request(siteSlug)),
  getShipment: shipmentId => dispatch(getShipmentAction.request(shipmentId)),
  getAdminSite: siteSlug => dispatch(getAdminSiteAction.request(siteSlug)),
  getAllCompanies: qs => dispatch(getAllCompaniesAction.request(qs)),
  getAllAvailableGateways: siteSlug =>
    dispatch(getAllAvailableGatewaysAction.request(siteSlug)),
  addUserToSite: (siteSlug, userId) =>
    dispatch(addUserToSiteAction.request({ siteSlug, userId })),
  addSiteContractor: (siteSlug, companySlug) =>
    dispatch(addSiteContractorAction.request({ siteSlug, companySlug })),
  deleteSiteContractor: (siteSlug, contractorId) =>
    dispatch(deleteSiteContractorAction.request({ siteSlug, contractorId })),
  deleteUserFromSite: (siteSlug, userId) =>
    dispatch(deleteUserFromSiteAction.request({ siteSlug, userId })),
  deleteDeployment: (siteSlug, deploymentSlug) =>
    dispatch(deleteDeploymentAction.request({ siteSlug, deploymentSlug })),
  getAllDeploymentsBySite: siteSlug =>
    dispatch(getAllDeploymentsBySiteAction.request(siteSlug)),
  getAllShipments: (siteSlug, qs) =>
    dispatch(getAllShipmentsAction.request({ siteSlug, qs })),
  addGatewayToSite: (siteSlug, gatewayId) =>
    dispatch(addGatewayToSiteAction.request({ siteSlug, gatewayId })),
  deleteGatewayFromSite: (siteSlug, gatewayId) =>
    dispatch(deleteGatewayFromSiteAction.request({ siteSlug, gatewayId })),
  getBuilding: (siteSlug, id) =>
    dispatch(getBuildingAction.request({ siteSlug, id })),
  getAllFloors: (siteSlug, qs) =>
    dispatch(getAllFloorsAction.request({ siteSlug, qs })),
  getSiteContractors: (siteSlug, qs) =>
    dispatch(getSiteContractorsAction.request({ siteSlug, qs })),
  getAllBuildings: (siteSlug, qs) =>
    dispatch(getAllBuildingsAction.request({ siteSlug, qs })),
  updateFloor: floor => dispatch(updateFloorAction.request(floor)),
  uploadFloorPlan: (siteSlug, floorId, formData) =>
    dispatch(uploadFloorPlanAction.request({ siteSlug, floorId, formData })),
  uploadFloorPlanPdf: (siteSlug, formData) =>
    dispatch(uploadFloorPlanPdfAction.request({ siteSlug, formData })),
  getFloorPlanSnaps: siteSlug =>
    dispatch(getFloorPlanSnapsAction.request(siteSlug)),
  uploadDocument: payload => dispatch(uploadDocumentAction.request(payload)),
  deleteFloor: (siteSlug, floorId) =>
    dispatch(deleteFloorAction.request({ siteSlug, floorId })),
  deleteBuilding: (siteSlug, buildingId) =>
    dispatch(deleteBuildingAction.request({ siteSlug, buildingId })),
  createBuilding: (siteSlug, building, name, inFloorPlan) =>
    dispatch(
      createBuildingAction.request({ siteSlug, building, name, inFloorPlan })
    ),
  getFloor: (siteSlug, floorId) =>
    dispatch(getFloorAction.request({ siteSlug, floorId })),
  getFloorSummary: (siteSlug, floorId) =>
    dispatch(getFloorSummaryAction.request({ siteSlug, floorId })),
  getAllFloorSummaries: siteSlug =>
    dispatch(getAllFloorSummariesAction.request({ siteSlug })),
  getAllFlowMonitors: qs => dispatch(getAllFlowMonitorsAction.request(qs)),
  getFlowMonitorData: (pillarId, qs) =>
    dispatch(getFlowMonitorDataAction.request({ pillarId, qs })),
  getAllAvailableFlowMonitorsBySite: siteSlug =>
    dispatch(getAllAvailableFlowMonitorsBySiteAction.request(siteSlug)),
  removeBuildingFromFloor: () => dispatch(removeBuildingFromFloorAction()),
  getAllLocationsBySite: (siteSlug, qs) =>
    dispatch(
      getAllLocationsBySiteAction.request({
        siteSlug,
        qs,
      })
    ),
  getAllGatewaysBySite: siteSlug =>
    dispatch(getSiteGatewaysAction.request(siteSlug)),
  updateGatewayMapLocation: gateway =>
    dispatch(updateGatewayMapLocationAction.request(gateway)),
  deleteGatewayMapLocation: gatewayId =>
    dispatch(deleteGatewayMapLocationAction.request({ gatewayId })),
  getSiteAssets: siteSlug => dispatch(getSiteAssetsAction.request(siteSlug)),
  getAllLocationsByFloor: (siteSlug, floorId) =>
    dispatch(getAllLocationsByFloorAction.request({ siteSlug, floorId })),
  getLocation: (siteSlug, floorId, id) =>
    dispatch(getLocationAction.request({ siteSlug, floorId, id })),
  getLocationChartData: (siteSlug, floorId, id, qs) =>
    dispatch(getLocationChartDataAction.request({ siteSlug, floorId, id, qs })),
  deleteLocation: (siteSlug, floorId, id) =>
    dispatch(deleteLocationAction.request({ siteSlug, floorId, id })),
  getAllThresholds: qs => dispatch(getAllThresholdsAction.request(qs)),
  getAllEscalationPolicies: () =>
    dispatch(
      getAllEscalationPoliciesAction.request(
        createQueryString({ perPage: ALL })
      )
    ),
  addThresholdToSite: (siteSlug, thresholdId) =>
    dispatch(addThresholdToSiteAction.request({ siteSlug, thresholdId })),
  deleteThresholdFromSite: (siteSlug, thresholdId) =>
    dispatch(deleteThresholdFromSiteAction.request({ siteSlug, thresholdId })),
  addThresholdToBuilding: (siteSlug, buildingId, thresholdId) =>
    dispatch(
      addThresholdToBuildingAction.request({
        siteSlug,
        buildingId,
        thresholdId,
      })
    ),
  deleteThresholdFromBuilding: (siteSlug, buildingId, thresholdId) =>
    dispatch(
      deleteThresholdFromBuildingAction.request({
        siteSlug,
        buildingId,
        thresholdId,
      })
    ),
  addThresholdToFloor: (siteSlug, floorSlug, thresholdId, floorId) =>
    dispatch(
      addThresholdToFloorAction.request({
        siteSlug,
        floorSlug,
        thresholdId,
        floorId,
      })
    ),
  deleteThresholdFromFloor: (siteSlug, floorSlug, thresholdId, floorId) =>
    dispatch(
      deleteThresholdFromFloorAction.request({
        siteSlug,
        floorSlug,
        thresholdId,
        floorId,
      })
    ),
  addThresholdToLocation: (siteSlug, floorId, locationId, thresholdId) =>
    dispatch(
      addThresholdToLocationAction.request({
        siteSlug,
        floorId,
        locationId,
        thresholdId,
      })
    ),
  deleteThresholdFromLocation: (siteSlug, floorId, locationId, thresholdId) =>
    dispatch(
      deleteThresholdFromLocationAction.request({
        siteSlug,
        floorId,
        locationId,
        thresholdId,
      })
    ),
  removePodFromLocation: () => dispatch(removePodFromLocationAction()),
  removeFlowMonitorFromLocation: () =>
    dispatch(removeFlowMonitorFromLocationAction()),
  generateGrafanaConfig: siteSlug =>
    dispatch(generateGrafanaConfigAction.request(siteSlug)),
  toggleLocationOption: location =>
    dispatch(toggleLocationOptionAction.request(location)),
  setMapLocationCoordinates: coordinates =>
    dispatch(setMapLocationCoordinatesAction(coordinates)),
  updateMapLocationCoordinates: (siteSlug, floorId, location) =>
    dispatch(
      updateMapLocationCoordinatesAction.request({
        siteSlug,
        floorId,
        location,
      })
    ),
  deleteMapLocation: ({ siteSlug, floorId, id }) =>
    dispatch(deleteMapLocationAction.request({ siteSlug, floorId, id })),
  clearVisibleLocations: () => dispatch(clearVisibleLocationsAction()),
  clearCurrentFloor: () => dispatch(clearCurrentFloorAction()),
  updateQueryParams: location => dispatch(replace(location)),
  updateSortOrder: (siteSlug, sortOrder) =>
    dispatch(updateSortOrderAction.request({ siteSlug, sortOrder })),
  getWeather: payload => dispatch(getWeatherByLatLngAction.request(payload)),
  clearWeather: () => dispatch(clearWeatherAction()),
  getAllTags: qs => dispatch(getAllTagsAction.request(qs)),
  getAllComments: qs => dispatch(getAllCommentsAction.request(qs)),
  deleteComment: comment => dispatch(deleteCommentAction.request(comment)),
  createComment: comment => dispatch(createCommentAction.request(comment)),
  editComment: comment => dispatch(updateCommentAction.request(comment)),
  getSiteAggregateThresholdUserMappings: payload =>
    dispatch(getAllSiteAggregateThresholdUserMappingsAction.request(payload)),
  updateSiteThresholdUserMapping: payload =>
    dispatch(updateSiteThresholdUserMappingAction.request(payload)),
  getLocationThresholdUserMappings: (siteSlug, floorId, locationId) =>
    dispatch(
      getAllLocationThresholdUserMappingsAction.request({
        siteSlug,
        floorId,
        locationId,
      })
    ),
  updateLocationThresholdUserMapping: payload =>
    dispatch(updateLocationThresholdUserMappingAction.request(payload)),
  getLocationSnapshot: (siteSlug, floorId, locationId) =>
    dispatch(
      getLocationSnapshotAction.request({
        siteSlug,
        floorId,
        locationId,
      })
    ),
  getAggregateThresholdsBySite: siteSlug =>
    dispatch(
      getAllAggregateThresholdsBySiteAction.request({
        siteSlug,
        qs: createQueryString({ full: 't' }),
      })
    ),
  getFloorThresholdUserMappings: (siteSlug, floorId) =>
    dispatch(
      getAllFloorThresholdUserMappingsAction.request({ siteSlug, floorId })
    ),
  updateFloorThresholdUserMapping: payload =>
    dispatch(updateFloorThresholdUserMappingAction.request(payload)),
  regeneratePackingList: (siteSlug, deploymentSlug) =>
    dispatch(regeneratePackingListAction.request({ siteSlug, deploymentSlug })),
  getActivityLog: (siteSlug, qs) =>
    dispatch(getActivityLogAction.request({ siteSlug, qs })),
  getRiMetrics: (siteSlug, qs) =>
    dispatch(getRiMetricsAction.request({ siteSlug, qs })),
  getLocationUptimeData: (siteSlug, floorId, locationId, qs) =>
    dispatch(
      getLocationUptimeDataAction.request({
        siteSlug,
        floorId,
        locationId,
        qs,
      })
    ),
  uploadUsersCSV: (siteSlug, formData) =>
    dispatch(uploadUsersCSVAction.request({ siteSlug, formData })),
  getAllFloorsByBuilding: (siteSlug, buildingId) =>
    dispatch(getAllFloorsByBuildingAction.request({ siteSlug, buildingId })),
  getBuildingThresholdUserMappings: (siteSlug, buildingId) =>
    dispatch(
      getAllBuildingThresholdUserMappingsAction.request({
        siteSlug,
        buildingId,
      })
    ),
  updateBuildingThresholdUserMapping: payload =>
    dispatch(updateBuildingThresholdUserMappingAction.request(payload)),
  getDeploymentEditHistory: (siteSlug, deploymentSlug) =>
    dispatch(
      getDeploymentEditHistoryAction.request({ siteSlug, deploymentSlug })
    ),
  getAllAssetLocations: qs => dispatch(getAllAssetLocationsAction.request(qs)),
  getAllNetworks: payload => dispatch(getAllNetworksAction.request(payload)),
  getAllLocationGroups: payload =>
    dispatch(getAllLocationGroupsAction.request(payload)),
  getAllUsersBySite: siteSlug =>
    dispatch(getAllUsersBySiteAction.request({ siteSlug })),
})

const SitesPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SitesPage)

export { SitesPageContainer as default, mergeSiteGatewaysFloorsBuildings }
