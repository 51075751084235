import React, { Component } from 'react'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import moment from 'moment'
import BatchUpdatePodsModal from './BatchUpdatePodsModal'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Tooltip,
  RadioButton,
  RadioGroup,
  Spin,
} from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import PodsCSVUpload from './PodsCSVUpload'
import CalibrationCSVUpload from './CalibrationCSVUpload'
import CalibrationZipfileUpload from './CalibrationZipfileUpload'
import GeneratePillarIDsForm from './forms/GeneratePillarIDsForm'
import Select, { Option } from './common/Select'
import { toTitleCase, renderPodType } from '../utils/textFormatters'
import { hasPermission } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { generatePillarIDsAction } from '../actions/podsActions'
import {
  ALERT_TYPES,
  POD_STATUS,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  VIEW_ALL,
  UNASSIGNED,
  CONNECTIVITY_STATUS,
  BATTERY_INDICATOR,
  USER_PERMISSIONS,
  POD_TYPES,
} from '../constants'
import { configurePagination } from '../utils/helpers'
import LastSnapshot from './LastSnapshot'
import { dateFormatter } from '../utils/date'

class AllPods extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.pods,
      searchText: '',
      filtered: false,
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      isModalVisible: false,
      online: undefined,
      podType: undefined,
      batteryIndicator: undefined,
      status: undefined,
      firmwareVersion: undefined,
      productVersion: undefined,
      siteSlug: undefined,
      assetLocationId: undefined,
      selectedPods: [],
    }
  }

  static propTypes = {
    isSnapshotLoading: PropTypes.bool.isRequired,
    snapshotData: PropTypes.object.isRequired,
    getLocationSnapshot: PropTypes.func.isRequired,
    pods: PropTypes.arrayOf(PropTypes.object).isRequired,
    windowWidth: PropTypes.number.isRequired,
    isPodLoading: PropTypes.bool.isRequired,
    uploadPodsCSV: PropTypes.func.isRequired,
    uploadCalibrationCSV: PropTypes.func.isRequired,
    csvUploadResults: PropTypes.object.isRequired,
    getAllPods: PropTypes.func.isRequired,
    deletePod: PropTypes.func,
    meta: PropTypes.object,
    getPodMetadata: PropTypes.func,
    podMetadata: PropTypes.object.isRequired,
    getAllSites: PropTypes.func,
    getAllAssetLocations: PropTypes.func,
    sites: PropTypes.arrayOf(PropTypes.object),
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    updateQueryParams: PropTypes.func,
    batchUpdatePods: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.pods })
  }

  componentDidMount() {
    const {
      getAllPods,
      getPodMetadata,
      getAllSites,
      getAllAssetLocations,
    } = this.props

    const {
      page,
      perPage,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
    } = parseQueryParams(global.location)

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
    }

    this.setState(query)

    const allQs = createQueryString({ perPage: 'all' })

    getAllPods(createQueryString(query))
    getPodMetadata && getPodMetadata()
    getAllSites && getAllSites(allQs)
    getAllAssetLocations && getAllAssetLocations(allQs)
  }

  handleChange = (pagination, filters, sorter) => {
    const { updateQueryParams, getAllPods } = this.props
    const {
      page,
      perPage,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
      searchText,
    } = this.state

    if (page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        online,
        podType,
        batteryIndicator,
        status,
        firmwareVersion,
        productVersion,
        siteSlug,
        assetLocationId,
        filter: searchText,
      }

      const qs = createQueryString(query)

      getAllPods(qs)

      updateQueryParams && updateQueryParams({ search: qs })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllPods, updateQueryParams } = this.props
    const {
      page,
      perPage,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        online,
        podType,
        batteryIndicator,
        status,
        firmwareVersion,
        productVersion,
        siteSlug,
        assetLocationId,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllPods(qs)

    updateQueryParams && updateQueryParams({ search: qs })
  }

  handleRowSelectionChange = (selectedPodIds, selectedPods) =>
    this.setState({ selectedPods })

  handleModalOpen = () => this.setState({ isModalVisible: true })
  handleModalCancel = () => this.setState({ isModalVisible: false })

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deletePod,
      meta,
      windowWidth,
      isPodLoading,
      getAllPods,
      podMetadata,
      isSnapshotLoading,
      snapshotData,
      getLocationSnapshot,
      sites,
      assetLocations,
      batchUpdatePods,
      csvUploadResults,
      uploadPodsCSV,
      uploadCalibrationCSV,
    } = this.props
    const {
      data,
      sortedInfo,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
      searchText,
      page,
      perPage,
      isModalVisible,
      selectedPods,
    } = this.state

    console.log(sortedInfo)

    const isFiltering = !!(
      (online && online.length) ||
      (podType && podType.length) ||
      (batteryIndicator && batteryIndicator.length) ||
      (firmwareVersion && firmwareVersion.length) ||
      (productVersion && productVersion.length) ||
      (status && status.length) ||
      (siteSlug && siteSlug.length) ||
      (assetLocationId && assetLocationId.length)
    )

    const {
      firmwareVersions,
      productVersions,
      leakFirmwareVersions,
      leakProductVersions,
    } = podMetadata
    const selectedFirmwareVersions =
      podType === POD_TYPES.SMART
        ? firmwareVersions
        : podType === POD_TYPES.LEAK_POD
        ? leakFirmwareVersions
        : [...new Set([...firmwareVersions, ...leakFirmwareVersions])]
    const selectedProductVersions =
      podType === POD_TYPES.SMART
        ? productVersions
        : podType === POD_TYPES.LEAK_POD
        ? leakProductVersions
        : [...new Set([...productVersions, ...leakProductVersions])]

    const { firmwareCounts, productVersionCounts, statusCounts } = meta

    const isFixedColumn = windowWidth < 750

    const expandedRowRender = record => (
      <div className="mt2">
        {record.locationId ? (
          <LastSnapshot
            siteSlug={record.siteSlug}
            floorId={record.floorId}
            locationId={record.locationId}
            isSnapshotLoading={isSnapshotLoading}
            snapshotData={snapshotData}
            getLocationSnapshot={getLocationSnapshot}
          />
        ) : (
          <p className="tc">No location available.</p>
        )}
      </div>
    )

    const query = {
      page,
      perPage,
      online,
      podType,
      batteryIndicator,
      status,
      firmwareVersion,
      productVersion,
      siteSlug,
      assetLocationId,
    }

    return (
      <section className="mb5 AllPods">
        <div>
          {hasPermission(USER_PERMISSIONS.ADD_POD) && (
            <div>
              <Anchor
                to="/inventory/pods/create"
                button
                className="mr3 mb3 dib"
              >
                Create New
              </Anchor>
              <div className="dn dib-ns mr3 mb3">
                <PodsCSVUpload
                  getAllPods={getAllPods}
                  csvUploadResults={csvUploadResults}
                  uploadPodsCSV={uploadPodsCSV}
                />
              </div>
              <div className="dn dib-ns mr3 mb3">
                <CalibrationCSVUpload
                  uploadCalibrationCSV={uploadCalibrationCSV}
                />
              </div>
              <div className="dn dib-ns mr3 mb3">
                <CalibrationZipfileUpload />
              </div>
              <div className="dib mr3 mb3">
                <GeneratePillarIDsForm
                  submitAction={generatePillarIDsAction}
                  initialValues={{ podType: POD_TYPES.SMART }}
                />
              </div>
              {hasPermission(USER_PERMISSIONS.VIEW_OTA_LOG) && (
                <Anchor to="/inventory/ota" button className="mr3 dib mb3">
                  View OTA Logs
                </Anchor>
              )}
              <div className="dib red">
                {isFiltering && (
                  <p>Found {meta.total} pods matching your criteria</p>
                )}
              </div>
              {selectedPods.length > 0 && (
                <ActionAnchor onClick={this.handleModalOpen}>
                  Bulk Update ({selectedPods.length.toString()} Smart Pods)
                </ActionAnchor>
              )}
            </div>
          )}
        </div>
        <div className="flex-l items-center-l">
          <RadioGroup
            onChange={({ target: { value } }) =>
              this.handleFilterChange('online')(value)
            }
            value={online}
            className="mb3 mr3"
            size="small"
          >
            <RadioButton value={undefined}>All</RadioButton>
            <RadioButton value={CONNECTIVITY_STATUS.ONLINE}>Online</RadioButton>
            <RadioButton value={CONNECTIVITY_STATUS.OFFLINE}>
              Offline
            </RadioButton>
          </RadioGroup>
          <RadioGroup
            onChange={({ target: { value } }) =>
              this.handleFilterChange('podType')(value)
            }
            value={podType}
            className="mb3 mr3"
            size="small"
          >
            <RadioButton value={undefined}>All</RadioButton>
            <RadioButton value={POD_TYPES.SMART}>Smart</RadioButton>
            <RadioButton value={POD_TYPES.LEAK_POD}>Leak</RadioButton>
          </RadioGroup>
          <RadioGroup
            onChange={({ target: { value } }) =>
              this.handleFilterChange('batteryIndicator')(value)
            }
            value={batteryIndicator}
            className="mb3"
            size="small"
          >
            <RadioButton value={undefined}>All Battery</RadioButton>
            <RadioButton value={BATTERY_INDICATOR.OLD}>Old</RadioButton>
            <RadioButton value={BATTERY_INDICATOR.NEW}>New</RadioButton>
          </RadioGroup>
        </div>
        <div className="flex-l items-center-l">
          <Select
            className="w-20-l mr3-l"
            placeholder="Asset Location"
            mode="multiple"
            input={{
              value: assetLocationId,
              onChange: this.handleFilterChange('assetLocationId'),
            }}
            filterable
          >
            {[
              { id: 'all', pillarId: VIEW_ALL },
              { id: null, pillarId: UNASSIGNED },
              ...assetLocations,
            ].map(x => (
              <Option value={x.id} key={x.id}>
                {x !== 'all' || x !== null
                  ? x.pillarId
                  : x !== 'all'
                  ? UNASSIGNED
                  : VIEW_ALL}
              </Option>
            ))}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Status"
            mode="multiple"
            input={{
              value: status,
              onChange: this.handleFilterChange('status'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...Object.keys(POD_STATUS)].map(x => {
              const index = statusCounts.map(y => y.key).indexOf(x)

              if (index > -1) {
                const { value } = statusCounts[index]
                return (
                  <Option value={x} key={x} className="pr5">
                    {toTitleCase(POD_STATUS[x])}{' '}
                    <span className="b">({value})</span>
                  </Option>
                )
              }

              return (
                <Option value={x} key={x}>
                  {toTitleCase(POD_STATUS[x]) || VIEW_ALL}
                </Option>
              )
            })}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Firmware Version"
            mode="multiple"
            input={{
              value: firmwareVersion,
              onChange: this.handleFilterChange('firmwareVersion'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...selectedFirmwareVersions.filter(x => !!x)].map(x => {
              const index = firmwareCounts.map(y => y.key).indexOf(x)

              if (index > -1) {
                const { value } = firmwareCounts[index]
                return (
                  <Option value={x} key={x} className="pr5">
                    {toTitleCase(x)} <span className="b">({value})</span>
                  </Option>
                )
              }

              return (
                <Option value={x} key={x}>
                  {x !== 'all' ? x : VIEW_ALL}
                </Option>
              )
            })}
          </Select>
          <Select
            className="w-20-l mr3-l"
            placeholder="Product Version"
            mode="multiple"
            input={{
              value: productVersion,
              onChange: this.handleFilterChange('productVersion'),
            }}
            dropdownMatchSelectWidth={false}
            filterable
          >
            {['all', ...selectedProductVersions]
              .filter(x => !!x)
              .map(x => {
                const index = productVersionCounts.map(y => y.key).indexOf(x)

                if (index > -1) {
                  const { value } = productVersionCounts[index]
                  return (
                    <Option value={x} key={x} className="pr5">
                      {toTitleCase(x)} <span className="b">({value})</span>
                    </Option>
                  )
                }

                return (
                  <Option value={x} key={x}>
                    {x !== 'all' ? x : VIEW_ALL}
                  </Option>
                )
              })}
          </Select>
          <Select
            className="w-20-l"
            placeholder="Site"
            mode="multiple"
            input={{
              value: siteSlug,
              onChange: this.handleFilterChange('siteSlug'),
            }}
            filterable
          >
            {[{ slug: 'all', name: VIEW_ALL }, ...sites].map(x => (
              <Option value={x.slug} key={x.slug}>
                {x.name}
              </Option>
            ))}
          </Select>
        </div>
        <SearchFilter
          searchText={searchText}
          setSearchText={this.setSearchText}
          getItems={getAllPods}
          query={query}
        />
        {isPodLoading ? (
          <Spin size="large" className="w-100 center mt5" />
        ) : (
          <Table
            className="nested-table"
            dataSource={data}
            onChange={this.handleChange}
            rowSelection={{ onChange: this.handleRowSelectionChange }}
            expandedRowRender={expandedRowRender}
            rowKey="id"
            scroll={{ x: 650 }}
            bordered
            pagination={configurePagination({
              perPage,
              onShowSizeChange: (page, perPage) =>
                this.handleFilterChange('perPage')(perPage),
              ...meta,
            })}
            tableLayout="auto"
            rowClassName={record =>
              moment(record.deploymentCreatedAt).isBefore(
                moment().subtract(1, 'year')
              )
                ? 'bg-light-red'
                : ''
            }
          >
            <Column
              title="Pillar ID"
              dataIndex="pillarId"
              sorter={sortBy('pillarId')}
              sortOrder={sortedInfo.field === 'pillarId' && sortedInfo.order}
              width={150}
              render={(text, record) => {
                const anchor = (
                  <Anchor
                    to={`/inventory/pods/${record.pillarId}`}
                    className="pl2"
                  >
                    {text}
                  </Anchor>
                )

                return record.locationAlertActive ? (
                  record.locationAlertType === ALERT_TYPES.THRESHOLD ? (
                    <span className="flex items-center">
                      <Tooltip title="Active Alert">
                        <Icon type="exclamation-circle-o" className="red" />{' '}
                        <span className="b">{anchor}</span>
                      </Tooltip>
                    </span>
                  ) : (
                    <span className="flex items-center">
                      <Tooltip title="Offline">
                        <Icon type="poweroff" className="red" />{' '}
                        <span className="b">{anchor}</span>
                      </Tooltip>
                    </span>
                  )
                ) : (
                  <span className="flex items-center">
                    <Tooltip title="Online">
                      <Icon type="check-circle-o" className="green" /> {anchor}
                    </Tooltip>
                  </span>
                )
              }}
            />
            <Column
              title="Site"
              dataIndex="siteSlug"
              sorter={sortBy('siteSlug')}
              sortOrder={sortedInfo.field === 'siteSlug' && sortedInfo.order}
              width={100}
              render={text => {
                const index = sites.map(x => x.slug).indexOf(text)

                return text ? (
                  <Anchor to={`/sites/${text}`}>
                    {index !== -1 ? sites[index].name : '--'}
                  </Anchor>
                ) : (
                  '--'
                )
              }}
            />
            <Column
              title="Module ID"
              dataIndex="moduleId"
              sorter={sortBy('moduleId')}
              sortOrder={sortedInfo.field === 'moduleId' && sortedInfo.order}
              width={200}
              render={text => text || '--'}
            />
            <Column
              title="Type"
              dataIndex="podType"
              sorter={sortBy('podType')}
              sortOrder={sortedInfo.field === 'podType' && sortedInfo.order}
              width={200}
              render={renderPodType}
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              width={100}
              render={text => (text ? toTitleCase(text) : '--')}
            />
            <Column
              title="Deployment Date"
              dataIndex="deploymentCreatedAt"
              sorter={sortBy('deploymentCreatedAt')}
              sortOrder={
                sortedInfo.field === 'deploymentCreatedAt' && sortedInfo.order
              }
              width={200}
              render={text => (text ? dateFormatter(text) : '--')}
            />
            <Column
              title="Asset Location"
              dataIndex="assetLocationPillarId"
              sorter={sortBy('assetLocationPillarId')}
              sortOrder={
                sortedInfo.field === 'assetLocationPillarId' && sortedInfo.order
              }
              width={100}
              render={text => text || '--'}
            />
            <Column
              title="Sensors"
              dataIndex="hasDefectiveSensors"
              width={100}
              render={(text, record) => {
                const defectiveSensors = record.hasDefectiveSensors ? (
                  <Tooltip title="Defective sensors on Smart Pod">
                    <Icon type="warning" className="red" />
                  </Tooltip>
                ) : (
                  <Tooltip title="All Smart Pod sensors working">
                    <Icon type="check-circle" className="green" />
                  </Tooltip>
                )

                const calibrations = record.hasCalibrations ? (
                  <Tooltip title="Smart Pod sensors calibrated">
                    <Icon type="sliders" className="red" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Smart Pod sensors uncalibrated">
                    <Icon type="sliders" className="moon-gray" />
                  </Tooltip>
                )

                return (
                  <div className="flex justify-between">
                    <span style={{ width: 20 }} className="dib">
                      {defectiveSensors}
                    </span>
                    <span style={{ width: 20 }} className="dib">
                      {calibrations}
                    </span>
                  </div>
                )
              }}
            />
            <Column
              title="Firmware Version"
              dataIndex="firmwareVersion"
              sorter={sortBy('firmwareVersion')}
              sortOrder={
                sortedInfo.field === 'firmwareVersion' && sortedInfo.order
              }
              width={100}
              render={text => text || '--'}
            />
            <Column
              title="Product Version"
              dataIndex="productVersion"
              sorter={sortBy('productVersion')}
              sortOrder={
                sortedInfo.field === 'productVersion' && sortedInfo.order
              }
              width={100}
              render={text => text || '--'}
            />
            <Column
              fixed={isFixedColumn ? 'right' : false}
              width={100}
              render={(text, record) => {
                const menu = (
                  <Menu>
                    <Menu.Item key="link-labs-pod">
                      <Anchor
                        to={`https://conductor.link-labs.com/symphony/${record.moduleId}`}
                      >
                        View in Link Labs
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item key="edit-pod">
                      <Anchor to={`/inventory/pods/${record.pillarId}/edit`}>
                        Edit
                      </Anchor>
                    </Menu.Item>
                    <Menu.Item key="delete-pod">
                      <ActionAnchor onClick={() => deletePod(record.id)}>
                        Delete
                      </ActionAnchor>
                    </Menu.Item>
                  </Menu>
                )

                return (
                  <Dropdown overlay={menu} trigger={['click']}>
                    <a className="ant-dropdown-link">
                      Actions <Icon type="down" />
                    </a>
                  </Dropdown>
                )
              }}
            />
          </Table>
        )}
        <BatchUpdatePodsModal
          selectedPods={selectedPods}
          assetLocations={assetLocations}
          podMetadata={podMetadata}
          isModalVisible={isModalVisible}
          handleModalCancel={this.handleModalCancel}
          batchUpdatePods={payload => {
            // TODO not ideal
            if (payload.status) {
              this.setState({
                selectedPods: this.state.selectedPods.map(x => ({
                  ...x,
                  status: payload.status,
                })),
              })
            }

            batchUpdatePods(payload)
          }}
        />
      </section>
    )
  }
}

export default AllPods
