import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { H3 } from './common/Headers'
import { ActionAnchor, Anchor } from './common/Anchor'
import Divider from './common/Divider'
import Block from './common/Block'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane, Spin } from './common/Ant'
import AllComments from './AllComments'
import Shipment from './Shipment'
import AllShipments from './AllShipments'
import EditHistory from './EditHistory'
import Logo from './common/Logo'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import { toTitleCase } from '../utils/textFormatters'
import { getPathSnippets } from '../utils/helpers'
import { hasPermission } from '../utils/hasPermission'
import { formatPhoneNumber } from '../utils/forms'
import {
  DEPLOYMENT_STATUS,
  INSTALL_TYPE,
  RESOURCE_TYPES,
  USER_PERMISSIONS,
} from '../constants'

class Deployment extends Component {
  static propTypes = {
    deployment: PropTypes.object.isRequired,
    shipmentFormValues: PropTypes.object,
    site: PropTypes.object.isRequired,
    getDeployment: PropTypes.func.isRequired,
    getAllShipments: PropTypes.func.isRequired,
    regeneratePackingList: PropTypes.func.isRequired,
    getSite: PropTypes.func.isRequired,
    isDeploymentLoading: PropTypes.bool.isRequired,
    isSiteLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
    pods: PropTypes.arrayOf(PropTypes.object),
    gateways: PropTypes.arrayOf(PropTypes.object),
    siteAssets: PropTypes.object,
    siteShipments: PropTypes.arrayOf(PropTypes.object),
    getSiteAssets: PropTypes.func.isRequired,
    isCommentLoading: PropTypes.bool.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    getEditHistory: PropTypes.func.isRequired,
    editHistory: PropTypes.arrayOf(PropTypes.object),
    isHistoryLoading: PropTypes.bool.isRequired,
  }

  state = {
    areShipmentsFinished: false,
    commentEndTime: undefined,
    commentStartTime: undefined,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const { siteSlug, deploymentSlug } = this.props.match.params
    const { getDeployment, getSite } = this.props

    getSite(siteSlug, false)
    getDeployment(siteSlug, deploymentSlug)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    if (entity !== match.params.deploymentSlug) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === activeTab ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'details' })
    }
  }

  render() {
    const {
      match,
      deployment,
      site,
      shipmentFormValues,
      isDeploymentLoading,
      isSiteLoading,
      getSiteAssets,
      getAllShipments,
      siteShipments,
      siteAssets,
      isCommentLoading,
      comments,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      regeneratePackingList,
      editHistory,
      getEditHistory,
      isHistoryLoading,
    } = this.props

    const {
      areShipmentsFinished,
      commentStartTime,
      commentEndTime,
    } = this.state

    const {
      status,
      deploymentType,
      slug,
      installType,
      estimatedPods,
      estimatedLeakPods,
      estimatedFlowMonitors,
      estimatedGateways,
      actualPods,
      actualLeakPods,
      actualFlowMonitors,
      actualGateways,
      podsList,
      gatewaysList,
      flowMonitorsList,
      leakPodsList,
      estimatedDate,
      shippingDate,
      deliveryDate,
      flowMonitors,
      shipments,
      pods,
      gateways,
      leakPods,
      anyGatewaySupply,
      description,
      commentsCount,
      responsiblePartyUser,
    } = deployment

    const { siteSlug, deploymentSlug } = this.props.match.params

    const hasShipped = status === DEPLOYMENT_STATUS.SHIPPED
    const isDelivered = status === DEPLOYMENT_STATUS.DELIVERED
    const wasDeploymentJustCreated = status === DEPLOYMENT_STATUS.CREATED
    const isUninstall = installType === INSTALL_TYPE.UNINSTALL
    const commentsTabTitle = `Comments (${
      comments && comments.length > 0 ? comments.length : commentsCount
    })`

    let allPacked
    if (shipments) {
      const [
        shipmentPodCount,
        shipmentGatewayCount,
        shipmentFlowMonitorCount,
        shipmentLeakPodCount,
      ] = shipments.reduce(
        (mem, shipment) => {
          mem[0] = mem[0] + shipment.podsList.length
          mem[1] = mem[1] + shipment.gatewaysList.length
          mem[2] = mem[2] + shipment.flowMonitorsList.length
          mem[3] = mem[3] + shipment.leakPodsList.length

          return mem
        },
        [0, 0, 0, 0, 0]
      )

      allPacked =
        shipmentPodCount === podsList.length &&
        shipmentGatewayCount === gatewaysList.length &&
        shipmentFlowMonitorCount === flowMonitorsList.length &&
        shipmentLeakPodCount === leakPodsList.length
    }

    const deploymentTitle = description
      ? description
      : dateTimeFormatter(estimatedDate, true)

    // show shipment form if the deployment's been assigned resources
    const shouldShowShipmentForm = isUninstall
      ? !isDelivered
      : (pods && !!pods.length) ||
        (gateways && !!gateways.length) ||
        (flowMonitors && !!flowMonitors.length) ||
        (leakPods && !!leakPods.length) ||
        anyGatewaySupply

    const shouldShowShipmentsFinishedButton =
      shipments &&
      !!shipments.length &&
      !areShipmentsFinished &&
      !wasDeploymentJustCreated &&
      !hasShipped &&
      !isDelivered &&
      !allPacked

    return isDeploymentLoading && isSiteLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Deployment">
        <div className="mb3">
          <Bread>
            <Crumb>
              <Anchor to="/sites">Sites</Anchor>
            </Crumb>
            {site.slug && (
              <Crumb>
                <Anchor to={`/sites/${site.slug}`}>{site.name}</Anchor>
              </Crumb>
            )}
            <Crumb>
              <Anchor to={`/sites/${site.slug}/deployments`}>
                Deployments
              </Anchor>
            </Crumb>
            <Crumb>{deploymentTitle}</Crumb>
          </Bread>
        </div>
        {deployment.status === DEPLOYMENT_STATUS.CREATED &&
          (hasPermission(USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT) ||
            (isUninstall &&
              hasPermission(USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT))) && (
            <div className="flex justify-center mb3">
              <Anchor to={`${match.url}/edit`}>Edit</Anchor>
            </div>
          )}
        <div className="flex justify-center mb3">
          <Logo companyName={site.companyName} companyLogo={site.companyLogo} />
        </div>
        <Divider />
        {wasDeploymentJustCreated &&
          !isUninstall &&
          hasPermission(USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT) && (
            <Fragment>
              <Anchor
                to={`/sites/${siteSlug}/deployments/${deploymentSlug}/packing`}
                className="mb3"
                button
              >
                Generate Packing List
              </Anchor>
              <Divider />
            </Fragment>
          )}
        {shouldShowShipmentForm && (
          <Shipment
            deployment={deployment}
            siteAssets={siteAssets}
            isDeploymentLoading={isDeploymentLoading}
            regeneratePackingList={regeneratePackingList}
            shipmentFormValues={shipmentFormValues}
            siteName={site.name}
            siteSlug={siteSlug}
            isUninstall={isUninstall}
            areShipmentsFinished={areShipmentsFinished}
            hasShipped={hasShipped}
            isDelivered={isDelivered}
            getSiteAssets={getSiteAssets}
            getAllShipments={getAllShipments}
            siteShipments={siteShipments}
          />
        )}
        {shouldShowShipmentsFinishedButton && (
          <Fragment>
            <p className="b">
              If all shipments in this Deployment are complete, please{' '}
              <ActionAnchor
                onClick={() => this.setState({ areShipmentsFinished: true })}
              >
                set a shipment date
              </ActionAnchor>
              .
            </p>
            <Divider />
          </Fragment>
        )}
        {shipments && !!shipments.length && (
          <AllShipments
            deploymentSlug={slug}
            siteSlug={siteSlug}
            shipments={shipments}
            shippingDate={shippingDate}
          />
        )}
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/details`} />}
          />
          <Fragment>
            <Tabs
              defaultActiveKey="details"
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Details" key="details">
                <Route
                  path={`${match.url}/details`}
                  exact
                  render={props => (
                    <Fragment>
                      <H3>Deployment Details</H3>
                      <div className="flex flex-wrap">
                        <Block label="Status">{toTitleCase(status)}</Block>
                        <Block label="Deployment Type">
                          {toTitleCase(deploymentType)}
                        </Block>
                        <Block label="Install Type">
                          {toTitleCase(installType)}
                        </Block>
                        <Block label="Estimated Date">
                          {dateFormatter(estimatedDate)}
                        </Block>
                        <Block label="Shipping Date">
                          {dateFormatter(shippingDate)}
                        </Block>
                        <Block label="Delivery Date">
                          {dateFormatter(deliveryDate)}
                        </Block>
                        {wasDeploymentJustCreated ? (
                          <Fragment>
                            <Block label="Estimated Smart Pods">
                              {estimatedPods ? estimatedPods : '0'}
                            </Block>
                            <Block label="Estimated Leak Pucks">
                              {estimatedLeakPods ? estimatedLeakPods : '0'}
                            </Block>
                            <Block label="Estimated Network Gateways">
                              {estimatedGateways ? estimatedGateways : '0'}
                            </Block>
                            <Block label="Estimated Water Monitors">
                              {estimatedFlowMonitors
                                ? estimatedFlowMonitors
                                : '0'}
                            </Block>
                          </Fragment>
                        ) : (
                          <Fragment>
                            <Block label="Smart Pods">
                              {actualPods ? actualPods : '0'}
                            </Block>
                            <Block label="Leak Pucks">
                              {actualLeakPods ? actualLeakPods : '0'}
                            </Block>
                            <Block label="Network Gateways">
                              {actualGateways ? actualGateways : '0'}
                            </Block>
                            <Block label="Water Monitors">
                              {actualFlowMonitors ? actualFlowMonitors : '0'}
                            </Block>
                            <Block label="Any Gateway Supplies">
                              {anyGatewaySupply ? 'Yes' : 'No'}
                            </Block>
                          </Fragment>
                        )}
                        <Block label="Responsible Party">
                          {responsiblePartyUser.id && (
                            <div>
                              <div>
                                {responsiblePartyUser.firstName}{' '}
                                {responsiblePartyUser.lastName}
                              </div>
                              <div>
                                {responsiblePartyUser.phoneNumber && (
                                  <a
                                    className="dark-blue link"
                                    href={`tel:${responsiblePartyUser.phoneNumber}`}
                                  >
                                    {formatPhoneNumber(
                                      responsiblePartyUser.phoneNumber
                                    )}
                                  </a>
                                )}
                              </div>
                              <div>
                                {responsiblePartyUser.email && (
                                  <a
                                    className="dark-blue link"
                                    href={`mailto:${responsiblePartyUser.email}`}
                                  >
                                    {responsiblePartyUser.email}
                                  </a>
                                )}
                              </div>
                            </div>
                          )}
                        </Block>
                      </div>
                    </Fragment>
                  )}
                />
              </TabPane>
              {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
                <TabPane tab={commentsTabTitle} key="comments">
                  <Route
                    path={`${match.url}/comments`}
                    exact
                    render={props => (
                      <AllComments
                        resourceType={RESOURCE_TYPES.DEPLOYMENT}
                        resourceSlug={slug}
                        startTime={commentStartTime}
                        endTime={commentEndTime}
                        isCommentLoading={isCommentLoading}
                        getAllComments={getAllComments}
                        deleteComment={deleteComment}
                        createComment={createComment}
                        editComment={editComment}
                        getAllTags={getAllTags}
                        tags={tags}
                        comments={comments}
                        showDateFields={false}
                      />
                    )}
                  />
                </TabPane>
              )}
              <TabPane tab="Edit History" key="edit-history">
                <Route
                  path={`${match.url}/edit-history`}
                  exact
                  render={props => (
                    <EditHistory
                      editHistory={editHistory}
                      getEditHistory={slug => getEditHistory(siteSlug, slug)}
                      resourceId={slug}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
            </Tabs>
          </Fragment>
        </Switch>
      </section>
    )
  }
}

export default Deployment
