import { combineReducers } from 'redux'
import {
  getAdminDashboardAction,
  getAllSiteImuNanoIotAction,
  getImuDataAction,
} from '../actions/dashboardActions'
import produce from 'immer'

const counts = (
  state = {
    activeAlerts: 0,
    activeActionableAlerts: 0,
    activeInformationalAlerts: 0,
    activeSystemAlerts: 0,
    activeSites: 0,
    activeGateways: 0,
    activePods: 0,
    activeFlowMonitors: 0,
    activeFlowAlerts: 0,
    activeActionableFlowAlerts: 0,
    activeInformationalFlowAlerts: 0,
    activeSystemFlowAlerts: 0,
    activeLeakPods: 0,
    activeLeakAlerts: 0,
    activeActionableLeakAlerts: 0,
    activeInformationalLeakAlerts: 0,
    activeSystemLeakAlerts: 0,
  },
  { type, payload }
) => {
  switch (type) {
    case getAdminDashboardAction.SUCCESS:
      return payload
    default:
      return state
  }
}

const allSiteImuNanoIots = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getAllSiteImuNanoIotAction.SUCCESS:
        draft.splice(0, draft.length, ...payload)
        break
    }
  })

const allImuData = (state = [], { type, payload }) =>
  produce(state, draft => {
    switch (type) {
      case getImuDataAction.SUCCESS:
        Object.assign(draft, payload)
        break
    }
  })

const dashboardReducer = combineReducers({
  allImuData,
  allSiteImuNanoIots,
  counts,
})

const getDashboardCounts = ({ counts }) => counts
const getVisibleAllImuNanoIots = ({ allSiteImuNanoIots }) => allSiteImuNanoIots
const getVisibleImuData = ({ allImuData }) => allImuData

export {
  dashboardReducer as default,
  getDashboardCounts,
  getVisibleAllImuNanoIots,
  getVisibleImuData,
}
