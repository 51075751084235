import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditSiteProposalForm from '../components/forms/EditSiteProposalForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { createSiteAction } from '../actions/sitesActions'
import { createQueryString } from '../utils/queryParams'

class CreateSiteProposal extends Component {
  static propTypes = {
    getAllCompanies: PropTypes.func.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  }

  componentDidMount() {
    const qs = createQueryString({ perPage: 'all' })

    this.props.getAllCompanies(qs)
  }

  render() {
    const { companies } = this.props
    const crumbs = [<Anchor to="/sites">Sites</Anchor>, 'Create Site Proposal']

    return (
      <section className="CreateSiteProposal">
        <FormHeader crumbs={crumbs} />
        <EditSiteProposalForm
          companies={companies}
          submitAction={createSiteAction}
          initialValues={{
            estimatedPods: 0,
            estimatedGateways: 0,
            estimatedFlowMonitors: 0,
            estimatedLeakPods: 0,
            contractValue: 0,
          }}
        />
      </section>
    )
  }
}

export default CreateSiteProposal
