import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import PropTypes from 'prop-types'
import InputContainer from '../../containers/common/InputContainer'
import SelectContainer from '../../containers/common/SelectContainer'
import CheckboxGroup from '../common/CheckboxGroup'
import Checkbox from '../common/Checkbox'
import Form from '../common/Form'
import FormError from '../common/FormError'
import { H3 } from '../common/Headers'
import { Spin } from '../common/Ant'
import { Option } from '../common/Select'
import Button from '../common/Button'
import CancelButton from '../common/CancelButton'
import { isAlphaNumeric } from '../../utils/validators'
import { SHIPPING_VENDOR } from '../../constants'
import { formatShippingVendors } from '../../utils/textFormatters'

class EditShipmentForm extends Component {
  static propTypes = {
    submitAction: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    match: PropTypes.object.isRequired,
    error: PropTypes.string,
    shipment: PropTypes.object,
    getShipment: PropTypes.func.isRequired,
    isShipmentLoading: PropTypes.bool,
  }

  componentDidMount() {
    const { shipmentId } = this.props.match.params
    this.props.getShipment(shipmentId)
  }

  onChange = (key, val) => this.setState({ [key]: val })

  generateCheckboxOptions = options =>
    options.map(option => ({
      label: option.pillarId,
      value: `${option.id}`,
    }))

  render() {
    const {
      handleSubmit,
      submitting,
      error,
      submitAction,
      isShipmentLoading,
    } = this.props
    const { siteSlug, deploymentSlug } = this.props.match.params
    const submit = handleSubmit(submitAction)

    const {
      pods,
      leakPods,
      anyGatewaySupply,
      gateways,
      flowMonitors,
    } = this.props.shipment

    return isShipmentLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="EditShipmentForm">
        <H3>Edit Shipment</H3>
        <Form onSubmit={submit}>
          <div className="flex flex-wrap">
            {!!pods.length && (
              <Field
                name="pods"
                component={CheckboxGroup}
                availableOptions={this.generateCheckboxOptions(pods)}
                label="Smart Pods"
                className="w-100-ns flex-wrap"
              />
            )}
            {!!leakPods.length && (
              <Field
                name="leakPods"
                component={CheckboxGroup}
                availableOptions={this.generateCheckboxOptions(leakPods)}
                label="Leak Pucks"
                className="w-100-ns flex-wrap"
              />
            )}
            {!!gateways.length && (
              <Field
                name="gateways"
                component={CheckboxGroup}
                availableOptions={this.generateCheckboxOptions(gateways)}
                label="Network Gateways"
                className="w-100-ns flex-wrap"
              />
            )}
            {!!flowMonitors.length && (
              <Field
                name="flowMonitors"
                component={CheckboxGroup}
                availableOptions={this.generateCheckboxOptions(flowMonitors)}
                label="Water Monitors"
                className="w-100-ns flex-wrap"
              />
            )}
            {anyGatewaySupply && (
              <Field
                name="anyGatewaySupply"
                type="checkbox"
                component={Checkbox}
                label="All Gateway Supplies"
                className="w-100-ns flex-wrap"
              />
            )}
            <Field
              name="trackingNumber"
              component={InputContainer}
              label="Tracking Number"
              validate={[isAlphaNumeric]}
              className="w-100"
            />
            <Field
              name="vendor"
              component={SelectContainer}
              label="Vendor"
              className="w-100"
              filterable
            >
              {Object.keys(SHIPPING_VENDOR).map(x => (
                <Option value={x} key={x}>
                  {formatShippingVendors(SHIPPING_VENDOR[x])}
                </Option>
              ))}
            </Field>
            <Field
              name="returnTrackingNumber"
              component={InputContainer}
              label="Return Tracking Number"
              validate={[isAlphaNumeric]}
              className="w-100"
            />
            <Field
              name="returnVendor"
              component={SelectContainer}
              label="Return Vendor"
              className="w-100"
              filterable
            >
              {Object.keys(SHIPPING_VENDOR).map(y => (
                <Option value={y} key={y}>
                  {formatShippingVendors(SHIPPING_VENDOR[y])}
                </Option>
              ))}
            </Field>
          </div>
          <div className="db flex justify-between mv3">
            <CancelButton
              defaultLocation={`/sites/${siteSlug}/deployments/${deploymentSlug}`}
            />
            <Button
              text="Submit Shipment Update"
              type="submit"
              submitting={submitting}
            />
          </div>
          <FormError error={error} />
        </Form>
      </section>
    )
  }
}
export default reduxForm({ form: 'EditShipmentForm' })(EditShipmentForm)
