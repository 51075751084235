import React, { Component } from 'react'
import PropTypes from 'prop-types'
import EditFlowMonitorForm from './forms/EditFlowMonitorForm'
import { Anchor } from './common/Anchor'
import FormHeader from './common/FormHeader'
import { updateFlowMonitorAction } from '../actions/flowMonitorsActions'
import { createQueryString } from '../utils/queryParams'

class EditFlowMonitor extends Component {
  static propTypes = {
    setWaterValveFields: PropTypes.func.isRequired,
    setWaterMeterFields: PropTypes.func.isRequired,
    flowMonitor: PropTypes.object.isRequired,
    getFlowMonitor: PropTypes.func.isRequired,
    getAllAvailablePods: PropTypes.func.isRequired,
    getAllAssetLocations: PropTypes.func.isRequired,
    allAvailablePods: PropTypes.arrayOf(PropTypes.object).isRequired,
    assetLocations: PropTypes.arrayOf(PropTypes.object),
    allAvailableWaterValves: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableWaterValves: PropTypes.func.isRequired,
    allAvailableWaterMeters: PropTypes.arrayOf(PropTypes.object).isRequired,
    getAllAvailableWaterMeters: PropTypes.func.isRequired,
    match: PropTypes.object,
  }

  componentDidMount() {
    const {
      getAllAssetLocations,
      getAllAvailablePods,
      getFlowMonitor,
      getAllAvailableWaterValves,
      getAllAvailableWaterMeters,
      match,
    } = this.props
    const qs = createQueryString({ perPage: 'all' })

    getAllAvailableWaterMeters(qs)
    getAllAvailableWaterValves(qs)
    getAllAvailablePods(qs)
    getAllAssetLocations(qs)
    getFlowMonitor(match.params.id)
  }

  render() {
    const {
      flowMonitor,
      allAvailablePods,
      assetLocations,
      setWaterValveFields,
      setWaterMeterFields,
      allAvailableWaterValves,
      allAvailableWaterMeters,
    } = this.props
    const crumbs = [
      <Anchor to="/inventory/all">Inventory</Anchor>,
      <Anchor to="/inventory/all/flow-monitors">Water Monitors</Anchor>,
      'Edit',
      `${flowMonitor && flowMonitor.pillarId ? flowMonitor.pillarId : ''}`,
    ]

    return (
      <section className="EditFlowMonitor">
        <FormHeader crumbs={crumbs} />
        <EditFlowMonitorForm
          allAvailableWaterMeters={allAvailableWaterMeters}
          allAvailableWaterValves={allAvailableWaterValves}
          setWaterValveFields={setWaterValveFields}
          setWaterMeterFields={setWaterMeterFields}
          allAvailablePods={allAvailablePods}
          assetLocations={assetLocations}
          submitAction={updateFlowMonitorAction}
          initialValues={flowMonitor}
          enableReinitialize
        />
      </section>
    )
  }
}

export default EditFlowMonitor
