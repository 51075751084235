import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { reduxForm, Field } from 'redux-form'
import Form from '../common/Form'
import InputContainer from '../../containers/common/InputContainer'
import RadioGroup, { RadioButton } from '../common/RadioGroup'
import Button from '../common/Button'
import FormError from '../common/FormError'
import { Modal } from '../common/Ant'
import { required, isNumeric } from '../../utils/validators'
import { toTitleCase } from '../../utils/textFormatters'
import { POD_TYPES, SIZES } from '../../constants'

class GeneratePillarIDsForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitAction: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    error: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      modalOpen: false,
    }
  }

  handleClose = () => {
    this.setState({ modalOpen: false })
  }

  handleOpen = () => {
    this.setState({ modalOpen: true })
  }

  render() {
    const { handleSubmit, submitting, error, valid, submitAction } = this.props
    const submit = handleSubmit(submitAction)
    const modalSubmit = event => {
      if (valid) {
        this.handleClose()
        submit(event)
      } else {
        event.preventDefault()
      }
    }

    return (
      <section className="GeneratePillarIDsForm">
        <Button
          onClick={this.handleOpen}
          text="Generate Pillar IDs"
          size={SIZES.SMALL}
        />
        <Modal
          title="Generate Pillar IDs"
          visible={this.state.modalOpen}
          footer={null}
          onCancel={this.handleClose}
          style={{ top: 16 }}
          destroyOnClose
        >
          <Form onSubmit={modalSubmit}>
            <Field
              name="count"
              type="text"
              component={InputContainer}
              label="Number of Pillar IDs Needed"
              validate={[required, isNumeric]}
              className="w-100-ns mr3-ns"
            />
            <Field
              name="podType"
              size="medium"
              component={RadioGroup}
              label="Smart Pod Type"
            >
              <RadioButton value={POD_TYPES.SMART}>
                {toTitleCase(POD_TYPES.SMART)}
              </RadioButton>
              <RadioButton value={POD_TYPES.LEAK_POD}>Leak</RadioButton>
            </Field>
            <FormError error={error} />
            <div className="flex justify-between">
              <Button invert text="Cancel" onClick={this.handleClose} />
              <Button text="Submit" type="submit" submitting={submitting} />
            </div>
          </Form>
        </Modal>
      </section>
    )
  }
}

export default reduxForm({ form: 'GeneratePillarIDsForm' })(
  GeneratePillarIDsForm
)
