import React from 'react'
import PropTypes from 'prop-types'
import CreateDeploymentForm from '../components/forms/CreateDeploymentForm'
import { H2 } from './common/Headers'
import { createDeploymentAction } from '../actions/deploymentsActions'

const CreateDeployment = ({ match, formValues, siteUsers }) => {
  return (
    <section className="CreateDeployment">
      <H2>Create Deployment</H2>
      <CreateDeploymentForm
        siteUsers={siteUsers}
        submitAction={createDeploymentAction}
        match={match}
        formValues={formValues}
        initialValues={{
          installType: 'INSTALL',
          deploymentType: 'ONSITE',
          estimatedPods: '0',
          estimatedLeakPods: '0',
          estimatedGateways: '0',
          estimatedFlowMonitors: '0',
        }}
        enableReinitialize
      />
    </section>
  )
}

CreateDeployment.propTypes = {
  match: PropTypes.object,
  formValues: PropTypes.object,
  siteUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CreateDeployment
