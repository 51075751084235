import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Tabs, TabPane, Table, Column, Spin, Tooltip, Icon } from './common/Ant'
import { H2, H3 } from './common/Headers'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import Block from './common/Block'
import Button from './common/Button'
import Checkbox from './common/Checkbox'
import EditHistory from './EditHistory'
import ThresholdScheduler from './ThresholdScheduler'
import { toTitleCase } from '../utils/textFormatters'
import {
  THRESHOLD_LEVELS,
  THRESHOLD_DATA_SOURCES,
  THRESHOLD_ALERT_TYPES,
} from '../constants'
import { getPathSnippets } from '../utils/helpers'

class Threshold extends Component {
  static propTypes = {
    threshold: PropTypes.object.isRequired,
    thresholdAssignments: PropTypes.arrayOf(PropTypes.object).isRequired,
    editHistory: PropTypes.arrayOf(PropTypes.object),
    getEditHistory: PropTypes.func.isRequired,
    getThreshold: PropTypes.func.isRequired,
    getThresholdAssignments: PropTypes.func.isRequired,
    toggleThresholdOption: PropTypes.func.isRequired,
    isThresholdLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    sendTestNotification: PropTypes.func.isRequired,
    match: PropTypes.object,
    updateQueryParams: PropTypes.func.isRequired,
  }

  state = { activeTab: undefined }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
      getThreshold,
      getThresholdAssignments,
    } = this.props

    getThreshold(id)
    getThresholdAssignments(id)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet)
  }

  handleTabSelection = entity => {
    const { updateQueryParams, match } = this.props
    const { activeTab } = this.state

    if (entity !== match.params.id) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === activeTab ? global.location.search : '',
      })
    } else {
      this.setState({ activeTab: 'assignments' })
    }
  }

  renderThresholdLevel(level) {
    switch (level) {
      case THRESHOLD_LEVELS.HIGH:
        return 'High'
      case THRESHOLD_LEVELS.WARNING:
        return 'Medium'
      case THRESHOLD_LEVELS.LOW:
        return 'Low'
    }
  }

  renderThresholdDataSource(source) {
    switch (source) {
      case THRESHOLD_DATA_SOURCES.TEMPERATURE:
        return 'Temperature'
      case THRESHOLD_DATA_SOURCES.PRESSURE:
        return 'Pressure'
      case THRESHOLD_DATA_SOURCES.HUMIDITY:
        return 'Humidity'
      case THRESHOLD_DATA_SOURCES.DUST:
        return 'Dust'
      case THRESHOLD_DATA_SOURCES.SOUND:
        return 'Sound'
      case THRESHOLD_DATA_SOURCES.VOC:
        return 'VOC (Alpha)'
      case THRESHOLD_DATA_SOURCES.CARBON_MONOXIDE:
        return 'Carbon Monoxide'
      case THRESHOLD_DATA_SOURCES.MIC_COUNT:
        return 'Noise Count (> 85 dBA)'
      case THRESHOLD_DATA_SOURCES.DEW_POINT:
        return 'Dew Point'
      case THRESHOLD_DATA_SOURCES.WATER_FLOW:
        return 'Water Flow'
      case THRESHOLD_DATA_SOURCES.LEAK_EVENT:
        return 'Leak Event'
      case THRESHOLD_DATA_SOURCES.LEAK_STATE:
        return 'Leak State'
      default:
        return '--'
    }
  }

  render() {
    const {
      threshold,
      thresholdAssignments,
      match,
      toggleThresholdOption,
      isThresholdLoading,
      sendTestNotification,
      editHistory,
      getEditHistory,
      isHistoryLoading,
    } = this.props
    const {
      id,
      name,
      thresholdAlertType,
      description,
      message,
      level,
      active,
      publishToUser,
      rulesJson,
      schedules,
    } = threshold
    let { visibleToUserRoles } = threshold

    if (!visibleToUserRoles) {
      visibleToUserRoles = []
    }

    return isThresholdLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Threshold">
        <H2 className="flex items-center">
          {name}
          {thresholdAlertType === THRESHOLD_ALERT_TYPES.ACTIONABLE ? (
            <span className="br2 bg-dark-red white pa1 f7 ml2">Actionable</span>
          ) : (
            <span className="br2 bg-gold pa1 f7 ml2">Informational</span>
          )}
          <Divider vertical />{' '}
          <Button
            text="Send Test Notification"
            type="button"
            onClick={() => sendTestNotification(id)}
          />
          <Divider vertical />{' '}
          <small>
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </small>
        </H2>
        <Divider />
        <div className="flex-l">
          <div className="w-100 w-25-l">
            <Checkbox
              type="checkbox"
              label="Active"
              input={{
                checked: active,
                onChange: value =>
                  toggleThresholdOption({
                    ...threshold,
                    active: value,
                  }),
              }}
            />
          </div>
          <div className="w-100 w-25-l">
            <Checkbox
              label="Publish"
              type="checkbox"
              input={{
                checked: publishToUser,
                onChange: value =>
                  toggleThresholdOption({
                    ...threshold,
                    publishToUser: value,
                  }),
              }}
            />
          </div>
        </div>
        <Divider />
        <div className="flex flex-wrap">
          <Block label="Description">{description}</Block>
          <Block label="Message">{message}</Block>
          <Block label="Level">{this.renderThresholdLevel(level)}</Block>
          <Block label="User Roles with Access to Alerts">
            <ul className="pl0 mb0 list">
              {visibleToUserRoles.map(x => (
                <li key={x}>{toTitleCase(x)}</li>
              ))}
            </ul>
          </Block>
        </div>
        <Divider />
        <H3>Rules</H3>
        <Table
          dataSource={rulesJson.map((x, i) => ({ ...x, index: i }))}
          rowKey="index"
          size="small"
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Type"
            dataIndex="thresholdType"
            render={text => (text ? toTitleCase(text) : '--')}
          />
          <Column
            title="Source"
            dataIndex="source"
            render={text => this.renderThresholdDataSource(text)}
          />
          <Column
            title="Low"
            dataIndex="low"
            render={text => (text ? text : '--')}
          />
          <Column
            title="High"
            dataIndex="high"
            render={text => (text ? text : '--')}
          />
          <Column
            title="Duration"
            dataIndex="duration"
            render={text => (text ? `${text}s` : '--')}
          />
        </Table>
        <Divider />
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/assignments`} />}
          />
          <Fragment>
            <Tabs
              defaultActiveKey="assignments"
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              <TabPane tab="Assignments" key="assignments">
                <Route
                  path={`${match.url}/assignments`}
                  exact
                  render={props => (
                    <Table
                      dataSource={thresholdAssignments}
                      rowKey="id"
                      pagination={false}
                      size="small"
                      tableLayout="auto"
                    >
                      <Column
                        title="Site"
                        dataIndex="siteName"
                        render={(text, record) => (
                          <div>
                            <Anchor to={`/sites/${record.siteSlug}`}>
                              {text}
                            </Anchor>
                            {record.siteNotificationsMuted && (
                              <Tooltip title="Threshold Muted">
                                <Icon type="exclamation-circle-o" />
                              </Tooltip>
                            )}
                          </div>
                        )}
                      />
                      <Column
                        title="Location"
                        dataIndex="locationName"
                        render={(text, record) =>
                          text ? (
                            <div>
                              <Anchor
                                to={`/sites/${record.siteSlug}/floors/${record.floorId}/locations/${record.locationId}`}
                              >
                                {text}
                              </Anchor>
                              {record.locationNotificationsMuted && (
                                <Tooltip title="Threshold Muted">
                                  <Icon type="exclamation-circle-o" />
                                </Tooltip>
                              )}
                            </div>
                          ) : (
                            'N/A'
                          )
                        }
                      />
                    </Table>
                  )}
                />
              </TabPane>
              <TabPane tab="Edit History" key="edit-history">
                <Route
                  path={`${match.url}/edit-history`}
                  exact
                  render={props => (
                    <EditHistory
                      editHistory={editHistory}
                      getEditHistory={getEditHistory}
                      resourceId={id}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              <TabPane tab="Schedules" key="schedules">
                <Route
                  path={`${match.url}/schedules`}
                  exact
                  render={props => (
                    <ThresholdScheduler
                      schedules={schedules}
                      thresholdId={id}
                    />
                  )}
                />
              </TabPane>
            </Tabs>
          </Fragment>
        </Switch>
      </section>
    )
  }
}

export default Threshold
