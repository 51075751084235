const ROLES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  PILLAR_ADMIN: 'PILLAR_ADMIN',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  JOBSITE_ADMIN: 'JOBSITE_ADMIN',
  JOBSITE_USER: 'JOBSITE_USER',
  PILLAR_CONTRACTOR: 'PILLAR_CONTRACTOR',
  CONTRACTOR: 'CONTRACTOR',
  SUB_CONTRACTOR: 'SUB_CONTRACTOR',
  HILTI_USER: 'HILTI_USER',
  HARTFORD_FLOW_USER: 'HARTFORD_FLOW_USER',
  KASTLE_USER: 'KASTLE_USER',
}

const ENTITY_TYPES = {
  SITE: 'SITE',
  FLOOR: 'FLOOR',
  LOCATION: 'LOCATION',
  BUILDING: 'BUILDING',
  USER: 'USER',
  THRESHOLD: 'THRESHOLD',
  GATEWAY: 'GATEWAY',
  POD: 'POD',
}

const RESOURCE_TYPES = {
  POD: 'POD',
  LEAK_POD: 'LEAK_POD',
  GATEWAY: 'GATEWAY',
  ALERT: 'ALERT',
  GATEWAY_ALERT: 'GATEWAY_ALERT',
  LOCATION: 'LOCATION',
  DEPLOYMENT: 'DEPLOYMENT',
  FLOW_MONITOR: 'FLOW_MONITOR',
  USER: 'USER',
  SITE: 'SITE',
  FLOOR: 'FLOOR',
  CHAT: 'CHAT',
  REPORT: 'REPORT',
}

const DEPLOYMENT_STATUS = {
  CREATED: 'CREATED',
  DELIVERED: 'DELIVERED',
  PACKING: 'PACKING',
  PACKED: 'PACKED',
  REVIEWED: 'REVIEWED',
  SHIPPED: 'SHIPPED',
}

const REPORT_STATUS = {
  DELIVERED: 'DELIVERED',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
}

const REPORT_TYPES = {
  LOCATIONS_PER_GRAPH: 'LOCATIONS_PER_GRAPH',
  FLOOR_LOCATIONS_PER_GRAPH: 'FLOOR_LOCATIONS_PER_GRAPH',
  FLOOR_AVG_PER_GRAPH: 'FLOOR_AVG_PER_GRAPH',
  ALL_FLOORS_AVG_PER_GRAPH: 'ALL_FLOORS_AVG_PER_GRAPH',
  LOCATION_GROUPS_PER_GRAPH: 'LOCATION_GROUPS_PER_GRAPH',
}

const DEPLOYMENT_TYPE = {
  ONSITE: 'ONSITE',
  DROP: 'DROP',
}

const INSTALL_TYPE = {
  INSTALL: 'INSTALL',
  UNINSTALL: 'UNINSTALL',
}

const SHIPPING_VENDOR = {
  UPS: 'UPS',
  FEDEX: 'FEDEX',
  USPS: 'USPS',
}

const PART_RESOURCE_TYPE = {
  POD: 'POD',
  GATEWAY: 'GATEWAY',
}

const PURCHASE_STATUS = {
  REQUESTED: 'REQUESTED',
  ORDERED: 'ORDERED',
  SHIPPED: 'SHIPPED',
  DELIVERED: 'DELIVERED',
}

const ALL_SUPPLY_TYPES = {
  SMALL_ANTENNA: 'SMALL_ANTENNA',
  MEDIUM_ANTENNA: 'MEDIUM_ANTENNA',
  LARGE_ANTENNA: 'LARGE_ANTENNA',
  MAGNET_MOUNT_CELL_ANTENNA: 'MAGNET_MOUNT_CELL_ANTENNA',
  FOUR_FT_CABLE: '4_FT_CABLE',
  TEN_FT_CABLE: '10_FT_CABLE',
  TWENTY_FIVE_FT_CABLE: '25_FT_CABLE',
  BAG_A_FLUSH_MOUNTING: 'BAG_A_FLUSH_MOUNTING',
  BAG_B_BOLT_KIT: 'BAG_B_BOLT_KIT',
  BAG_C_RAILS_KIT: 'BAG_C_RAILS_KIT',
  BAG_D_ANTENNA_BRACKET: 'BAG_D_ANTENNA_BRACKET',
  BAG_E_DUCK_BILL_CELL: 'BAG_E_DUCK_BILL_CELL',
  TWELVE_FT_POWER_CABLE: '12_FT_POWER_CABLE',
  CONNECTOR_TYPE_N_COUPLING: 'CONNECTOR_TYPE_N_COUPLING',
}

const GATEWAY_SUPPLY_TYPE = {
  MEDIUM_ANTENNA: 'MEDIUM_ANTENNA',
  LARGE_ANTENNA: 'LARGE_ANTENNA',
  MAGNET_MOUNT_CELL_ANTENNA: 'MAGNET_MOUNT_CELL_ANTENNA',
  FOUR_FT_CABLE: '4_FT_CABLE',
  TEN_FT_CABLE: '10_FT_CABLE',
  BAG_A_FLUSH_MOUNTING: 'BAG_A_FLUSH_MOUNTING',
  BAG_B_BOLT_KIT: 'BAG_B_BOLT_KIT',
  BAG_C_RAILS_KIT: 'BAG_C_RAILS_KIT',
  BAG_D_ANTENNA_BRACKET: 'BAG_D_ANTENNA_BRACKET',
  BAG_E_DUCK_BILL_CELL: 'BAG_E_DUCK_BILL_CELL',
  TWELVE_FT_POWER_CABLE: '12_FT_POWER_CABLE',
  CONNECTOR_TYPE_N_COUPLING: 'CONNECTOR_TYPE_N_COUPLING',
}

const FLOW_MONITOR_SUPPLY_TYPE = {
  SMALL_ANTENNA: 'SMALL_ANTENNA',
  TWENTY_FIVE_FT_CABLE: '25_FT_CABLE',
  BAG_A_FLUSH_MOUNTING: 'BAG_A_FLUSH_MOUNTING',
  BAG_B_BOLT_KIT: 'BAG_B_BOLT_KIT',
  BAG_C_RAILS_KIT: 'BAG_C_RAILS_KIT',
  BAG_E_DUCK_BILL_CELL: 'BAG_E_DUCK_BILL_CELL',
  TWELVE_FT_POWER_CABLE: '12_FT_POWER_CABLE',
  CONNECTOR_TYPE_N_COUPLING: 'CONNECTOR_TYPE_N_COUPLING',
}

const SITE_DOCUMENT_TYPES = {
  INVOICE: 'INVOICE',
  CHANGE_ORDERS: 'CHANGE_ORDERS',
  SCOPE_OF_WORK: 'SCOPE_OF_WORK',
  PROPOSALS: 'PROPOSALS',
}

const MESSAGES = {
  CONFIRM: 'Are you sure?',
  CONFIRM_GATEWAY_RESTART:
    'Are you sure? This will physically reset the gateway via Link Labs.',
  CONFIRM_WATER_VALVE_OPEN:
    'Are you sure? This will physically turn on water valve. It takes a minute to come into effect!',
  CONFIRM_WATER_VALVE_CLOSE:
    'Are you sure? This will physically turn off water valve. It takes a minute to come into effect!',
  CONFIRM_SITE_COMPLETION:
    'Marking this Site as "Completed" will remove all remaining assigned hardware and mark them as "Lost".',
  SUCCESSFULLY_CREATED: 'Successfully created!',
  COPIED: 'Copied!',
  SUCCESSFULLY_RETRIEVED_PODS: 'Successfully retrieved pods!',
  RETRIEVAL_FAILED: 'Unable to retrieve specified pods',
  SUCCESSFULLY_UPDATED: 'Successfully updated!',
  SUCCESSFULLY_UPLOADED: 'Successfully uploaded!',
  SUCCESSFULLY_DELETED: 'Successfully deleted!',
  SUCCESSFULLY_ADDED_USER: 'Successfully added user!',
  SUCCESSFULLY_IMPORTED_USERS: 'Successfully imported users!',
  SUCCESSFULLY_DELETED_USER: 'Successfully removed user!',
  SUCCESSFULLY_ADDED_CONTRACTOR: 'Successfully added contractor!',
  SUCCESSFULLY_DELETED_CONTRACTOR: 'Successfully removed contractor!',
  SUCCESSFULLY_ADDED_GATEWAY: 'Successfully added gateway!',
  SUCCESSFULLY_DELETED_GATEWAY: 'Successfully removed gateway!',
  SUCCESSFULLY_ADDED_THRESHOLD: 'Successfully added threshold!',
  SUCCESSFULLY_DELETED_THRESHOLD: 'Successfully removed threshold!',
  SUCCESSFULLY_RESTARTED: 'Successfully restarted gateway!',
  SUCCESSFULLY_HID_ALERT: 'Successfully hid alert!',
  SUCCESSFULLY_UNHID_ALERT: 'Successfully unhid alert!',
  SUCCESSFULLY_CLOSE_ALERT: 'Successfully closed alert!',
  SUCCESSFULLY_ACKNOWLEDGE_ALERT: 'Successfully acknowledged alert!',
  SUCCESSFULLY_RESOLVE_ALERT: 'Successfully resolved alert!',
  SUCCESSFULLY_SENT_TEST_NOTIFICATION: 'Successfully sent test notification!',
  SUCCESSFULLY_REQUESTED_PHONE_VERIFICATION:
    'Success! Please check your text messages for your new verification code.',
  SUCCESSFULLY_VERIFIED_PHONE: 'Successfully verified phone number!',
  SUCCESSFULLY_GENERATED_REPORT: 'Success!',
  SUCCESSFULLY_GENERATED_ACCESS_TOKEN:
    "Successfully generated access token! Please copy your new access token now. You won't be able to see it again.",
  SUCCESSFULLY_GENERATED_ALERT_EXPORT:
    'Your alert export is being generated. You will receive an email when it has completed.',
  SUCCESSFULLY_GENERATED_USER_EXPORT:
    'Your user export is being generated. You will receive an email when it has completed.',
  SUCCESSFULLY_RETRIEVED_PREVIEW: 'Successfully retrieved preview data!',
  SUCCESSFULLY_SCHEDULED_FIRMWARE_UPDATE:
    'Successfully scheduled Smart Pod firmware update!',
  SUCCESSFULLY_UPDATED_ESCALATION_POLICY:
    'Successfully updated escalation policy!',
  SUCCESSFULLY_RESENT_VERIFICATION: 'Successfully resent verification code!',
  SUCCESSFULLY_RESENT_CONFIRMATION: 'Successfully resent confirmation email!',
  CONFIRM_WORKFLOW_UPDATE:
    'Are you sure? This will overwrite any previous workflows of this type.',
  SUCCESSFULLY_REQUESTED_FILE_DOWNLOAD:
    'Your request is being processed. When it completes, your file will be downloaded.',
  SUCCESSFULLY_DOWNLOADED: 'Successfully downloaded!',
  SUCCESSFULLY_UPLOADED_FLOORPLAN_PDF:
    'Your PDF is being processed. You will receive an email when it has completed with a link to continue the Floor Plan creation process.',
  SUCCESSFULLY_BRIDGED_PHONE_CALL:
    'Success! Your phone call will now be connected. Please follow further instructions on the call.',
  CONFIRM_THRESHOLD_GROUP_DEFAULT:
    'Please consider what you are about to do. This group is set as a default for customer usage. Are you sure you want to delete it?',
  SUCCESSFULLY_CONVERTED: 'Successfully generated Pillar IDs from proposal.',
  SUCCESSFULLY_UPLOADED_CALIBRATION_CSV:
    'Your Calibration CSV is being processed. You will receive an email when it has been completed.',
  SUCCESSFULLY_UPLOADED_CALIBRATION_ZIPFILE:
    'Your Calibration upload is being processed. You will receive an email when it has been completed.',
}

const GENERIC_ERROR_MESSAGE = 'An error occurred.'

const ERROR_MESSAGES = {
  CREDENTIALS:
    'The username and password you entered did not match our records. Please double-check and try again.',
  SERVER: 'A server error occurred. Please try again.',
  GENERIC: 'An error occurred. Please try again.',
  TOKEN:
    'Invalid or expired token. Please request a new token by submitting your email address again.',
  INVALID_CODE:
    'Invalid or expired code. Please request to resend verification code.',
  CONFIRMED_USER:
    'Your account is already confirmed. Please log in using your email and password. You will be redirected in a few seconds.',
}

const ERROR_TRIGGERS = {
  FAILED_TO_FETCH: 'Failed to fetch',
  TOKEN_EXPIRED: 'token_expired',
  UNAUTHORIZED: 401,
  CONFIRMED_USER: 'confirmed_user',
}

const ERROR_MESSAGE_LIST = [
  {
    apiKey: 'forbidden',
    message: 'You do not have permission to complete this action.',
  },
  {
    apiKey: 'site_not_found',
    message: 'Site not found!',
  },
  {
    apiKey: 'site_gateway_not_found',
    message: 'Network Gateway not found!',
  },
  {
    apiKey: 'site_user_not_found',
    message: 'User not found!',
  },
  {
    apiKey: 'user_not_found',
    message: 'User not found!',
  },
  {
    apiKey: 'site_contact_cannot_delete',
    message:
      'User is listed as a site contact. Please remove user as a contact before attempting to remove from site',
  },
  {
    apiKey: 'gateway_not_found',
    message: 'Network Gateway not found!',
  },
  {
    apiKey: 'threshold_not_found',
    message: 'Threshold not found!',
  },
  {
    apiKey: 'alert_not_found',
    message: 'Alert not found!',
  },
  {
    apiKey: 'floor_not_found',
    message: 'Floor not found!',
  },
  {
    apiKey: 'building_not_found',
    message: 'Building not found!',
  },
  {
    apiKey: 'company_not_found',
    message: 'Company not found!',
  },
  {
    apiKey: 'pod_not_found',
    message: 'Smart Pod not found!',
  },
  {
    apiKey: 'file_not_found',
    message: 'File not found!',
  },
  {
    apiKey: 'deployment_not_found',
    message: 'Deployment not found!',
  },
  {
    apiKey: 'shipment_not_found',
    message: 'Shipment not found!',
  },
  {
    apiKey: 'location_not_found',
    message: 'Location not found!',
  },
  {
    apiKey: 'gateway_exists',
    message: 'Network Gateway already exists!',
  },
  {
    apiKey: 'user_exists',
    message: 'User already exists!',
  },
  {
    apiKey: 'threshold_exists',
    message: 'Threshold already exists!',
  },
  {
    apiKey: 'company_exists',
    message: 'Company already exists!',
  },
  {
    apiKey: 'pod_exists',
    message: 'Smart Pod already exists!',
  },
  {
    apiKey: 'flow_monitor_exists',
    message: 'Water Monitor already exists!',
  },
  {
    apiKey: 'building_exists',
    message: 'Building already exists!',
  },
  {
    apiKey: 'floor_exists',
    message: 'Floor already exists!',
  },
  {
    apiKey: 'location_exists',
    message: 'Location already exists!',
  },
  {
    apiKey: 'has_users',
    message: 'Cannot delete. Please remove all users and try again.',
  },
  {
    apiKey: 'has_sites',
    message: 'Cannot delete. Please remove all sites and try again.',
  },
  {
    apiKey: 'has_floors',
    message: 'Cannot delete. Please remove all floors and try again.',
  },
  {
    apiKey: 'has_gateways',
    message: 'Cannot delete. Please remove all gateways and try again.',
  },
  {
    apiKey: 'has_thresholds',
    message: 'Cannot delete. Please remove all thresholds and try again.',
  },
  {
    apiKey: 'has_locations',
    message: 'Cannot delete. Please remove all locations and try again.',
  },
  {
    apiKey: 'has_pod',
    message: 'Cannot delete. Please remove all Pods and try again.',
  },
  {
    apiKey: 'name_required',
    message: 'Name is required. Please add your name and try again.',
  },
  {
    apiKey: 'email_required',
    message: 'Email is required. Please add your email and try again.',
  },
  {
    apiKey: 'phone_required',
    message: 'Phone number is required. Please add your number and try again.',
  },
  {
    apiKey: 'site_required',
    message: 'Site is required. Please select a site and try again.',
  },
  {
    apiKey: 'threshold_required',
    message: 'Threshold is required. Please select a threshold and try again.',
  },
  {
    apiKey: 'invalid_user_role',
    message: 'Invalid user role. Please re-enter and try again.',
  },
  {
    apiKey: 'invalid_password',
    message: 'Invalid credentials. Please re-enter and try again.',
  },
  {
    apiKey: 'invalid_dates',
    message: 'Invalid date selection.',
  },
  {
    apiKey: 'invalid_name',
    message: 'Name is invalid. Please edit and try again.',
  },
  {
    apiKey: 'invalid_pillar_id',
    message: 'Pillar ID is not in correct format. Please edit and try again.',
  },
  {
    apiKey: 'invalid_extension',
    message:
      'Image format is invalid. Please select a different image and try again.',
  },
  {
    apiKey: 'invalid_alert_type',
    message: 'Alert type is invalid.',
  },
  {
    apiKey: 'invalid_document_type',
    message: 'Document type is invalid.',
  },
  {
    apiKey: 'invalid_building_name',
    message: 'Buidling name is invalid. Please edit and try again.',
  },
  {
    apiKey: 'invalid_input',
    message: 'Input is invalid. Please edit and try again.',
  },
  {
    apiKey: 'invalid_floor',
    message: 'Floor is invalid. Please edit and try again.',
  },
  {
    apiKey: 'invalid_floorplan_dimension',
    message:
      'A floor plan must be at least 1000px tall/wide and at most 5000px tall/wide. Please re-size to fit constraints.',
  },
  {
    apiKey: 'invalid_pod_status',
    message: 'Invalid Smart Pod status. Please and try again.',
  },
  {
    apiKey: 'invalid_calibration_values',
    message: 'Invalid Calibration values. Please edit and try again.',
  },
  {
    apiKey: 'insufficient_pods',
    message:
      'There is insufficient Smart Pod inventory to complete that request. Please edit the request and try again.',
    duration: 10,
  },
  {
    apiKey: 'insufficient_gateways',
    message:
      'There is insufficient gateway inventory to complete that request. Please edit the request and try again.',
    duration: 10,
  },
  {
    apiKey: 'insufficient_flow_monitors',
    message:
      'There is insufficient Flow monitor inventory to complete that request. Please edit the request and try again.',
    duration: 10,
  },
  {
    apiKey: 'assigned_threshold',
    message:
      'Threshold assigned to Location. Remove threshold from Location and try again.',
  },
  {
    apiKey: 'assigned_location',
    message:
      'Smart Pod assigned to Location. Remove Smart Pod from Location and try again.',
  },
  {
    apiKey: 'no_rules_threshold',
    message: 'At least one rule is required to create a threshold.',
  },
  {
    apiKey: 'invalid_rules_threshold',
    message: 'Invalid rule. Please edit your rules and try again.',
  },
  {
    apiKey: 'configuration_error',
    message: 'Server Error!',
  },
  {
    apiKey: 'unconfirmed_user',
    message:
      'Your account is not confirmed. Please check your email and confirm your account using the link provided.',
  },
  {
    apiKey: 'confirmed_user',
    message:
      'Your account is already confirmed. Please log in using your email and password.',
  },
  {
    apiKey: 'token_expired',
    message: 'The confirmation token has expired. Please request a new token.',
  },
  {
    apiKey: 'token_invalid',
    message: 'The confirmation token is invalid. Please request a new token.',
  },
  {
    apiKey: 'active_ota',
    message:
      'Only one OTA update allowed at a time. Please wait for current task to complete and try your request again.',
  },
  {
    apiKey: 'unauthorized',
    message: 'Unauthorized. Please log in and try again.',
  },
  {
    apiKey: 'invalid_policy_name',
    message: 'Invalid policy name. Please try again.',
  },
  {
    apiKey: 'company_required',
    message:
      'Company selection is required for Escalation Policies. Please select a company and try again.',
  },
  {
    apiKey: 'escalation_policy_duplicate_step',
    message:
      'Escalation Policy step already exists. Please review your input and try again.',
  },
  {
    apiKey: 'escalation_policy_missing_step',
    message:
      'Escalation Policy step is missing. Please review your input and try again.',
  },
  {
    apiKey: 'invalid_escalation_policy_group',
    message: 'Invalid Escalation Policy group. Please try again.',
  },
  {
    apiKey: 'asset_location_exists',
    message:
      'Asset location already exists. Please review your input and try again.',
  },
  {
    apiKey: 'asset_location_not_found',
    message: 'Asset location does not exist. Please try a different location.',
  },
  {
    apiKey: 'comment_not_found',
    message: 'Comment does not exist.',
  },
  {
    apiKey: 'comment_required',
    message: 'Comment is required.',
  },
  {
    apiKey: 'invalid_user',
    message: 'You may only edit/delete comments you have authored.',
  },
  {
    apiKey: 'resource_type_invalid',
    message: 'A resource type is required for comments and tags.',
  },
  {
    apiKey: 'gateway_alert_not_found',
    message: 'Network Gateway alert does not exist.',
  },
  {
    apiKey: 'gateway_restart_failed',
    message: 'Network Gateway failed to restart.',
  },
  {
    apiKey: 'sim_card_not_found',
    message: 'SIM Card does not exist.',
  },
  {
    apiKey: 'sim_card_exists',
    message: 'SIM Number already exists.',
  },
  {
    apiKey: 'part_not_found',
    message: 'BOM Part does not exist.',
  },
  {
    apiKey: 'part_exists',
    message: 'BOM Part already exists.',
  },
  {
    apiKey: 'purchase_not_found',
    message: 'BOM Purchase does not exist.',
  },
  {
    apiKey: 'purchase_exists',
    message: 'BOM Purchase already exists.',
  },
  {
    apiKey: 'flow_monitor_not_found',
    message: 'Water Monitor does not exist.',
  },
  {
    apiKey: 'gateway_supply_not_found',
    message: 'Network Gateway Supply does not exist.',
  },
  {
    apiKey: 'gateway_supply_exists',
    message: 'Network Gateway Supply already exists.',
  },
  {
    apiKey: 'pods_required',
    message: 'You must select one or more Pods to update.',
  },
  {
    apiKey: 'invalid_status_update',
    message: 'Cannot assign to new status. Please try a different status.',
  },
  {
    apiKey: 'pod_statuses_different',
    message:
      'All Pods must be of same status in order to bulk update. Please check your selections and try again.',
  },
  {
    apiKey: 'invalid_sensor_type',
    message: 'Sensor type invalid.',
  },
  {
    apiKey: 'threshold_invalid_keywords',
    message:
      'Message contains invalid keywords. Please edit your message and try again.',
  },
  {
    apiKey: 'tag_assigned',
    message: 'Tag is in use. Please remove tag from all items and try again.',
  },
  {
    apiKey: 'tag_not_found',
    message: 'Tag does not exist!',
  },
  {
    apiKey: 'tag_exists',
    message: 'Tag exists already!',
  },
  {
    apiKey: 'file_too_large',
    message:
      'The selected file is over the 16MB size limit. Please use a smaller file and try again.',
  },
  {
    apiKey: 'authy_issue',
    message:
      'Pillar is unable to verify your token. Please contact your site administrator.',
  },
  {
    apiKey: 'company_users_without_phone',
    message:
      "To activate two-factor authentication, all company users must have a phone number associated with their account. Please review the company's user accounts and try again.",
  },
  {
    apiKey: 'action_report_not_found',
    message: 'Action Report not found!',
  },
  {
    apiKey: 'phone_number_not_found',
    message: 'Phone number not found. Please try again.',
  },
  {
    apiKey: 'has_phone_number',
    message: 'Phone number already provided. Please try again.',
  },
  {
    apiKey: 'phone_number_exists',
    message: 'Phone number exists. Please use a different phone number.',
  },
  {
    apiKey: 'support_phone_number_not_found',
    message:
      'You do not have a phone number assigned to your account. Please contact an administrator to add your phone number and try again.',
  },
  {
    apiKey: 'report_token_expired',
    message: 'This report has expired. Please create a new report.',
  },
  {
    apiKey: 'network_exists',
    message: 'Network exists. Please use a different name.',
  },
  {
    apiKey: 'network_not_found',
    message: 'Network not found!',
  },
  {
    apiKey: 'network_authority_not_found',
    message: 'Authority not found!',
  },
  {
    apiKey: 'has_assets',
    message:
      'This resource has assets associated with it. Please remove them and try again.',
  },
  {
    apiKey: 'default_threshold_group_exists',
    message:
      'This type has already been assigned as a default of another Threshold Group. Please remove it and try again.',
  },
  {
    apiKey: 'insufficient_leak_pods',
    message:
      'There is insufficient Leak Puck inventory to complete that request. Please edit the request and try again.',
  },
  {
    apiKey: 'resource_not_found',
    message: 'Resource not found!',
  },
  {
    apiKey: 'incorrect_threshold_unit',
    message:
      'Thresholds with a different unit are assigned to this Site. Please remove them before adjusting this setting.',
  },
]

const THRESHOLD_TYPES_BETA = {
  LEVEL: 'level',
  LEVEL_OVER_DURATION: 'level_over_duration',
  RATE_OF_CHANGE: 'rate_of_change',
  EQUAL: 'equal',
  EQUAL_FOR_RANGE: 'equal_for_range',
  OFFLINE: 'offline',
  STANDARD_DEVIATION: 'stddev',
}

const THRESHOLD_TYPES = {
  LEVEL: 'level',
  LEVEL_OVER_DURATION: 'level_over_duration',
  RATE_OF_CHANGE: 'rate_of_change',
  EQUAL: 'equal',
  EQUAL_FOR_RANGE: 'equal_for_range',
  OFFLINE: 'offline',
}

const THRESHOLD_DATA_SOURCES = {
  TEMPERATURE: 'temp',
  PRESSURE: 'pres',
  HUMIDITY: 'hum',
  DUST: 'dust',
  SOUND: 'sound',
  VOC: 'voc',
  PRESSURE_DIFFERENTIAL: 'diffPressureAlarmStatus',
  CARBON_MONOXIDE: 'carbon_monoxide',
  MIC_COUNT: 'mic_count',
  DEW_POINT: 'dew_point',
  WATER_FLOW: 'flow_value',
  LEAK_EVENT: 'leak_event',
  LEAK_STATE: 'leak_value',
}

const THRESHOLD_LEVELS = {
  HIGH: 'HIGH',
  WARNING: 'WARNING',
  LOW: 'LOW',
}

const THRESHOLD_ALERT_TYPES = {
  ACTIONABLE: 'ACTIONABLE',
  INFORMATIONAL: 'INFORMATIONAL',
}

const ALERT_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
}

const ALERT_TYPES = {
  THRESHOLD: 'THRESHOLD',
  SYSTEM: 'SYSTEM',
}

const SITE_STATUS = {
  PROPOSED: 'PROPOSED',
  PLANNING: 'PLANNING',
  INSTALLING: 'INSTALLING',
  ACTIVE: 'ACTIVE',
  UNINSTALL: 'UNINSTALL',
  COMPLETED: 'COMPLETED',
}

const GATEWAY_STATUS = {
  DAMAGED: 'DAMAGED',
  DEPLOYED: 'DEPLOYED',
  INVENTORY: 'INVENTORY',
  STAGING: 'STAGING',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  RESERVED: 'RESERVED',
  REVIEW: 'REVIEW',
  TEST: 'TEST',
  LOST: 'LOST',
  SPARE: 'SPARE',
}

const CELLULAR_ANTENNA_TYPE = {
  DAS_3DBI: '3DBI_DAS',
  MAGNET_BASE_3DBI_DAS: 'MAGNET_BASE_3DBI_DAS',
}

const LORA_ANTENNA_TYPE = {
  SMALL_3DBI_OUTDOOR_LORA: 'SMALL_3DBI_OUTDOOR_LORA',
  MEDIUM_6DBI_OUTDOOR_LORA: 'MEDIUM_6DBI_OUTDOOR_LORA',
  LARGE_8DBI_OUTDOOR_LORA: 'LARGE_8DBI_OUTDOOR_LORA',
  MEDIUM_3DBI_INDOOR_LORA: 'MEDIUM_3DBI_INDOOR_LORA',
  LARGE_5DBI_INDOOR_LORA: 'LARGE_5DBI_INDOOR_LORA',
}

const LORA_CABLE_SIZE = {
  FOUR_FT: '4_FT',
  TEN_FT: '10_FT',
  TWENTY_FT: '20_FT',
}

const SOLAR_PANEL = {
  FIFTY_W_12_V: '50_W_12_V',
  HUNDRED_W_12_V: '100_W_12_V',
}

const UPS_BATTERY = {
  THIRTY_SIX_AH_LEAD_ACID: '36_AH_LEAD_ACID',
  FIFTY_TWO_AH_LEAD_ACID: '52_AH_LEAD_ACID',
}

const GATEWAY_TYPES = {
  INDOOR: 'INDOOR',
  OUTDOOR: 'OUTDOOR',
  MODULAR: 'MODULAR',
}

const SIM_CARRIERS = {
  ATT: 'ATT',
  VERIZON: 'VERIZON',
  T_MOBILE: 'T_MOBILE',
  TELIT: 'TELIT',
  TWILIO: 'TWILIO',
}

const SIM_USAGE = {
  GATEWAY_SIM: 'GATEWAY_SIM',
  COMMUNICATION_DEVICE: 'COMMUNICATION_DEVICE',
  WATER_FLOW_MONITOR: 'WATER_FLOW_MONITOR',
}

const SIM_CARD_STATUS = {
  ACTIVE: 'ACTIVE',
  SUSPENDED: 'SUSPENDED',
  LOST: 'LOST',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
}

const GATEWAY_NETWORK = {
  DEV: 'DEV',
  PROD: 'PROD',
}

const GATEWAY_STACK_TYPES = {
  LINKLABS: 'LINKLABS',
  LORA: 'LORA',
  RECON: 'RECON',
}

const POD_STATUS = {
  DAMAGED: 'DAMAGED',
  DEPLOYED: 'DEPLOYED',
  INVENTORY: 'INVENTORY',
  LOST: 'LOST',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  NEW: 'NEW',
  QUALITY_CONTROL_REFURB: 'QUALITY_CONTROL_REFURB',
  RECEIVED: 'RECEIVED',
  RESERVED: 'RESERVED',
  REVIEW: 'REVIEW',
  SHOW: 'SHOW',
  SPARE: 'SPARE',
  STAGING: 'STAGING',
  TEST: 'TEST',
  TRANSIT_INBOUND: 'TRANSIT_INBOUND',
  TRANSIT_OUTBOUND: 'TRANSIT_OUTBOUND',
  CALIBRATION: 'CALIBRATION',
  MANUFACTURING: 'MANUFACTURING',
}

const BATTERY_STATE = {
  NEW: 'NEW',
  THREE_MONTHS_LEFT: 'THREE_MONTHS_LEFT',
  SIX_MONTHS_LEFT: 'SIX_MONTHS_LEFT',
  DEAD_BATTERIES: 'DEAD_BATTERIES',
}

const POD_TYPES = {
  SMART: 'SMART',
  // PDS: 'PDS',
  LEAK_POD: 'LEAK_POD',
}

const TIME_FORMAT = 'HH:mm'

const COLORS = {
  WHITE: '#ffffff',
  BLUE: '#357edd',
  DARK_BLUE: '#00449E',
  NAVY: '#001B44',
  GREEN: '#19a974',
  DARK_GREEN: '#137752',
  RED: '#FF4136',
  DARK_RED: '#E7040F',
  LIGHT_RED: '#FF725C',
  LIGHT_GREEN: '#9EEBCF',
  LIGHT_BLUE: '#96CCFF',
  ORANGE: '#F48120',
  PURPLE: '#A463F2',
  YELLOW: '#FFD700',
  GOLD: '#FFB700',
  BLACK: '#000000',
  BLACK_80: 'rgba(0, 0, 0, 0.8)',
  GRAY: '#777777',
  MOON_GRAY: '#CCCCCC',
  LIGHT_GRAY: '#EEEEEE',
  LIGHT_SILVER: '#AAAAAA',
  TRANSPARENT: 'transparent',
  DARK_PINK: '#D5008F',
  HOT_PINK: '#FF41B4',
  PINK: '#FF80CC',
  LIGHT_PINK: '#FFA3D7',
  LIGHT_PURPLE: '#A463F2',
  NEON_LIME: '#e0e622',
}

const SIZES = {
  XS: 'xs',
  SMALL: 'sm',
  MEDIUM: 'md',
  LARGE: 'lg',
  XL: 'xl',
}

const UNITS = {
  TEMPERATURE: ' ºF',
  HUMIDITY: '%',
  DUST: ' µg/m3',
  PRESSURE: ' inHG',
  SOUND: ' dB',
  VOC: ' ppm',
  SIGNAL_RSSI: ' dBm',
  SIGNAL_SNR: '',
  SIGNAL_SF: '',
  PDS: ' wc',
  CARBON_MONOXIDE: ' ppm',
  LIGHT: ' µW/cm2',
  AMBIENT_LIGHT_FC: ' foot-candles',
  BATTERY: ' mV',
  ACCELERATION: ' g',
  GYROSCOPE: ' deg/s',
  CABLE_OCCUPANCY_ToF: ' %',
  DISTANCE: ' cm',
  ROTATION_ANGLE: ' deg',
  FLOW_VALUE: ' gal/min',
  TOTAL_FLOW: ' gal',
  VALVE_ANALOG: ' %',
  VALVE_STATUS: '',
  SWITCH_STATUS: '',
  VALVE_STATUS_INT: '',
  SWITCH_STATUS_INT: '',
}

const SMART_SENSOR_TYPES = {
  TEMPERATURE: 'temp',
  TEMPERATURE_CELCIUS: 'tempC',
  HUMIDITY: 'hum',
  DUST: 'dust',
  PRESSURE: 'pres',
  PRESSURE_KPA: 'presKPa',
  CARBON_MONOXIDE: 'carbonMonoxide',
  CARBON__MONOXIDE: 'co',
  MIC_COUNT: 'micCount',
  AMBIENT_LIGHT: 'ambientLight',
  AMBIENT_LIGHT_FC: 'ambientLightFc',
  SOUND: 'sound',
  VOC: 'voc',
  SIGNAL: 'signal',
  SIGNAL_RSSI: 'signalRssi',
  AIR_QUALITY_INDEX: 'aqi',
}

const DEFECTIVE_SENSOR_TYPES = {
  TEMPERATURE: 'temp',
  HUMIDITY: 'hum',
  DUST: 'dust',
  PRESSURE: 'pres',
  CARBON_MONOXIDE: 'carbon_monoxide',
  AMBIENT_LIGHT: 'ambient_light',
  VOC: 'voc',
  TEMPERATURE_LMT_87: 'temp_lmt87',
}

const PDS_SENSOR_TYPES = {
  DP_LOW: 'diffPressureLow',
  DP_HIGH: 'diffPressureHigh',
  DP_AVG: 'diffPressureAvg',
  DP_ALARM: 'diffPressureAlarm1Setting',
}

const SENSOR_READING_TYPES = {
  TEMPERATURE: 'temp',
  TEMPERATURE_CELCIUS: 'tempC',
  HUMIDITY: 'hum',
  HUMIDITY_UNCALIBRATED: 'humUncalibrated',
  HUMIDITY_CALIBRATED_1PT: 'humCalibrated1pt',
  HUMIDITY_CALIBRATED_2PT: 'humCalibrated2pt',
  DUST: 'dust',
  PRESSURE: 'pres',
  PRESSURE_KPA: 'presKPa',
  SOUND: 'sound',
  VOC: 'voc',
  CARBON_MONOXIDE: 'carbonMonoxide',
  MIC_COUNT: 'micCount',
  LIGHT: 'ambientLight',
  AMBIENT_LIGHT_FC: 'ambientLightFc',
  SIGNAL: 'signal',
  SIGNAL_RSSI: 'signalRssi',
  DP_LOW: 'diffPressureLow',
  DP_HIGH: 'diffPressureHigh',
  DP_AVG: 'diffPressureAvg',
  DP_ALARM: 'diffPressureAlarm1Setting',
  TEMPERATURE_LMT_87: 'tempLmt87',
  GATEWAY_MODULE_ID: 'gatewayModuleId',
  SIGNAL_SF: 'signalSf',
  SIGNAL_SNR: 'signalSnr',
  BATTERY_NORMAL: 'batteryNormal',
  BATTERY_LOADED: 'batteryLoaded',
  BATTERY_DROP: 'batteryDrop',
  DEW_POINT: 'dewPoint',
  MOTION: 'motion',
  LEAK_EVENT: 'leakEvent',
  LEAK_VALUE: 'leakValue',
  STATUS_FLAGS: 'statusFlags',
  ANOMALY_READING_TYPE: 'anomalyReadingType',
  ANOMALY: 'anomaly',
}

const IMU_SENSOR_READING_TYPES = {
  ROTATION_ANGLE: 'accelX',
  DISTANCE_1: 'accelY',
  DISTANCE_2: 'accelZ',
  DISTANCE_3: 'gyroX',
  DISTANCE_4: 'gyroY',
  CABLE_OCCUPANCY_ToF: 'gyroZ',
  CABLE_OCCUPANCY_ACCEL: 'rotationAngle',
}

const FLOW_SENSOR_READING_TYPES = {
  FLOW_VALUE: 'flowValue',
  AVERAGE_FLOW: 'avgFlow',
  TOTAL_FLOW: 'totalFlow',
  PIPE_TEMPERATURE: 'temp',
  // HUMIDITY: 'hum',
  VALVE_ANALOG: 'valveAnalog',
  VALVE_STATUS: 'valveStatus',
  SWITCH_STATUS: 'relaySwitchStatus',
  VALVE_STATUS_INT: 'valveStatusInt',
  SWITCH_STATUS_INT: 'relaySwitchStatusInt',
  AVERAGE_WEEKLY_FLOW: 'avgWeeklyFlow',
}

const WATER_METER_TYPES = {
  FD_R50: 'FD-R50',
  FD_R80: 'FD-R80',
  FD_R125: 'FD-R125',
  FD_R200: 'FD-R200',
  FD_Q10C: 'FD-Q10C',
  FD_Q20C: 'FD-Q20C',
  FD_Q32C: 'FD-Q32C',
  FD_Q50C: 'FD-Q50C',
}

const FLOW_MONITOR_STATUS = {
  DEPLOYED: 'DEPLOYED',
  RESERVED: 'RESERVED',
  TEST: 'TEST',
  SHOW: 'SHOW',
  INVENTORY: 'INVENTORY',
  DAMAGED: 'DAMAGED',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
  REVIEW: 'REVIEW',
  LOST: 'LOST',
  NEW: 'NEW',
  QUALITY_CONTROL_REFURB: 'QUALITY_CONTROL_REFURB',
  SPARE: 'SPARE',
  TRANSIT_INBOUND: 'TRANSIT_INBOUND',
  TRANSIT_OUTBOUND: 'TRANSIT_OUTBOUND',
  RECEIVED: 'RECEIVED',
  STAGING: 'STAGING',
  CALIBRATION: 'CALIBRATION',
}

const WATER_VALVE_STATUS = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  MAINTENANCE: 'MAINTENANCE',
  MISSING: 'MISSING',
}

const WATER_VALVE_TYPES = {
  BELIMO_24V: 'BELIMO_24V',
  OTHER: 'OTHER',
}

const Z_INDEX = {
  HUGE: 99999999,
  MAX: 2147483647,
}

const KEYCODES = {
  ARROW_RIGHT: 39,
  ARROW_LEFT: 37,
}

const TIME = 'time'

const FONT_FAMILY =
  "-apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif"

const REPORT_SENSOR_READING_TYPES = [
  {
    name: 'Temperature',
    value: SENSOR_READING_TYPES.TEMPERATURE,
  },
  {
    name: 'Humidity',
    value: SENSOR_READING_TYPES.HUMIDITY,
  },
  {
    name: 'Dust',
    value: SENSOR_READING_TYPES.DUST,
  },
  {
    name: 'Air Quality Index',
    value: 'aqi',
  },
  {
    name: 'Carbon Monoxide',
    value: 'carbon_monoxide',
  },
  {
    name: 'Noise Count (> 85 dBA)',
    value: 'micCount',
  },
]

const ALERT_STATES = {
  ACKNOWLEDGED: 'ACK',
  RESOLVED: 'RESOLVED',
}

const COMMUNICATION_MODES = [
  {
    value: 'SMS',
    label: 'SMS',
  },
  {
    value: 'EMAIL',
    label: 'Email',
  },
  // {
  //   value: 'voice',
  //   label: 'Phone Call'
  // }
]

const VISIBLE_READINGS = [
  'TEMPERATURE',
  'HUMIDITY',
  'DUST',
  'PRESSURE',
  'SIGNAL_RSSI',
]

const READING_ABBREVIATIONS = {
  TEMPERATURE: 'Temp.',
  HUMIDITY: 'Hum.',
  DUST: 'Dust.',
  PRESSURE: 'Pres.',
  SIGNAL_RSSI: 'Sig. RSSI',
}

const DEFAULT_PAGE = 1
const DEFAULT_PER_PAGE = 25

const ZOOM_LEVELS = {
  MIN: -1.5,
  MAX: 4,
}

const CONNECTIVITY_STATUS = {
  ONLINE: 't',
  OFFLINE: 'f',
}

const BATTERY_INDICATOR = {
  NEW: 't',
  OLD: 'f',
}

const ALL = 'all'
const VIEW_ALL = '-- View All --'
const OTHER = '-- Other --'
const UNASSIGNED = 'Unassigned'

const SUMMARY_INTERVAL_OPTIONS = [
  {
    label: '1 Day',
    value: 1,
  },
  {
    label: '1 Week',
    value: 7,
  },
  {
    label: '2 Weeks',
    value: 14,
  },
  {
    label: '4 Weeks',
    value: 28,
  },
]

const SUMMARY_REPEAT_FREQUENCY_OPTIONS = [
  {
    label: 'Forever',
    value: -1,
  },
  {
    label: '1',
    value: 1,
  },
  {
    label: '2',
    value: 2,
  },
  {
    label: '3',
    value: 3,
  },
  {
    label: '4',
    value: 4,
  },
  {
    label: '5',
    value: 5,
  },
]

const DEFAULT_ANIMATION_DURATION = 50
const DEFAULT_TOOLTIP_ANIMATION_DURATION = 250

const ACTION_REPORT_STATE = {
  DISABLED: 'DISABLED',
  INCOMPLETE: 'INCOMPLETE',
  PARTIALLY_COMPLETE: 'PARTIALLY_COMPLETE',
  COMPLETE: 'COMPLETE',
}

const BUILDING_VIEW_TYPES = {
  LOCATIONS: 'locations',
  SUMMARIES: 'summaries',
}
const COMPANY_TYPES = {
  OWNER: 'OWNER',
  CONTRACTOR: 'CONTRACTOR',
  INSURER: 'INSURER',
  SUB_CONTRACTOR: 'SUB_CONTRACTOR',
}

const RI_METRICS_TYPES = {
  SUCCESS_RATE: 'reliabilityMetrics.successRate',
  TOTAL_READINGS: 'reliabilityMetrics.totalSignals',
  DELAYED_READINGS: 'reliabilityMetrics.unmetSignals',
  ON_TIME_RATE: 'reliabilityMetrics.%SignalsUnmet',
  DELAY: 'reliabilityMetrics.timeDelayed(s)',
  UPTIME: 'reliabilityMetrics.%TimeDelayed',
  MAX_DELAY: 'reliabilityMetrics.maxDelay',
  MEAN_DELAY: 'reliabilityMetrics.meanDelay',
  MEDIAN_DELAY: 'reliabilityMetrics.medianDelay',
  STANDARD_DEVIATION_DELAY: 'reliabilityMetrics.stdDelay',
}

const POD_ASSET_TYPES = {
  IMAGE: 'IMAGE',
  AUDIO: 'AUDIO',
}

const WORKFLOW_TYPES = {
  POD__REFURB: 'POD__REFURB',
  POD__VALIDATE_CALIBRATION: 'POD__VALIDATE_CALIBRATION',
  POD__INVENTORY_PREPARATION: 'POD__INVENTORY_PREPARATION',
}

const SMOOTH_INTERVAL = {
  RAW: 'raw',
  TWO_MINUTES: '2m',
  FIVE_MINUTES: '5m',
  TEN_MINUTES: '10m',
  FIFTEEN_MINUTES: '15m',
  ONE_HOUR: '1h',
  SIX_HOURS: '6h',
  TWELVE_HOURS: '12h',
}

const USER_PERMISSIONS = {
  // ADMIN
  ADD_COMPANY: 'ADD_COMPANY',
  VIEW_COMPANY: 'VIEW_COMPANY',
  ADD_SITE: 'ADD_SITE',
  DELETE_SITE: 'DELETE_SITE',
  EDIT_COMPANY: 'EDIT_COMPANY',
  DELETE_COMPANY: 'DELETE_COMPANY',
  VIEW_USER_PERMISSIONS: 'VIEW_USER_PERMISSIONS',
  EDIT_USER_PERMISSIONS: 'EDIT_USER_PERMISSIONS',
  VIEW_WORKFLOW: 'VIEW_WORKFLOWS',
  EDIT_WORKFLOW: 'EDIT_WORKFLOWS',
  VIEW_TAG: 'VIEW_TAG',
  ADD_TAG: 'ADD_TAG',
  EDIT_TAG: 'EDIT_TAG',
  DELETE_TAG: 'DELETE_TAG',
  MANAGE_NETWORK: 'MANAGE_NETWORK',
  BUILDING_EVACUATION: "BUILDING_EVACUATION",

  // TODO or maybe Action Report causes are deprecated?
  VIEW_ACTION_REPORT_CAUSES: 'VIEW_ACTION_REPORT_CAUSES',
  ADD_ACTION_REPORT_CAUSES: 'ADD_ACTION_REPORT_CAUSES',
  EDIT_ACTION_REPORT_CAUSES: 'EDIT_ACTION_REPORT_CAUSES',
  DELETE_ACTION_REPORT_CAUSES: 'DELETE_ACTION_REPORT_CAUSES',

  // ALERT
  VIEW_ALERTS: 'VIEW_ALERTS',
  EDIT_ALERT: 'EDIT_ALERT',
  VIEW_GATEWAY_ALERTS: 'VIEW_GATEWAY_ALERTS',
  EDIT_GATEWAY_ALERT: 'EDIT_GATEWAY_ALERT',
  VIEW_ALERT_AUDIT_LOG: 'VIEW_ALERT_AUDIT_LOG',

  // DEPLOYMENT
  ADD_INSTALL_DEPLOYMENT: 'ADD_INSTALL_DEPLOYMENT',
  VIEW_INSTALL_DEPLOYMENT: 'VIEW_INSTALL_DEPLOYMENT',
  DELETE_INSTALL_DEPLOYMENT: 'DELETE_INSTALL_DEPLOYMENT',
  EDIT_INSTALL_DEPLOYMENT: 'EDIT_INSTALL_DEPLOYMENT',
  ADD_UNINSTALL_DEPLOYMENT: 'ADD_UNINSTALL_DEPLOYMENT',
  VIEW_UNINSTALL_DEPLOYMENT: 'VIEW_UNINSTALL_DEPLOYMENT',
  DELETE_UNINSTALL_DEPLOYMENT: 'DELETE_UNINSTALL_DEPLOYMENT',
  EDIT_UNINSTALL_DEPLOYMENT: 'EDIT_UNINSTALL_DEPLOYMENT',
  VIEW_ALL_DEPLOYMENTS: 'VIEW_ALL_DEPLOYMENTS',
  VIEW_SITE_DEPLOYMENTS: 'VIEW_SITE_DEPLOYMENTS',

  // INVENTORY
  ADD_POD: 'ADD_POD',
  VIEW_POD: 'VIEW_POD',
  EDIT_POD: 'EDIT_POD',
  DELETE_POD: 'DELETE_POD',
  BULK_UPLOAD_PODS: 'BULK_UPLOAD_PODS',
  GENERATE_PODS_PILLAR_IDS: 'GENERATE_PODS_PILLAR_IDS',
  ADD_GATEWAY: 'ADD_GATEWAY',
  VIEW_GATEWAY: 'VIEW_GATEWAY',
  EDIT_GATEWAY: 'EDIT_GATEWAY',
  DELETE_GATEWAY: 'DELETE_GATEWAY',
  ADD_ASSET_LOCATION: 'ADD_ASSET_LOCATION',
  VIEW_ASSET_LOCATION: 'VIEW_ASSET_LOCATION',
  EDIT_ASSET_LOCATION: 'EDIT_ASSET_LOCATION',
  DELETE_ASSET_LOCATION: 'DELETE_ASSET_LOCATION',
  ADD_SIM_CARD: 'ADD_SIM_CARD',
  VIEW_SIM_CARD: 'VIEW_SIM_CARD',
  EDIT_SIM_CARD: 'EDIT_SIM_CARD',
  DELETE_SIM_CARD: 'DELETE_SIM_CARD',
  ADD_PART: 'ADD_PART',
  VIEW_PART: 'VIEW_PART',
  EDIT_PART: 'EDIT_PART',
  DELETE_PART: 'DELETE_PART',
  ADD_PURCHASE: 'ADD_PURCHASE',
  VIEW_PURCHASE: 'VIEW_PURCHASE',
  EDIT_PURCHASE: 'EDIT_PURCHASE',
  DELETE_PURCHASE: 'DELETE_PURCHASE',
  ADD_FLOW_MONITOR: 'ADD_FLOW_MONITOR',
  VIEW_FLOW_MONITOR: 'VIEW_FLOW_MONITOR',
  EDIT_FLOW_MONITOR: 'EDIT_FLOW_MONITOR',
  DELETE_FLOW_MONITOR: 'DELETE_FLOW_MONITOR',
  ADD_GATEWAY_SUPPLY: 'ADD_GATEWAY_SUPPLY',
  VIEW_GATEWAY_SUPPLY: 'VIEW_GATEWAY_SUPPLY',
  EDIT_GATEWAY_SUPPLY: 'EDIT_GATEWAY_SUPPLY',
  DELETE_GATEWAY_SUPPLY: 'DELETE_GATEWAY_SUPPLY',
  VIEW_OTA_LOG: 'VIEW_OTA_LOG',
  OTA_POD: 'OTA_POD',
  VIEW_INVENTORY_PROJECTIONS: 'VIEW_INVENTORY_PROJECTIONS',

  // REPORT
  VIEW_REPORT: 'VIEW_REPORT',
  REQUEST_REPORT: 'REQUEST_REPORT',
  SCHEDULE_REPORT: 'SCHEDULE_REPORT',

  // SITE
  VIEW_SITE: 'VIEW_SITE',
  EDIT_SITE: 'EDIT_SITE',
  ADD_BUILDING: 'ADD_BUILDING',
  EDIT_BUILDING: 'EDIT_BUILDING',
  VIEW_BUILDING: 'VIEW_BUILDING',
  DELETE_BUILDING: 'DELETE_BUILDING',
  ADD_FLOOR: 'ADD_FLOOR',
  EDIT_FLOOR: 'EDIT_FLOOR',
  VIEW_FLOOR: 'VIEW_FLOOR',
  DELETE_FLOOR: 'DELETE_FLOOR',
  ADD_LOCATION: 'ADD_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
  VIEW_LOCATION: 'VIEW_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  VIEW_SITE_INVENTORY: 'VIEW_SITE_INVENTORY',
  ADD_SITE_THRESHOLD: 'ADD_SITE_THRESHOLD',
  VIEW_SITE_THRESHOLD: 'VIEW_SITE_THRESHOLD',
  DELETE_SITE_THRESHOLD: 'DELETE_SITE_THRESHOLD',
  EDIT_SITE_THRESHOLD: 'EDIT_SITE_THRESHOLD',
  ADD_SITE_USER: 'ADD_SITE_USER',
  VIEW_SITE_USER: 'VIEW_SITE_USER',
  EDIT_SITE_USER: 'EDIT_SITE_USER',
  DELETE_SITE_USER: 'DELETE_SITE_USER',

  // THRESHOLD
  ADD_THRESHOLD: 'ADD_THRESHOLD',
  EDIT_THRESHOLD: 'EDIT_THRESHOLD',
  VIEW_THRESHOLD: 'VIEW_THRESHOLD',
  DELETE_THRESHOLD: 'DELETE_THRESHOLD',
  ADD_ESCALATION_POLICY: 'ADD_ESCALATION_POLICY',
  EDIT_ESCALATION_POLICY: 'EDIT_ESCALATION_POLICY',
  VIEW_ESCALATION_POLICY: 'VIEW_ESCALATION_POLICY',
  DELETE_ESCALATION_POLICY: 'DELETE_ESCALATION_POLICY',
  ADD_THRESHOLD_GROUP: 'ADD_THRESHOLD_GROUP',
  EDIT_THRESHOLD_GROUP: 'EDIT_THRESHOLD_GROUP',
  VIEW_THRESHOLD_GROUP: 'VIEW_THRESHOLD_GROUP',
  DELETE_THRESHOLD_GROUP: 'DELETE_THRESHOLD_GROUP',

  // COMMENT
  ADD_COMMENT: 'ADD_COMMENT',
  VIEW_COMMENT: 'VIEW_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',

  // ASSETS
  ADD_POD_ASSET: 'ADD_POD_ASSET',
  VIEW_POD_ASSET: 'VIEW_POD_ASSET',
  DELETE_POD_ASSET: 'DELETE_POD_ASSET',

  // USER
  ADD_USER: 'ADD_USER',
  EDIT_USER: 'EDIT_USER',
  VIEW_USER: 'VIEW_USER',
  DELETE_USER: 'DELETE_USER',
  ENABLE_API: 'ENABLE_API',
  ENABLE_EROS: 'ENABLE_EROS',
  ENABLE_COMMS_HUB: 'ENABLE_COMMS_HUB',
  VIEW_LEAD: 'VIEW_LEAD',
}

// TODO use these?
// const USER_PERMISSION_GROUPS = {
//   ADMIN: 'ADMIN',
//   ALERT: 'ALERT',
//   DEPLOYMENT: 'DEPLOYMENT',
//   INVENTORY: 'INVENTORY',
//   REPORT: 'REPORT',
//   SITE: 'SITE',
//   THRESHOLD: 'THRESHOLD',
//   USER: 'USER'
// }

// const USER_PERMISSION_GROUP_MAPPING = {
//   [USER_PERMISSION_GROUPS.ADMIN]: [
//     USER_PERMISSIONS.ADD_COMPANY,
//     USER_PERMISSIONS.EDIT_COMPANY,
//     USER_PERMISSIONS.DELETE_COMPANY,
//     USER_PERMISSIONS.VIEW_COMPANY,
//     USER_PERMISSIONS.CREATE_SITE,
//     USER_PERMISSIONS.EDIT_SITE,
//     USER_PERMISSIONS.DELETE_SITE
//     USER_PERMISSIONS.VIEW_WORKFLOW,
//     USER_PERMISSIONS.EDIT_WORKFLOW
//   ],
//   [USER_PERMISSION_GROUPS.ALERT]: [
//     USER_PERMISSIONS.VIEW_ALERTS,
//     USER_PERMISSIONS.EDIT_ALERT
//   ],
//   [USER_PERMISSION_GROUPS.DEPLOYMENT]: [
//     USER_PERMISSIONS.ADD_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.ADD_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_SITE_DEPLOYMENTS
//   ],
//   [USER_PERMISSION_GROUPS.INVENTORY]: [
//     USER_PERMISSIONS.ADD_POD,
//     USER_PERMISSIONS.VIEW_POD,
//     USER_PERMISSIONS.EDIT_POD,
//     USER_PERMISSIONS.DELETE_POD,
//     USER_PERMISSIONS.BULK_UPLOAD_PODS,
//     USER_PERMISSIONS.GENERATE_PODS_PILLAR_IDS,
//     USER_PERMISSIONS.ADD_GATEWAY,
//     USER_PERMISSIONS.VIEW_GATEWAY,
//     USER_PERMISSIONS.EDIT_GATEWAY,
//     USER_PERMISSIONS.DELETE_GATEWAY,
//     USER_PERMISSIONS.ADD_ASSET_LOCATION,
//     USER_PERMISSIONS.VIEW_ASSET_LOCATION,
//     USER_PERMISSIONS.EDIT_ASSET_LOCATION,
//     USER_PERMISSIONS.DELETE_ASSET_LOCATION,
//     USER_PERMISSIONS.ADD_SIM_CARD,
//     USER_PERMISSIONS.VIEW_SIM_CARD,
//     USER_PERMISSIONS.EDIT_SIM_CARD,
//     USER_PERMISSIONS.DELETE_SIM_CARD,
//     USER_PERMISSIONS.ADD_PART,
//     USER_PERMISSIONS.VIEW_PART,
//     USER_PERMISSIONS.EDIT_PART,
//     USER_PERMISSIONS.DELETE_PART,
//     USER_PERMISSIONS.ADD_PURCHASE,
//     USER_PERMISSIONS.VIEW_PURCHASE,
//     USER_PERMISSIONS.EDIT_PURCHASE,
//     USER_PERMISSIONS.DELETE_PURCHASE,
//     USER_PERMISSIONS.VIEW_FLOW_MONITOR,
//     USER_PERMISSIONS.EDIT_FLOW_MONITOR,
//     USER_PERMISSIONS.DELETE_FLOW_MONITOR,
//     USER_PERMISSIONS.ADD_GATEWAY_SUPPLY,
//     USER_PERMISSIONS.VIEW_GATEWAY_SUPPLY,
//     USER_PERMISSIONS.EDIT_GATEWAY_SUPPLY,
//     USER_PERMISSIONS.DELETE_GATEWAY_SUPPLY,
//   ],
//   [USER_PERMISSION_GROUPS.REPORT]: [
//     USER_PERMISSIONS.VIEW_REPORT,
//     USER_PERMISSIONS.REQUEST_REPORT
//   ],
//   [USER_PERMISSION_GROUPS.SITE]: [
//     USER_PERMISSIONS.ADD_BUILDING,
//     USER_PERMISSIONS.EDIT_BUILDING,
//     USER_PERMISSIONS.VIEW_BUILDING,
//     USER_PERMISSIONS.DELETE_BUILDING,
//     USER_PERMISSIONS.ADD_FLOOR,
//     USER_PERMISSIONS.EDIT_FLOOR,
//     USER_PERMISSIONS.VIEW_FLOOR,
//     USER_PERMISSIONS.DELETE_FLOOR,
//     USER_PERMISSIONS.ADD_LOCATION,
//     USER_PERMISSIONS.EDIT_LOCATION,
//     USER_PERMISSIONS.VIEW_LOCATION,
//     USER_PERMISSIONS.DELETE_LOCATION,
//     USER_PERMISSIONS.VIEW_SITE_INVENTORY,
//     USER_PERMISSIONS.ADD_SITE_USER,
//     USER_PERMISSIONS.EDIT_SITE_USER,
//     USER_PERMISSIONS.VIEW_SITE_USER,
//     USER_PERMISSIONS.DELETE_SITE_USER,
//     USER_PERMISSIONS.ADD_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_SITE_THRESHOLD,
//     USER_PERMISSIONS.DELETE_SITE_THRESHOLD,
//     USER_PERMISSIONS.EDIT_SITE_THRESHOLD
//   ],
//   [USER_PERMISSION_GROUPS.THRESHOLD]: [
//     USER_PERMISSIONS.ADD_THRESHOLD,
//     USER_PERMISSIONS.EDIT_THRESHOLD,
//     USER_PERMISSIONS.VIEW_THRESHOLD,
//     USER_PERMISSIONS.DELETE_THRESHOLD
//   ],
//   [USER_PERMISSION_GROUPS.USER]: [
//     USER_PERMISSIONS.ADD_USER,
//     USER_PERMISSIONS.EDIT_USER,
//     USER_PERMISSIONS.VIEW_USER,
//     USER_PERMISSIONS.DELETE_USER,
//     USER_PERMISSIONS.ENABLE_API
//   ]
// }

// const USER_PERMISSION_STARTER_PACK = {
//   [ROLES.SUPER_ADMIN]: [
//     USER_PERMISSIONS.ADD_COMPANY,
//     USER_PERMISSIONS.EDIT_COMPANY,
//     USER_PERMISSIONS.DELETE_COMPANY,
//     USER_PERMISSIONS.VIEW_COMPANY,
//     USER_PERMISSIONS.CREATE_SITE,
//     USER_PERMISSIONS.EDIT_SITE,
//     USER_PERMISSIONS.DELETE_SITE,
//     USER_PERMISSIONS.VIEW_ALERTS,
//     USER_PERMISSIONS.EDIT_ALERT,
//     USER_PERMISSIONS.ADD_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.ADD_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_SITE_DEPLOYMENTS,
//     USER_PERMISSIONS.ADD_POD,
//     USER_PERMISSIONS.VIEW_POD,
//     USER_PERMISSIONS.EDIT_POD,
//     USER_PERMISSIONS.DELETE_POD,
//     USER_PERMISSIONS.BULK_UPLOAD_PODS,
//     USER_PERMISSIONS.GENERATE_PODS_PILLAR_IDS,
//     USER_PERMISSIONS.ADD_GATEWAY,
//     USER_PERMISSIONS.VIEW_GATEWAY,
//     USER_PERMISSIONS.EDIT_GATEWAY,
//     USER_PERMISSIONS.DELETE_GATEWAY,
//     USER_PERMISSIONS.ADD_ASSET_LOCATION,
//     USER_PERMISSIONS.VIEW_ASSET_LOCATION,
//     USER_PERMISSIONS.EDIT_ASSET_LOCATION,
//     USER_PERMISSIONS.DELETE_ASSET_LOCATION,
//     USER_PERMISSIONS.ADD_SIM_CARD,
//     USER_PERMISSIONS.VIEW_SIM_CARD,
//     USER_PERMISSIONS.EDIT_SIM_CARD,
//     USER_PERMISSIONS.DELETE_SIM_CARD,
//     USER_PERMISSIONS.VIEW_REPORT,
//     USER_PERMISSIONS.REQUEST_REPORT,
//     USER_PERMISSIONS.ADD_BUILDING,
//     USER_PERMISSIONS.EDIT_BUILDING,
//     USER_PERMISSIONS.VIEW_BUILDING,
//     USER_PERMISSIONS.DELETE_BUILDING,
//     USER_PERMISSIONS.ADD_FLOOR,
//     USER_PERMISSIONS.EDIT_FLOOR,
//     USER_PERMISSIONS.VIEW_FLOOR,
//     USER_PERMISSIONS.DELETE_FLOOR,
//     USER_PERMISSIONS.ADD_LOCATION,
//     USER_PERMISSIONS.EDIT_LOCATION,
//     USER_PERMISSIONS.VIEW_LOCATION,
//     USER_PERMISSIONS.DELETE_LOCATION,
//     USER_PERMISSIONS.VIEW_SITE_INVENTORY,
//     USER_PERMISSIONS.ADD_THRESHOLD,
//     USER_PERMISSIONS.EDIT_THRESHOLD,
//     USER_PERMISSIONS.VIEW_THRESHOLD,
//     USER_PERMISSIONS.DELETE_THRESHOLD,
//     USER_PERMISSIONS.ADD_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_SITE_THRESHOLD,
//     USER_PERMISSIONS.DELETE_SITE_THRESHOLD,
//     USER_PERMISSIONS.EDIT_SITE_THRESHOLD,
//     USER_PERMISSIONS.ADD_USER,
//     USER_PERMISSIONS.EDIT_USER,
//     USER_PERMISSIONS.VIEW_USER,
//     USER_PERMISSIONS.DELETE_USER,
//     USER_PERMISSIONS.ADD_SITE_USER,
//     USER_PERMISSIONS.EDIT_SITE_USER,
//     USER_PERMISSIONS.VIEW_SITE_USER,
//     USER_PERMISSIONS.DELETE_SITE_USER,
//     USER_PERMISSIONS.ENABLE_API
//   ],
//   [ROLES.PILLAR_ADMIN]: [
//     USER_PERMISSIONS.ADD_COMPANY,
//     USER_PERMISSIONS.EDIT_COMPANY,
//     USER_PERMISSIONS.DELETE_COMPANY,
//     USER_PERMISSIONS.VIEW_COMPANY,
//     USER_PERMISSIONS.CREATE_SITE,
//     USER_PERMISSIONS.EDIT_SITE,
//     USER_PERMISSIONS.DELETE_SITE,
//     USER_PERMISSIONS.VIEW_ALERTS,
//     USER_PERMISSIONS.EDIT_ALERT,
//     USER_PERMISSIONS.ADD_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.ADD_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_SITE_DEPLOYMENTS,
//     USER_PERMISSIONS.ADD_POD,
//     USER_PERMISSIONS.VIEW_POD,
//     USER_PERMISSIONS.EDIT_POD,
//     USER_PERMISSIONS.DELETE_POD,
//     USER_PERMISSIONS.BULK_UPLOAD_PODS,
//     USER_PERMISSIONS.GENERATE_PODS_PILLAR_IDS,
//     USER_PERMISSIONS.ADD_GATEWAY,
//     USER_PERMISSIONS.VIEW_GATEWAY,
//     USER_PERMISSIONS.EDIT_GATEWAY,
//     USER_PERMISSIONS.DELETE_GATEWAY,
//     USER_PERMISSIONS.ADD_ASSET_LOCATION,
//     USER_PERMISSIONS.VIEW_ASSET_LOCATION,
//     USER_PERMISSIONS.EDIT_ASSET_LOCATION,
//     USER_PERMISSIONS.DELETE_ASSET_LOCATION,
//     USER_PERMISSIONS.ADD_SIM_CARD,
//     USER_PERMISSIONS.VIEW_SIM_CARD,
//     USER_PERMISSIONS.EDIT_SIM_CARD,
//     USER_PERMISSIONS.DELETE_SIM_CARD,
//     USER_PERMISSIONS.VIEW_REPORT,
//     USER_PERMISSIONS.REQUEST_REPORT,
//     USER_PERMISSIONS.ADD_BUILDING,
//     USER_PERMISSIONS.EDIT_BUILDING,
//     USER_PERMISSIONS.VIEW_BUILDING,
//     USER_PERMISSIONS.DELETE_BUILDING,
//     USER_PERMISSIONS.ADD_FLOOR,
//     USER_PERMISSIONS.EDIT_FLOOR,
//     USER_PERMISSIONS.VIEW_FLOOR,
//     USER_PERMISSIONS.DELETE_FLOOR,
//     USER_PERMISSIONS.ADD_LOCATION,
//     USER_PERMISSIONS.EDIT_LOCATION,
//     USER_PERMISSIONS.VIEW_LOCATION,
//     USER_PERMISSIONS.DELETE_LOCATION,
//     USER_PERMISSIONS.VIEW_SITE_INVENTORY,
//     USER_PERMISSIONS.ADD_THRESHOLD,
//     USER_PERMISSIONS.EDIT_THRESHOLD,
//     USER_PERMISSIONS.VIEW_THRESHOLD,
//     USER_PERMISSIONS.DELETE_THRESHOLD,
//     USER_PERMISSIONS.ADD_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_SITE_THRESHOLD,
//     USER_PERMISSIONS.DELETE_SITE_THRESHOLD,
//     USER_PERMISSIONS.EDIT_SITE_THRESHOLD,
//     USER_PERMISSIONS.ADD_USER,
//     USER_PERMISSIONS.EDIT_USER,
//     USER_PERMISSIONS.VIEW_USER,
//     USER_PERMISSIONS.DELETE_USER,
//     USER_PERMISSIONS.ADD_SITE_USER,
//     USER_PERMISSIONS.EDIT_SITE_USER,
//     USER_PERMISSIONS.VIEW_SITE_USER,
//     USER_PERMISSIONS.DELETE_SITE_USER,
//     USER_PERMISSIONS.ENABLE_API
//   ],
//   [ROLES.PILLAR_USER]: [
//     USER_PERMISSIONS.VIEW_ALERTS,
//     USER_PERMISSIONS.EDIT_ALERT,
//     USER_PERMISSIONS.ADD_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.ADD_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_SITE_DEPLOYMENTS,
//     USER_PERMISSIONS.ADD_POD,
//     USER_PERMISSIONS.VIEW_POD,
//     USER_PERMISSIONS.EDIT_POD,
//     USER_PERMISSIONS.DELETE_POD,
//     USER_PERMISSIONS.BULK_UPLOAD_PODS,
//     USER_PERMISSIONS.GENERATE_PODS_PILLAR_IDS,
//     USER_PERMISSIONS.ADD_GATEWAY,
//     USER_PERMISSIONS.VIEW_GATEWAY,
//     USER_PERMISSIONS.EDIT_GATEWAY,
//     USER_PERMISSIONS.DELETE_GATEWAY,
//     USER_PERMISSIONS.ADD_ASSET_LOCATION,
//     USER_PERMISSIONS.VIEW_ASSET_LOCATION,
//     USER_PERMISSIONS.EDIT_ASSET_LOCATION,
//     USER_PERMISSIONS.DELETE_ASSET_LOCATION,
//     USER_PERMISSIONS.ADD_SIM_CARD,
//     USER_PERMISSIONS.VIEW_SIM_CARD,
//     USER_PERMISSIONS.EDIT_SIM_CARD,
//     USER_PERMISSIONS.DELETE_SIM_CARD,
//     USER_PERMISSIONS.VIEW_REPORT,
//     USER_PERMISSIONS.REQUEST_REPORT,
//     USER_PERMISSIONS.ADD_BUILDING,
//     USER_PERMISSIONS.EDIT_BUILDING,
//     USER_PERMISSIONS.VIEW_BUILDING,
//     USER_PERMISSIONS.DELETE_BUILDING,
//     USER_PERMISSIONS.ADD_FLOOR,
//     USER_PERMISSIONS.EDIT_FLOOR,
//     USER_PERMISSIONS.VIEW_FLOOR,
//     USER_PERMISSIONS.DELETE_FLOOR,
//     USER_PERMISSIONS.ADD_LOCATION,
//     USER_PERMISSIONS.EDIT_LOCATION,
//     USER_PERMISSIONS.VIEW_LOCATION,
//     USER_PERMISSIONS.DELETE_LOCATION,
//     USER_PERMISSIONS.VIEW_SITE_INVENTORY,
//     USER_PERMISSIONS.ADD_THRESHOLD,
//     USER_PERMISSIONS.EDIT_THRESHOLD,
//     USER_PERMISSIONS.VIEW_THRESHOLD,
//     USER_PERMISSIONS.DELETE_THRESHOLD,
//     USER_PERMISSIONS.ADD_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_SITE_THRESHOLD,
//     USER_PERMISSIONS.DELETE_SITE_THRESHOLD,
//     USER_PERMISSIONS.EDIT_SITE_THRESHOLD,
//     USER_PERMISSIONS.ADD_USER,
//     USER_PERMISSIONS.EDIT_USER,
//     USER_PERMISSIONS.VIEW_USER,
//     USER_PERMISSIONS.DELETE_USER,
//     USER_PERMISSIONS.ADD_SITE_USER,
//     USER_PERMISSIONS.EDIT_SITE_USER,
//     USER_PERMISSIONS.VIEW_SITE_USER,
//     USER_PERMISSIONS.DELETE_SITE_USER
//   ],
//   [ROLES.PILLAR_CONTRACTOR]: [
//     USER_PERMISSIONS.VIEW_INSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.ADD_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.DELETE_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.EDIT_UNINSTALL_DEPLOYMENT,
//     USER_PERMISSIONS.VIEW_ALL_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_SITE_DEPLOYMENTS,
//     USER_PERMISSIONS.VIEW_POD,
//     USER_PERMISSIONS.VIEW_GATEWAY,
//     USER_PERMISSIONS.VIEW_BUILDING,
//     USER_PERMISSIONS.VIEW_FLOOR,
//     USER_PERMISSIONS.ADD_LOCATION,
//     USER_PERMISSIONS.EDIT_LOCATION,
//     USER_PERMISSIONS.VIEW_LOCATION,
//     USER_PERMISSIONS.DELETE_LOCATION,
//     USER_PERMISSIONS.VIEW_SITE_INVENTORY,
//     USER_PERMISSIONS.VIEW_THRESHOLD,
//     USER_PERMISSIONS.ADD_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_SITE_THRESHOLD,
//     USER_PERMISSIONS.DELETE_SITE_THRESHOLD,
//     USER_PERMISSIONS.EDIT_SITE_THRESHOLD,
//     USER_PERMISSIONS.VIEW_USER,
//     USER_PERMISSIONS.VIEW_SITE_USER
//   ]
// }

const PILLAR_TOKEN = 'pillar_token'

const CONVERSATION_STATUS = {
  ACTIVE: 'ACTIVE',
  RESOLVED: 'RESOLVED',
}

const CHAT_MESSAGE_TYPES = {
  TEXT: 'TEXT',
  CALL: 'CALL',
  EMAIL: 'EMAIL',
}

const CHAT_MESSAGE_STATUS = {
  // SMS
  FAILED: 'failed',
  SENT: 'sent',
  DELIVERED: 'delivered',
  UNDELIVERED: 'undelivered',

  // EMAIL
  PROCESSED: 'processed',
  DROPPED: 'dropped',
  DEFERRED: 'deferred',
  BOUNCE: 'bounce',
  BLOCKED: 'blocked',
  OPEN: 'open',
  CLICK: 'click',
  SPAM_REPORT: 'spamreport',
  UNSUBSCRIBE: 'unsubscribe',
}

const WATER_METER_VALUES = {
  HIGH_FLOW: 120,
  HIGH_TOTAL_FLOW: 10000,
}

const DEFAULT_PLAN_ZOOM_LEVEL = -0.25

const SOCKET_CHANNELS = {
  NEW_MESSAGE: 'chat.new_message',
  INBOX_STATUS: 'chat.inbox_status',
  MESSAGE_STATUS: 'chat.message_status',
  READ_MESSAGES: 'chat.read_messages',
  SEND_MESSAGE: 'chat.send_message',
}

const BLE_COMPANIES = {
  MILWAUKEE: 'MILWAUKEE',
}

const REPORT_SEND_DAYS = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
}

const DAYS_INTERVALS = {
  ONE_WEEK: 7,
  TWO_WEEKS: 14,
  ONE_MONTH: 28,
}

const DEFAULT_CHART_THEME = [
  '#2f7ed8',
  '#0d233a',
  '#8bbc21',
  '#910000',
  '#1aadce',
  '#492970',
  '#f28f43',
  '#77a1e5',
  '#c42525',
  '#a6c96a',
]

const REPORT_CHART_COLORS = {
  [SMART_SENSOR_TYPES.TEMPERATURE]: [
    '#2f7ed8',
    '#0d233a',
    '#8bbc21',
    '#910000',
    '#1aadce',
    '#492970',
    '#f28f43',
    '#77a1e5',
    '#c42525',
    '#a6c96a',
  ],
  [SMART_SENSOR_TYPES.HUMIDITY]: [
    '#6794a7',
    '#014d64',
    '#76c0c1',
    '#01a2d9',
    '#7ad2f6',
    '#00887d',
    '#adadad',
    '#7bd3f6',
    '#7c260b',
    '#ee8f71',
  ],
  [SMART_SENSOR_TYPES.DUST]: [
    '#4572A7',
    '#AA4643',
    '#89A54E',
    '#80699B',
    '#3D96AE',
    '#DB843D',
    '#92A8CD',
    '#A47D7C',
    '#B5CA92',
  ],
  // TODO
  // aqi: [],
  // co: [],
}

const THRESHOLD_GROUP_TYPES = {
  HIGH_HEAT: 'HIGH_HEAT',
  FIRE: 'FIRE',
  FREEZING_TEMPERATURES: 'FREEZING_TEMPERATURES',
  CO_OCCUPATIONAL_SAFETY: 'CO_OCCUPATIONAL_SAFETY',
  CLEAN_ROOM: 'CLEAN_ROOM',
  GENERAL_CLEANLINESS: 'GENERAL_CLEANLINESS',
  MATERIAL_CONDITION: 'MATERIAL_CONDITION',
  MOLD_GROWTH: 'MOLD_GROWTH',
}

const LOCATION_TYPES = {
  POD: 'POD',
  FLOW_MONITOR: 'FLOW_MONITOR',
  LEAK_POD: 'LEAK_POD',
}

const SITE_INSTALL_TYPE = {
  ONSITE: 'ONSITE',
  REMOTE: 'REMOTE',
}

const SYSTEM_ALERT_TYPES = {
  OFFLINE: 'OFFLINE',
  ANOMALY: 'ANOMALY',
  WET_POD: 'WET_POD',
}

const THRESHOLD_MESSAGE_KEYWORDS = [
  '{locationName}',
  '{locationDesc}',
  '{flrNumber}',
  '{siteName}',
  '{pillarID}',
  '{buildingName}',
  '{temp}',
  '{hum}',
  '{pres}',
  '{dust}',
  '{carbon_monoxide}',
  '{flow_value}',
  '{leak_event}',
  '{leak_value}',
]

const UNIT_PRESSURE = {
  KILOPASCAL: 'KILOPASCAL',
  INCHES_OF_MERCURY: 'INCHES_OF_MERCURY',
}

const UNIT_TEMPERATURE = {
  CELSIUS: 'CELSIUS',
  FAHRENHEIT: 'FAHRENHEIT',
}

const UNIT_AMBIENT_LIGHT = {
  FOOT_CANDLES: 'FOOT_CANDLES',
  MICROWATT_PER_CM_SQ: 'MICROWATT_PER_CM_SQ',
}

export {
  ROLES,
  MESSAGES,
  ERROR_MESSAGES,
  ERROR_TRIGGERS,
  ERROR_MESSAGE_LIST,
  THRESHOLD_TYPES,
  THRESHOLD_TYPES_BETA,
  THRESHOLD_DATA_SOURCES,
  THRESHOLD_LEVELS,
  THRESHOLD_ALERT_TYPES,
  ALERT_TYPES,
  ALERT_STATUS,
  SITE_STATUS,
  GATEWAY_STATUS,
  GATEWAY_TYPES,
  SIM_CARRIERS,
  GATEWAY_NETWORK,
  GATEWAY_STACK_TYPES,
  POD_STATUS,
  POD_TYPES,
  TIME_FORMAT,
  COLORS,
  SIZES,
  UNITS,
  SMART_SENSOR_TYPES,
  PDS_SENSOR_TYPES,
  SENSOR_READING_TYPES,
  IMU_SENSOR_READING_TYPES,
  FLOW_SENSOR_READING_TYPES,
  Z_INDEX,
  DEPLOYMENT_STATUS,
  DEPLOYMENT_TYPE,
  SHIPPING_VENDOR,
  PART_RESOURCE_TYPE,
  PURCHASE_STATUS,
  ALL_SUPPLY_TYPES,
  GATEWAY_SUPPLY_TYPE,
  FLOW_MONITOR_SUPPLY_TYPE,
  KEYCODES,
  FONT_FAMILY,
  TIME,
  REPORT_SENSOR_READING_TYPES,
  REPORT_STATUS,
  REPORT_TYPES,
  ALERT_STATES,
  COMMUNICATION_MODES,
  VISIBLE_READINGS,
  READING_ABBREVIATIONS,
  RESOURCE_TYPES,
  VIEW_ALL,
  ALL,
  OTHER,
  UNASSIGNED,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  GENERIC_ERROR_MESSAGE,
  INSTALL_TYPE,
  ZOOM_LEVELS,
  WATER_METER_VALUES,
  WATER_METER_TYPES,
  FLOW_MONITOR_STATUS,
  WATER_VALVE_STATUS,
  WATER_VALVE_TYPES,
  CONNECTIVITY_STATUS,
  BATTERY_INDICATOR,
  SIM_CARD_STATUS,
  SIM_USAGE,
  BATTERY_STATE,
  DEFECTIVE_SENSOR_TYPES,
  SUMMARY_INTERVAL_OPTIONS,
  SUMMARY_REPEAT_FREQUENCY_OPTIONS,
  ENTITY_TYPES,
  COMPANY_TYPES,
  DEFAULT_ANIMATION_DURATION,
  DEFAULT_TOOLTIP_ANIMATION_DURATION,
  ACTION_REPORT_STATE,
  BUILDING_VIEW_TYPES,
  LORA_ANTENNA_TYPE,
  CELLULAR_ANTENNA_TYPE,
  LORA_CABLE_SIZE,
  SOLAR_PANEL,
  RI_METRICS_TYPES,
  UPS_BATTERY,
  POD_ASSET_TYPES,
  WORKFLOW_TYPES,
  SMOOTH_INTERVAL,
  USER_PERMISSIONS,
  // USER_PERMISSION_GROUPS,
  // USER_PERMISSION_GROUP_MAPPING,
  // USER_PERMISSION_STARTER_PACK,
  PILLAR_TOKEN,
  CHAT_MESSAGE_TYPES,
  DEFAULT_PLAN_ZOOM_LEVEL,
  CHAT_MESSAGE_STATUS,
  CONVERSATION_STATUS,
  SOCKET_CHANNELS,
  BLE_COMPANIES,
  REPORT_SEND_DAYS,
  DAYS_INTERVALS,
  REPORT_CHART_COLORS,
  DEFAULT_CHART_THEME,
  THRESHOLD_GROUP_TYPES,
  LOCATION_TYPES,
  SITE_INSTALL_TYPE,
  SYSTEM_ALERT_TYPES,
  THRESHOLD_MESSAGE_KEYWORDS,
  SITE_DOCUMENT_TYPES,
  UNIT_PRESSURE,
  UNIT_TEMPERATURE,
  UNIT_AMBIENT_LIGHT,
}
