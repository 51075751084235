import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import sortBy from 'sort-by'
import cx from 'classnames'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Spin,
  Tooltip,
} from './common/Ant'
import Select, { Option } from './common/Select'
import { H3 } from './common/Headers'
import { Anchor, ActionAnchor } from './common/Anchor'
import { Bread, Crumb } from './common/Bread'
import SearchFilter from './common/SearchFilter'
import AllSitesCSVDownload from './AllSitesCSVDownload'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import {
  toTitleCase,
  renderSiteStatus,
  formatSIMCarrier,
  formatSIMCarrierIconName,
} from '../utils/textFormatters'
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  VIEW_ALL,
  SITE_STATUS,
  SIM_CARRIERS,
  LOCATION_TYPES,
  ALERT_TYPES,
  ALERT_STATUS,
  USER_PERMISSIONS,
  ROLES,
} from '../constants'
import { configurePagination } from '../utils/helpers'

const calculatePercentage = (x, total) => Math.round((x * 100) / total)

class AllSites extends Component {
  constructor(props) {
    super(props)

    this.state = {
      sortedInfo: {},
      data: this.props.sites,
      searchText: '',
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      status: undefined,
      companySlug: undefined,
      simType: undefined,
      hasAlertType: undefined,
    }
  }

  static propTypes = {
    sites: PropTypes.arrayOf(PropTypes.object).isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    accountProfile: PropTypes.object.isRequired,
    windowWidth: PropTypes.number.isRequired,
    isSiteLoading: PropTypes.bool,
    deleteSite: PropTypes.func,
    getAllSites: PropTypes.func,
    getAllCompanies: PropTypes.func,
    updateQueryParams: PropTypes.func,
    meta: PropTypes.object,
    isRenderingDashboardView: PropTypes.bool,
    sectionTitle: PropTypes.string,
    url: PropTypes.string,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.sites })
  }

  componentDidMount() {
    const { getAllSites, getAllCompanies, updateQueryParams, url } = this.props
    const {
      page,
      perPage,
      status,
      companySlug,
      simType,
      hasAlertType,
    } = parseQueryParams(global.location)
    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
      status: status
        ? status
        : hasRole(
            ROLES.PILLAR_ADMIN,
            ROLES.SUPER_ADMIN,
            ROLES.PILLAR_CONTRACTOR
          )
        ? [SITE_STATUS.ACTIVE, SITE_STATUS.INSTALLING, SITE_STATUS.UNINSTALL]
        : [SITE_STATUS.ACTIVE],
      companySlug,
      simType,
      hasAlertType,
    }
    const qs = createQueryString({ ...query, full: 't' })

    getAllSites && getAllSites(qs)
    hasPermission(USER_PERMISSIONS.VIEW_COMPANY) &&
      getAllCompanies &&
      getAllCompanies(createQueryString({ perPage: 'all' }))

    this.setState(query)

    updateQueryParams &&
      updateQueryParams({
        pathname: url,
        search: qs,
      })
  }

  componentDidUpdate(prevProps) {
    const { accountProfile, getAllCompanies } = this.props

    // TODO how to do this w/o passing accountProfile into everything?
    if (!prevProps.accountProfile.role && accountProfile.role) {
      hasPermission(USER_PERMISSIONS.VIEW_COMPANY) &&
        getAllCompanies &&
        getAllCompanies(createQueryString({ perPage: 'all' }))
    }
  }

  handleChange = (pagination, filters, sorter) => {
    const {
      isRenderingDashboardView,
      getAllSites,
      updateQueryParams,
      url,
    } = this.props
    const {
      page,
      perPage,
      status,
      companySlug,
      simType,
      hasAlertType,
      searchText,
    } = this.state

    if (!isRenderingDashboardView && page !== pagination.current) {
      const query = {
        page: pagination.current,
        perPage,
        full: 't',
        status,
        companySlug,
        simType,
        hasAlertType,
        filter: searchText,
      }
      const qs = createQueryString(query)

      getAllSites(qs)

      updateQueryParams &&
        updateQueryParams({
          pathname: url,
          search: qs,
        })
    }

    this.setState({
      page: pagination.current,
      sortedInfo: sorter,
    })
  }

  handleFilterChange = keyName => value => {
    const { getAllSites, updateQueryParams } = this.props
    const {
      page,
      perPage,
      status,
      companySlug,
      simType,
      hasAlertType,
    } = this.state

    const newValue =
      value === undefined || (value.length && value.indexOf('all')) > -1
        ? undefined
        : value

    const query = Object.assign(
      {
        page,
        perPage,
        full: 't',
        status,
        companySlug,
        simType,
        hasAlertType,
      },
      { [keyName]: newValue }
    )

    this.setState({ [keyName]: newValue })

    // if we expect the number of results to change, we need to reset to the
    // first page so API does not return a 404
    if (keyName !== 'page' || keyName !== 'perPage') {
      query.page = DEFAULT_PAGE
      this.setState({ page: DEFAULT_PAGE })
    }

    const qs = createQueryString(query)
    getAllSites && getAllSites(qs)

    updateQueryParams && updateQueryParams({ search: qs })
  }

  setContainerStyle = style => cx(style, 'dib mh1 ba b--moon-gray br2 flex')

  renderLocationColumn = (podCount, systemAlertsLocationCount) => {
    const online =
      podCount > 0 ? `${podCount - systemAlertsLocationCount} online` : ''

    const offline =
      systemAlertsLocationCount > 0
        ? ` | ${systemAlertsLocationCount} offline`
        : ''

    let style
    if (podCount === 0) {
      style = 'bg-dark-blue'
    } else if (podCount > 0 && podCount - systemAlertsLocationCount <= 0) {
      style = 'bg-dark-red'
    } else if (systemAlertsLocationCount > 0) {
      style = 'bg-orange'
    } else {
      style = 'bg-green'
    }

    return {
      locationStr: online + offline,
      locationStyle: this.setContainerStyle(style),
    }
  }

  renderGatewaysColumn = (gateways = []) => {
    const offlineGateways = gateways.filter(x => !x.online)
    const onlineGatewayCount = gateways.length - offlineGateways.length
    const online = gateways.length > 0 ? `${onlineGatewayCount} online` : ''
    const offline =
      offlineGateways.length > 0 ? ` | ${offlineGateways.length} offline` : ''

    let style
    if (gateways.length > 0 && onlineGatewayCount <= 0) {
      style = 'bg-dark-red'
    } else if (offlineGateways.length > 0) {
      style = 'bg-orange'
    } else {
      style = 'bg-green'
    }

    return {
      gatewaysStr: online + offline,
      gatewaysStyle: this.setContainerStyle(style),
    }
  }

  setSearchText = searchText => this.setState({ searchText })

  render() {
    const {
      deleteSite,
      meta,
      windowWidth,
      sectionTitle,
      isRenderingDashboardView,
      isSiteLoading = false,
      companies,
      getAllSites,
    } = this.props
    const {
      data,
      sortedInfo,
      page,
      perPage,
      status,
      companySlug,
      simType,
      hasAlertType,
      searchText,
    } = this.state

    const textStyle =
      'ph2 br2 br--right b--light-gray t-shadow-1 white no-hover'
    const isFixedColumn = windowWidth < 750

    const query = {
      page,
      perPage,
      full: 't',
      status,
      companySlug,
      simType,
      hasAlertType,
    }

    return (
      <section className="mb5 AllSites">
        {isRenderingDashboardView ? (
          <H3>{sectionTitle || 'Sites'}</H3>
        ) : (
          <div className="mb3">
            <div className="mb2">
              <Bread>
                <Crumb>Sites</Crumb>
              </Bread>
            </div>
            {hasPermission(USER_PERMISSIONS.ADD_SITE) && (
              <div className="flex justify-center">
                <span>
                  <Anchor to="/sites/create-proposal">Create New</Anchor>
                </span>
                <span>
                  <AllSitesCSVDownload sites={data} />
                </span>
              </div>
            )}
          </div>
        )}
        {!isRenderingDashboardView && (
          <Fragment>
            <div className="flex-l items-center-l">
              <Select
                className="w-third-l mr3-l"
                placeholder="Status"
                mode="multiple"
                input={{
                  value: status,
                  onChange: this.handleFilterChange('status'),
                }}
                filterable
              >
                {[undefined, ...Object.keys(SITE_STATUS)].map(x => (
                  <Option value={SITE_STATUS[x] || 'all'} key={x || 'all'}>
                    {toTitleCase(x) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              {hasPermission(USER_PERMISSIONS.VIEW_COMPANY) && (
                <Select
                  className="w-third-l mr3-l"
                  placeholder="Company"
                  mode="multiple"
                  input={{
                    value: companySlug,
                    onChange: this.handleFilterChange('companySlug'),
                  }}
                  filterable
                >
                  {[{ slug: 'all', name: VIEW_ALL }, ...companies].map(x => (
                    <Option value={x.slug} key={x.slug}>
                      {x.name}
                    </Option>
                  ))}
                </Select>
              )}
              <Select
                className="w-third-l mr3-l"
                placeholder="Active Alerts"
                mode="multiple"
                input={{
                  value: hasAlertType,
                  onChange: this.handleFilterChange('hasAlertType'),
                }}
                filterable
              >
                {[undefined, ...Object.keys(ALERT_TYPES)].map(x => (
                  <Option value={ALERT_TYPES[x] || 'all'} key={x || 'all'}>
                    {toTitleCase(x) || VIEW_ALL}
                  </Option>
                ))}
              </Select>
              {hasPermission(USER_PERMISSIONS.VIEW_SIM_CARD) && (
                <Select
                  className="w-third-l"
                  placeholder="SIM Type"
                  mode="multiple"
                  input={{
                    value: simType,
                    onChange: this.handleFilterChange('simType'),
                  }}
                  filterable
                >
                  {['all', ...Object.keys(SIM_CARRIERS)].map(x => (
                    <Option value={x} key={x}>
                      {formatSIMCarrier(SIM_CARRIERS[x]) || VIEW_ALL}
                    </Option>
                  ))}
                </Select>
              )}
            </div>
            <SearchFilter
              getItems={getAllSites}
              searchText={searchText}
              setSearchText={this.setSearchText}
              query={query}
            />
          </Fragment>
        )}
        {isSiteLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            dataSource={data}
            onChange={this.handleChange}
            rowKey="slug"
            scroll={{ x: 650 }}
            bordered
            pagination={
              !isRenderingDashboardView &&
              configurePagination({
                perPage,
                onShowSizeChange: (page, perPage) =>
                  this.handleFilterChange('perPage')(perPage),
                ...meta,
              })
            }
            tableLayout="auto"
          >
            <Column
              title="Name"
              dataIndex="name"
              sorter={sortBy('name')}
              sortOrder={sortedInfo.field === 'name' && sortedInfo.order}
              width={200}
              render={(text, record) => {
                const anchor = (
                  <Anchor to={`/sites/${record.slug}`}>{text}</Anchor>
                )

                return record.test ? (
                  <div className="flex items-center">
                    {anchor}
                    <span className="bg-neon-lime ph2 ba b--moon-gray br2 ml2 f7 ttu">
                      Test
                    </span>
                  </div>
                ) : (
                  anchor
                )
              }}
            />
            <Column
              title="Status"
              dataIndex="status"
              sorter={sortBy('status')}
              sortOrder={sortedInfo.field === 'status' && sortedInfo.order}
              width={50}
              render={text => renderSiteStatus(text)}
            />
            <Column
              title="Alerts"
              dataIndex={['stats', 'smartPod']}
              width={50}
              render={(text, record) => {
                if (!!record.stats && !!record.stats.smartPod) {
                  const informationalAlertsLocationCount =
                    record.stats.smartPod.informationalAlertsLocationCount
                  const actionableAlertsLocationCount =
                    record.stats.smartPod.actionableAlertsLocationCount
                  const thresholdAlertsLocationCount =
                    record.stats.smartPod.thresholdAlertsLocationCount
                  const infoAlertLocationsPercentage = calculatePercentage(
                    informationalAlertsLocationCount,
                    thresholdAlertsLocationCount
                  )
                  const actionAlertLocationsPercentage = calculatePercentage(
                    actionableAlertsLocationCount,
                    thresholdAlertsLocationCount
                  )
                  return thresholdAlertsLocationCount > 0 ? (
                    <div className="flex ba b--moon-gray br2">
                      <Tooltip
                        title={`${actionableAlertsLocationCount} Actionable Alerts`}
                      >
                        <div
                          className={cx('h1.5 bg-dark-red w-50', {
                            'br1 br--left': !!infoAlertLocationsPercentage,
                            br1: !infoAlertLocationsPercentage,
                          })}
                        >
                          <Link
                            className={textStyle}
                            to={`/alerts/all/pods?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                          >
                            <Icon type="alert" className="pr2" />
                            {actionableAlertsLocationCount}
                          </Link>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={`${informationalAlertsLocationCount} Informational Alerts`}
                      >
                        <div
                          className={cx('h1.5 bg-gold w-50', {
                            'br1 br--left': !!actionAlertLocationsPercentage,
                            br1: !actionAlertLocationsPercentage,
                          })}
                        >
                          <Link
                            className={textStyle}
                            to={`/alerts/all/pods?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                          >
                            <Icon type="alert" className="pr2" />
                            {informationalAlertsLocationCount}
                          </Link>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className={'bg-green ba b--moon-gray br2'}>
                      <Link
                        className={textStyle}
                        to={`/alerts/all/pods?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                      >
                        <Icon type="alert" className="pr2" />
                        {thresholdAlertsLocationCount}
                      </Link>
                    </div>
                  )
                } else {
                  return <div className="ml2">--</div>
                }
              }}
            />
            {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
              <Column
                title="Flow Alerts"
                dataIndex={['stats', 'flowMonitor']}
                width={50}
                render={(text, record) => {
                  if (!!record.stats && !!record.stats.flowMonitor) {
                    const informationalAlertsLocationCount =
                      record.stats.flowMonitor.informationalAlertsLocationCount
                    const actionableAlertsLocationCount =
                      record.stats.flowMonitor.actionableAlertsLocationCount
                    const thresholdAlertsLocationCount =
                      record.stats.flowMonitor.thresholdAlertsLocationCount
                    const infoAlertLocationsPercentage = calculatePercentage(
                      informationalAlertsLocationCount,
                      thresholdAlertsLocationCount
                    )
                    const actionAlertLocationsPercentage = calculatePercentage(
                      actionableAlertsLocationCount,
                      thresholdAlertsLocationCount
                    )
                    return thresholdAlertsLocationCount > 0 ? (
                      <div className="flex ba b--moon-gray br2">
                        <Tooltip
                          title={`${actionableAlertsLocationCount} Actionable Alerts`}
                        >
                          <div
                            className={cx('h1.5 bg-dark-red', {
                              'br1 br--left': !!infoAlertLocationsPercentage,
                              br1: !infoAlertLocationsPercentage,
                            })}
                          >
                            <Link
                              className={textStyle}
                              to={`/alerts/all/flow-monitors?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                            >
                              <Icon type="alert" className="pr2" />
                              {actionableAlertsLocationCount}
                            </Link>
                          </div>
                        </Tooltip>
                        <Tooltip
                          title={`${informationalAlertsLocationCount} Informational Alerts`}
                        >
                          <div
                            className={cx('h1.5 bg-gold', {
                              'br1 br--left': !!actionAlertLocationsPercentage,
                              br1: !actionAlertLocationsPercentage,
                            })}
                          >
                            <Link
                              className={textStyle}
                              to={`/alerts/all/flow-monitors?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                            >
                              <Icon type="alert" className="pr2" />
                              {informationalAlertsLocationCount}
                            </Link>
                          </div>
                        </Tooltip>
                      </div>
                    ) : (
                      <div className={'bg-green ba b--moon-gray br2'}>
                        <Link
                          className={textStyle}
                          to={`/alerts/all/flow-monitors?type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                        >
                          <Icon type="alert" className="pr2" />
                          {thresholdAlertsLocationCount}
                        </Link>
                      </div>
                    )
                  } else {
                    return <div className="ml2">--</div>
                  }
                }}
              />
            )}
            <Column
              title="Leak Alerts"
              dataIndex={['stats', 'leakPod']}
              width={50}
              render={(text, record) => {
                if (!!record.stats && !!record.stats.leakPod) {
                  const informationalAlertsLocationCount =
                    record.stats.leakPod.informationalAlertsLocationCount
                  const actionableAlertsLocationCount =
                    record.stats.leakPod.actionableAlertsLocationCount
                  const thresholdAlertsLocationCount =
                    record.stats.leakPod.thresholdAlertsLocationCount
                  const infoAlertLocationsPercentage = calculatePercentage(
                    informationalAlertsLocationCount,
                    thresholdAlertsLocationCount
                  )
                  const actionAlertLocationsPercentage = calculatePercentage(
                    actionableAlertsLocationCount,
                    thresholdAlertsLocationCount
                  )
                  return thresholdAlertsLocationCount > 0 ? (
                    <div className="flex ba b--moon-gray br2">
                      <Tooltip
                        title={`${actionableAlertsLocationCount} Actionable Alerts`}
                      >
                        <div
                          className={cx('h1.5 bg-dark-red', {
                            'br1 br--left': !!infoAlertLocationsPercentage,
                            br1: !infoAlertLocationsPercentage,
                          })}
                        >
                          <Link
                            className={textStyle}
                            to={`/alerts/all/pods?asset_type=${LOCATION_TYPES.LEAK_POD}&type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                          >
                            <Icon type="alert" className="pr2" />
                            {actionableAlertsLocationCount}
                          </Link>
                        </div>
                      </Tooltip>
                      <Tooltip
                        title={`${informationalAlertsLocationCount} Informational Alerts`}
                      >
                        <div
                          className={cx('h1.5 bg-gold', {
                            'br1 br--left': !!actionAlertLocationsPercentage,
                            br1: !actionAlertLocationsPercentage,
                          })}
                        >
                          <Link
                            className={textStyle}
                            to={`/alerts/all/pods?asset_type=${LOCATION_TYPES.LEAK_POD}&type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                          >
                            <Icon type="alert" className="pr2" />
                            {informationalAlertsLocationCount}
                          </Link>
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    <div className={'bg-green ba b--moon-gray br2'}>
                      <Link
                        className={textStyle}
                        to={`/alerts/all/pods?asset_type=${LOCATION_TYPES.LEAK_POD}&type=${ALERT_TYPES.THRESHOLD}&status=${ALERT_STATUS.ACTIVE}&site_slug=${record.slug}`}
                      >
                        <Icon type="alert" className="pr2" />
                        {thresholdAlertsLocationCount}
                      </Link>
                    </div>
                  )
                } else {
                  return <div className="ml2">--</div>
                }
              }}
            />
            <Column
              title="Smart Pods"
              dataIndex={['stats', 'smartPod']}
              width={50}
              render={(text, record) => {
                if (!!record.stats && !!record.stats.smartPod) {
                  const {
                    locationStr,
                    locationStyle,
                  } = this.renderLocationColumn(
                    record.stats.smartPod.totalCount,
                    record.stats.smartPod.systemAlertsLocationCount
                  )

                  return locationStr ? (
                    <div className={locationStyle}>
                      <div className={textStyle}>
                        <Icon type="compass" className="pr2" />
                        {locationStr}
                      </div>
                    </div>
                  ) : (
                    <div className="ml2">--</div>
                  )
                } else {
                  return <div className="ml2">--</div>
                }
              }}
            />
            {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
              <Column
                title="Water Monitors"
                dataIndex={['stats', 'flowMonitor']}
                width={50}
                render={(text, record) => {
                  if (!!record.stats && !!record.stats.flowMonitor) {
                    const {
                      locationStr,
                      locationStyle,
                    } = this.renderLocationColumn(
                      record.stats.flowMonitor.totalCount,
                      record.stats.flowMonitor.systemAlertsLocationCount
                    )

                    return locationStr ? (
                      <div className={locationStyle}>
                        <div className={textStyle}>
                          <Icon type="compass" className="pr2" />
                          {locationStr}
                        </div>
                      </div>
                    ) : (
                      <div className="ml2">--</div>
                    )
                  } else {
                    return <div className="ml2">--</div>
                  }
                }}
              />
            )}
            <Column
              title="Leak Pucks"
              dataIndex={['stats', 'leakPod']}
              width={50}
              render={(text, record) => {
                if (!!record.stats && !!record.stats.leakPod) {
                  const {
                    locationStr,
                    locationStyle,
                  } = this.renderLocationColumn(
                    record.stats.leakPod.totalCount,
                    record.stats.leakPod.systemAlertsLocationCount
                  )

                  return locationStr ? (
                    <div className={locationStyle}>
                      <div className={textStyle}>
                        <Icon type="compass" className="pr2" />
                        {locationStr}
                      </div>
                    </div>
                  ) : (
                    <div className="ml2">--</div>
                  )
                } else {
                  return <div className="ml2">--</div>
                }
              }}
            />
            <Column
              title="Network Gateways"
              dataIndex="gateways"
              width={50}
              render={(text, record) => {
                const {
                  gatewaysStyle,
                  gatewaysStr,
                } = this.renderGatewaysColumn(record.gateways)
                return gatewaysStr ? (
                  <div className={gatewaysStyle}>
                    <div className={textStyle}>
                      <Icon type="global" className="pr2" />
                      {gatewaysStr}
                    </div>
                  </div>
                ) : (
                  <div className="ml2">--</div>
                )
              }}
            />
            {hasPermission(USER_PERMISSIONS.VIEW_GATEWAY) && (
              <Column
                title="Offline SIM Type"
                dataIndex="gatewayCount"
                render={(text, record) => {
                  const offSimNames = !!record.gateways
                    ? record.gateways
                        .filter(item => !item.online)
                        .map(x => formatSIMCarrier(SIM_CARRIERS[x.simType]))
                        .filter((y, index, self) => self.indexOf(y) === index)
                    : []
                  const offSimCount =
                    offSimNames.length > 0
                      ? offSimNames.map(
                          x =>
                            record.gateways.filter(
                              y =>
                                !y.online &&
                                formatSIMCarrier(SIM_CARRIERS[y.simType]) === x
                            ).length
                        )
                      : []
                  const totalSimCount =
                    offSimNames.length > 0
                      ? offSimNames.map(
                          x =>
                            record.gateways.filter(
                              y =>
                                formatSIMCarrier(SIM_CARRIERS[y.simType]) === x
                            ).length
                        )
                      : []
                  return offSimNames.length > 0 ? (
                    <div className="flex">
                      {offSimNames.map((x, index) => (
                        <li key={index} className="mh1">
                          <Tooltip
                            title={`${x !== undefined ? `${x} —` : ''} ${
                              offSimCount[index]
                            } of ${totalSimCount[index]} Offline`}
                          >
                            {formatSIMCarrierIconName(x) && (
                              <img
                                src={formatSIMCarrierIconName(x)}
                                alt={x}
                                className="w1 h1 mr1"
                              />
                            )}
                            <span>
                              {`(${offSimCount[index]}/${totalSimCount[index]})`}
                            </span>
                          </Tooltip>
                        </li>
                      ))}
                    </div>
                  ) : (
                    <div className="ml2">&ndash; &ndash;</div>
                  )
                }}
              />
            )}
            <Column
              title="Floors"
              dataIndex={['stats', 'floorCount']}
              sorter={sortBy('floorCount')}
              width={50}
              sortOrder={sortedInfo.field === 'floorCount' && sortedInfo.order}
            />
            {!isRenderingDashboardView &&
              hasPermission(USER_PERMISSIONS.VIEW_COMPANY) && (
                <Column
                  title="Company"
                  dataIndex="companyName"
                  sorter={sortBy('companyName')}
                  sortOrder={
                    sortedInfo.field === 'companyName' && sortedInfo.order
                  }
                  width={200}
                  render={(text, record) =>
                    record.companyId && (
                      <Anchor to={`/companies/${record.companyId}`}>
                        {text}
                      </Anchor>
                    )
                  }
                />
              )}
            {!isRenderingDashboardView &&
              hasPermission(USER_PERMISSIONS.EDIT_SITE) && (
                <Column
                  fixed={isFixedColumn ? 'right' : false}
                  width={50}
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="edit-site">
                          <Anchor to={`/sites/${record.slug}/edit`}>
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-site">
                          <ActionAnchor onClick={() => deleteSite(record.slug)}>
                            Delete
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              )}
          </Table>
        )}
      </section>
    )
  }
}

export default AllSites
