import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import moment from 'moment'
import { H3 } from './common/Headers'
import { Table, Column, Tooltip } from './common/Ant'
import { Anchor } from './common/Anchor'
import {
  formatSIMCarrier,
  formatSIMCarrierIconName,
} from '../utils/textFormatters'
import { USER_PERMISSIONS, SIM_CARRIERS } from '../constants'
import { hasPermission } from '../utils/hasPermission'

const calculatePercentage = (x, total) => Math.round((x * 100) / total)

const calculatePercentComplete = (contractStartDate, contractEndDate) => {
  let percentComplete = 0

  if (contractStartDate && contractEndDate) {
    const now = moment()
    const startDate = moment(contractStartDate)
    const endDate = moment(contractEndDate)
    const totalContractDays = endDate.diff(startDate, 'days')
    if (startDate.isBefore(now) && endDate.isAfter(now)) {
      const daysCompleted = now.diff(startDate, 'days')
      percentComplete = parseInt((daysCompleted * 100) / totalContractDays)
    } else if (endDate.isBefore(now)) {
      percentComplete = 100
    }
  }

  return `${percentComplete}%`
}

const ActiveSites = ({ sites }) => (
  <div className="ActiveSites">
    <H3>Active Sites</H3>
    <Table
      dataSource={sites}
      rowKey="slug"
      pagination={false}
      size="small"
      scroll={{ x: 500 }}
      tableLayout="auto"
    >
      <Column
        title="Name"
        dataIndex="name"
        render={(text, record) => {
          const anchor = <Anchor to={`/sites/${record.slug}`}>{text}</Anchor>

          return record.test ? (
            <div className="flex items-center">
              {anchor}
              <span className="bg-neon-lime ph2 ba b--moon-gray br2 ml2 f7 ttu">
                Test
              </span>
            </div>
          ) : (
            anchor
          )
        }}
      />
      <Column
        title="Smart Pods"
        dataIndex={['stats', 'smartPod']}
        render={(
          text,
          {
            stats: {
              smartPod: {
                systemAlertsLocationCount,
                informationalAlertsLocationCount,
                actionableAlertsLocationCount,
                totalCount,
              },
            },
          }
        ) => {
          const offlineLocationsPercentage = calculatePercentage(
            systemAlertsLocationCount,
            totalCount
          )
          const infoAlertLocationsPercentage = calculatePercentage(
            informationalAlertsLocationCount,
            totalCount
          )
          const actionAlertLocationsPercentage = calculatePercentage(
            actionableAlertsLocationCount,
            totalCount
          )
          const onlineLocationsPercentage =
            100 -
            offlineLocationsPercentage -
            infoAlertLocationsPercentage -
            actionAlertLocationsPercentage
          const onlineLocationsCount =
            totalCount -
            systemAlertsLocationCount -
            actionableAlertsLocationCount -
            informationalAlertsLocationCount

          return totalCount > 0 ? (
            <div className="flex ba b--moon-gray br2">
              <Tooltip
                title={`${onlineLocationsCount} (${onlineLocationsPercentage}%) Smart Pods Healthy`}
              >
                <div
                  className={cx('h1 bg-green', {
                    'br1 br--left':
                      !!offlineLocationsPercentage ||
                      !!infoAlertLocationsPercentage ||
                      !!actionAlertLocationsPercentage,
                    br1:
                      !offlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${onlineLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${systemAlertsLocationCount} (${offlineLocationsPercentage}%) Smart Pods Offline`}
              >
                <div
                  className={cx('h1 bg-orange', {
                    'br1 br--left': !onlineLocationsPercentage,
                    'br1 br--right':
                      !infoAlertLocationsPercentage &
                      !actionAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${offlineLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${actionableAlertsLocationCount} (${actionAlertLocationsPercentage}%) Actionable Alerts`}
              >
                <div
                  className={cx('h1 bg-dark-red', {
                    'br1 br--left':
                      !onlineLocationsPercentage & !offlineLocationsPercentage,
                    'br1 br--right': !infoAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !offlineLocationsPercentage,
                  })}
                  style={{ width: `${actionAlertLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${informationalAlertsLocationCount} (${infoAlertLocationsPercentage}%) Informational Alerts`}
              >
                <div
                  className={cx('h1 bg-gold', {
                    'br1 br--right':
                      !!onlineLocationsPercentage ||
                      !!offlineLocationsPercentage ||
                      !!actionAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !offlineLocationsPercentage &
                        !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${infoAlertLocationsPercentage}%` }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="tc">&ndash; N/A &ndash;</div>
          )
        }}
      />
      {hasPermission(USER_PERMISSIONS.VIEW_FLOW_MONITOR) && (
        <Column
          title="Water Monitors"
          dataIndex={['stats', 'flowMonitor']}
          render={(
            text,
            {
              stats: {
                flowMonitor: {
                  systemAlertsLocationCount,
                  informationalAlertsLocationCount,
                  actionableAlertsLocationCount,
                  totalCount,
                },
              },
            }
          ) => {
            const offlineFlowLocationsPercentage = calculatePercentage(
              systemAlertsLocationCount,
              totalCount
            )
            const infoAlertFlowLocationsPercentage = calculatePercentage(
              informationalAlertsLocationCount,
              totalCount
            )
            const actionAlertFlowLocationsPercentage = calculatePercentage(
              actionableAlertsLocationCount,
              totalCount
            )
            const onlineFlowLocationsPercentage =
              100 -
              offlineFlowLocationsPercentage -
              infoAlertFlowLocationsPercentage -
              actionAlertFlowLocationsPercentage
            const onlineFlowLocationsCount =
              totalCount -
              systemAlertsLocationCount -
              actionableAlertsLocationCount -
              informationalAlertsLocationCount

            return totalCount > 0 ? (
              <div className="flex ba b--moon-gray br2">
                <Tooltip
                  title={`${onlineFlowLocationsCount} (${onlineFlowLocationsPercentage}%) Water Monitors Online`}
                >
                  <div
                    className={cx('h1 bg-green', {
                      'br1 br--left':
                        !!offlineFlowLocationsPercentage ||
                        !!infoAlertFlowLocationsPercentage ||
                        !!actionAlertFlowLocationsPercentage,
                      br1:
                        !offlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${onlineFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${systemAlertsLocationCount} (${offlineFlowLocationsPercentage}%) Water Monitors Offline`}
                >
                  <div
                    className={cx('h1 bg-orange', {
                      'br1 br--left': !onlineFlowLocationsPercentage,
                      'br1 br--right':
                        !infoAlertFlowLocationsPercentage &
                        !actionAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${offlineFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${actionableAlertsLocationCount} (${actionAlertFlowLocationsPercentage}%) Actionable Flow Alerts`}
                >
                  <div
                    className={cx('h1 bg-dark-red', {
                      'br1 br--left':
                        !onlineFlowLocationsPercentage &
                        !offlineFlowLocationsPercentage,
                      'br1 br--right': !infoAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !infoAlertFlowLocationsPercentage &&
                        !offlineFlowLocationsPercentage,
                    })}
                    style={{ width: `${actionAlertFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
                <Tooltip
                  title={`${informationalAlertsLocationCount} (${infoAlertFlowLocationsPercentage}%) Informational Alerts`}
                >
                  <div
                    className={cx('h1 bg-gold', {
                      'br1 br--right':
                        !!onlineFlowLocationsPercentage ||
                        !!offlineFlowLocationsPercentage ||
                        !!actionAlertFlowLocationsPercentage,
                      br1:
                        !onlineFlowLocationsPercentage &&
                        !offlineFlowLocationsPercentage &
                          !actionAlertFlowLocationsPercentage,
                    })}
                    style={{ width: `${infoAlertFlowLocationsPercentage}%` }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className="tc">&ndash; N/A &ndash;</div>
            )
          }}
        />
      )}
      <Column
        title="Leak Pucks"
        dataIndex={['stats', 'leakPod']}
        render={(
          text,
          {
            stats: {
              leakPod: {
                systemAlertsLocationCount,
                informationalAlertsLocationCount,
                actionableAlertsLocationCount,
                totalCount,
              },
            },
          }
        ) => {
          const offlineLocationsPercentage = calculatePercentage(
            systemAlertsLocationCount,
            totalCount
          )
          const infoAlertLocationsPercentage = calculatePercentage(
            informationalAlertsLocationCount,
            totalCount
          )
          const actionAlertLocationsPercentage = calculatePercentage(
            actionableAlertsLocationCount,
            totalCount
          )
          const onlineLocationsPercentage =
            100 -
            offlineLocationsPercentage -
            infoAlertLocationsPercentage -
            actionAlertLocationsPercentage
          const onlineLocationsCount =
            totalCount -
            systemAlertsLocationCount -
            actionableAlertsLocationCount -
            informationalAlertsLocationCount

          return totalCount > 0 ? (
            <div className="flex ba b--moon-gray br2">
              <Tooltip
                title={`${onlineLocationsCount} (${onlineLocationsPercentage}%) Leak Pucks Healthy`}
              >
                <div
                  className={cx('h1 bg-green', {
                    'br1 br--left':
                      !!offlineLocationsPercentage ||
                      !!infoAlertLocationsPercentage ||
                      !!actionAlertLocationsPercentage,
                    br1:
                      !offlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${onlineLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${systemAlertsLocationCount} (${offlineLocationsPercentage}%) Leak Pucks Offline`}
              >
                <div
                  className={cx('h1 bg-orange', {
                    'br1 br--left': !onlineLocationsPercentage,
                    'br1 br--right':
                      !infoAlertLocationsPercentage &
                      !actionAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${offlineLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${actionableAlertsLocationCount} (${actionAlertLocationsPercentage}%) Actionable Alerts`}
              >
                <div
                  className={cx('h1 bg-dark-red', {
                    'br1 br--left':
                      !onlineLocationsPercentage & !offlineLocationsPercentage,
                    'br1 br--right': !infoAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !infoAlertLocationsPercentage &&
                      !offlineLocationsPercentage,
                  })}
                  style={{ width: `${actionAlertLocationsPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${informationalAlertsLocationCount} (${infoAlertLocationsPercentage}%) Informational Alerts`}
              >
                <div
                  className={cx('h1 bg-gold', {
                    'br1 br--right':
                      !!onlineLocationsPercentage ||
                      !!offlineLocationsPercentage ||
                      !!actionAlertLocationsPercentage,
                    br1:
                      !onlineLocationsPercentage &&
                      !offlineLocationsPercentage &
                        !actionAlertLocationsPercentage,
                  })}
                  style={{ width: `${infoAlertLocationsPercentage}%` }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="tc">&ndash; N/A &ndash;</div>
          )
        }}
      />
      <Column
        title="Network Gateways"
        dataIndex="gateways"
        render={(text, record) => {
          const offlineGatewaysCount = !!record.gateways
            ? record.gateways.filter(x => !x.online).length
            : 0
          const onlineGatewaysCount = !!record.gateways
            ? record.gateways.filter(x => x.online).length
            : 0
          const offlineGatewaysPercentage = !!record.gateways
            ? calculatePercentage(offlineGatewaysCount, record.gateways.length)
            : 0
          const onlineGatewaysPercentage = 100 - offlineGatewaysPercentage

          return !!record.gateways && record.gateways.length > 0 ? (
            <div className="flex ba b--moon-gray br2">
              <Tooltip
                title={`${onlineGatewaysCount} (${onlineGatewaysPercentage}%) Network Gateways Online`}
              >
                <div
                  className={cx('h1 bg-green br1', {
                    'br--left': !!offlineGatewaysPercentage,
                  })}
                  style={{ width: `${onlineGatewaysPercentage}%` }}
                />
              </Tooltip>
              <Tooltip
                title={`${offlineGatewaysCount} (${offlineGatewaysPercentage}%) Network Gateways Offline`}
              >
                <div
                  className={cx('h1 bg-orange br1', {
                    'br--right': !!onlineGatewaysPercentage,
                  })}
                  style={{ width: `${offlineGatewaysPercentage}%` }}
                />
              </Tooltip>
            </div>
          ) : (
            <div className="tc">&ndash; N/A &ndash;</div>
          )
        }}
      />
      <Column
        title="Offline SIM Type"
        dataIndex="gatewayCount"
        render={(text, record) => {
          const offSimNames = !!record.gateways
            ? record.gateways
                .filter(item => !item.online)
                .map(x => formatSIMCarrier(SIM_CARRIERS[x.simType]))
                .filter((y, index, self) => self.indexOf(y) === index)
            : []
          const offSimCount =
            offSimNames.length > 0
              ? offSimNames.map(
                  x =>
                    record.gateways.filter(
                      y =>
                        !y.online &&
                        formatSIMCarrier(SIM_CARRIERS[y.simType]) === x
                    ).length
                )
              : []
          const totalSimCount =
            offSimNames.length > 0
              ? offSimNames.map(
                  x =>
                    record.gateways.filter(
                      y => formatSIMCarrier(SIM_CARRIERS[y.simType]) === x
                    ).length
                )
              : []
          return offSimNames.length > 0 ? (
            <div className="flex">
              {offSimNames.map((x, index) => (
                <li key={index} className="mh1">
                  <Tooltip
                    title={`${x !== undefined ? `${x} —` : ''} ${
                      offSimCount[index]
                    } of ${totalSimCount[index]} Offline`}
                  >
                    {formatSIMCarrierIconName(x) && (
                      <img
                        src={formatSIMCarrierIconName(x)}
                        alt={x}
                        className="w1 h1 mr1"
                      />
                    )}
                    <span>
                      {`(${offSimCount[index]}/${totalSimCount[index]})`}
                    </span>
                  </Tooltip>
                </li>
              ))}
            </div>
          ) : (
            <div className="ml2">&ndash; &ndash;</div>
          )
        }}
      />
      <Column
        title="Comp. %"
        dataIndex="contractStartDate"
        render={(text, record) => {
          return calculatePercentComplete(
            record.contractStartDate,
            record.contractEndDate
          )
        }}
      />
      <Column title="Buildings" dataIndex={['stats', 'buildingCount']} />
      <Column title="Floors" dataIndex={['stats', 'floorCount']} />
    </Table>
  </div>
)

ActiveSites.propTypes = {
  sites: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ActiveSites
