import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Switch, Route, Redirect } from 'react-router-dom'
import SensorChart from './charts/SensorChart'
import EditHistory from './EditHistory'
import AssignmentHistory from './AssignmentHistory'
import CalibrationHistory from './CalibrationHistory'
import AllComments from './AllComments'
import PodAssets from './PodAssets'
import AllPodRefurbResponses from './AllPodRefurbResponses'
import { Bread, Crumb } from './common/Bread'
import { Tabs, TabPane, Spin } from './common/Ant'
import Block from './common/Block'
import { Anchor } from './common/Anchor'
import Divider from './common/Divider'
import DateRange from './common/DateRange'
import { H4 } from './common/Headers'
import Select, { Option } from './common/Select'
import { toTitleCase, renderPodType } from '../utils/textFormatters'
import { dateFormatter, dateTimeFormatter } from '../utils/date'
import {
  POD_STATUS,
  RESOURCE_TYPES,
  BATTERY_STATE,
  DEFECTIVE_SENSOR_TYPES,
  SIZES,
  SMOOTH_INTERVAL,
  USER_PERMISSIONS,
  ROLES,
  POD_TYPES,
} from '../constants'
import { hasPermission, hasRole } from '../utils/hasPermission'
import { existy } from '../utils/helpers'
import { createQueryString, parseQueryParams } from '../utils/queryParams'
import { getPathSnippets } from '../utils/helpers'

class Pod extends Component {
  static propTypes = {
    pod: PropTypes.object.isRequired,
    currentPodChartData: PropTypes.arrayOf(PropTypes.object).isRequired,
    currentPodChartColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
    getPod: PropTypes.func.isRequired,
    getPodChartData: PropTypes.func.isRequired,
    updateQueryParams: PropTypes.func.isRequired,
    isPodLoading: PropTypes.bool.isRequired,
    isHistoryLoading: PropTypes.bool.isRequired,
    isCommentLoading: PropTypes.bool.isRequired,
    isChartDataLoading: PropTypes.bool.isRequired,
    match: PropTypes.object,
    comments: PropTypes.arrayOf(PropTypes.object),
    tags: PropTypes.arrayOf(PropTypes.object),
    getAllComments: PropTypes.func.isRequired,
    deleteComment: PropTypes.func.isRequired,
    editComment: PropTypes.func.isRequired,
    createComment: PropTypes.func.isRequired,
    getAllTags: PropTypes.func.isRequired,
    assignmentHistory: PropTypes.arrayOf(PropTypes.object),
    editHistory: PropTypes.arrayOf(PropTypes.object),
    getAssignmentHistory: PropTypes.func.isRequired,
    getEditHistory: PropTypes.func.isRequired,
    getPodAssets: PropTypes.func.isRequired,
    podImages: PropTypes.arrayOf(PropTypes.object).isRequired,
    getPodWorkflowResponses: PropTypes.func.isRequired,
    workflowResponses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getCalibrationHistory: PropTypes.func.isRequired,
    calibrationHistory: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCalibrationHistoryLoading: PropTypes.bool.isRequired,
  }

  now = moment()

  state = {
    now: this.now,
    endValue: this.now,
    startValue: moment(this.now).subtract(1, 'd'),
    commentEndTime: undefined,
    commentStartTime: undefined,
    activeTab: undefined,
    smoothInterval: SMOOTH_INTERVAL.FIVE_MINUTES,
  }

  componentWillReceiveProps() {
    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]

    this.setState({ activeTab: lastSnippet })
  }

  componentDidMount() {
    const { getPod, getPodChartData, getPodAssets, match } = this.props
    const { pillarId } = match.params
    const { startValue, endValue } = this.state

    const searchParams = parseQueryParams(global.location)

    let query
    if (searchParams.startTime && searchParams.endTime) {
      query = {
        startTime: searchParams.startTime,
        endTime: searchParams.endTime,
      }
      this.setState({
        startValue: moment(searchParams.startTime * 1000),
        endValue: moment(searchParams.endTime * 1000),
      })
    } else {
      query = {
        startTime: startValue.unix(),
        endTime: endValue.unix(),
      }
    }

    if (searchParams.smoothInterval || searchParams.smooth) {
      query = {
        ...query,
        smoothInterval: searchParams.smoothInterval,
        smooth: searchParams.smooth,
      }

      this.setState({
        smoothInterval: searchParams.smoothInterval
          ? searchParams.smoothInterval
          : !searchParams.smooth || searchParams.smooth === 't'
          ? SMOOTH_INTERVAL.FIVE_MINUTES
          : SMOOTH_INTERVAL.RAW,
      })
    }

    const qs = createQueryString(query)

    getPod(pillarId)
    getPodChartData(pillarId, qs)
    getPodAssets(pillarId)

    const snippets = getPathSnippets(global.location.pathname)
    const lastSnippet = snippets[snippets.length - 1]
    this.handleTabSelection(lastSnippet, query)
  }

  componentDidUpdate(prevProps) {
    const {
      getPodWorkflowResponses,
      pod: { slug },
    } = this.props

    if (
      hasPermission(USER_PERMISSIONS.VIEW_WORKFLOW) &&
      !prevProps.pod.slug &&
      !!slug
    ) {
      getPodWorkflowResponses(slug)
    }
  }

  handleTabSelection = (entity, query) => {
    const { updateQueryParams, match } = this.props
    const { smoothInterval } = this.state
    const qs = createQueryString({
      startTime: query.startTime,
      endTime: query.endTime,
      smoothInterval,
    })

    if (entity !== match.params.pillarId) {
      this.setState({ activeTab: entity })
      updateQueryParams({
        pathname: `${match.url}/${entity}`,
        search: entity === 'comments' ? qs : '',
      })
    } else {
      this.setState({ activeTab: 'comments' })
    }
  }

  onCloseDateRange = (startValue, endValue) => {
    const { getPodChartData, updateQueryParams, match } = this.props
    const { pillarId } = match.params
    const { smoothInterval } = this.state

    this.setState({ startValue, endValue })

    const qs = createQueryString({
      startTime: startValue.unix(),
      endTime: endValue.unix(),
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })
    getPodChartData(pillarId, qs)
  }

  handleFilterChange = smoothInterval => {
    const { getPodChartData, updateQueryParams, match } = this.props
    const { pillarId } = match.params
    const { startValue, endValue } = this.state

    this.setState({ smoothInterval })

    const qs = createQueryString({
      smooth: smoothInterval === SMOOTH_INTERVAL.RAW ? 'f' : 't',
      smoothInterval:
        smoothInterval !== SMOOTH_INTERVAL.RAW ? smoothInterval : undefined,
      startTime: startValue.unix(),
      endTime: endValue.unix(),
    })

    updateQueryParams({
      pathname: global.location.pathname,
      search: qs,
    })

    getPodChartData(pillarId, qs)
  }

  setCommentTime = (commentStartTime, commentEndTime) =>
    this.setState({ commentStartTime, commentEndTime })

  render() {
    const {
      pod,
      comments,
      getAllComments,
      deleteComment,
      createComment,
      editComment,
      getAllTags,
      tags,
      currentPodChartData,
      currentPodChartColumns,
      isPodLoading,
      isHistoryLoading,
      isCommentLoading,
      isChartDataLoading,
      editHistory,
      assignmentHistory,
      getEditHistory,
      getAssignmentHistory,
      podImages,
      workflowResponses,
      match,
      updateQueryParams,
      getCalibrationHistory,
      calibrationHistory,
      isCalibrationHistoryLoading,
    } = this.props

    const {
      startValue,
      endValue,
      commentStartTime,
      commentEndTime,
      smoothInterval,
    } = this.state

    const {
      id,
      siteName,
      locationName,
      description,
      pillarId,
      moduleId,
      podType,
      status,
      firmwareVersion,
      packetVersionNumber,
      packetMinorVersionNumber,
      productVersion,
      builtDate,
      assetLocationPillarId,
      locationId,
      locationDescription,
      siteSlug,
      floorId,
      floor,
      slug,
      deploymentInstallType,
      batteryStartStatus,
      batteryChangeDate,
      batteryCurrentStatus,
      batteryUsedPercentCurrent,
      batteryPercentCurrentTime,
      batteryUsedPercentVoltage,
      deploymentSlug,
      deploymentCreatedAt,
      deploymentDescription,
      defectiveSensors,
      calibrationBme680Humidity,
      calibrationBme680Temperature,
      calibrationBme680Pressure,
      calibrationBme680Voc,
      calibrationBme280Humidity,
      calibrationBme280Temperature,
      calibrationBme280Pressure,
      calibrationLmt87Temperature,
      calibrationSharp1023Dust,
      calibrationBma222eAcceleration,
      calibrationOpt3002dnptAmbientLight,
      calibrationShtc3Temperature,
      calibrationShtc3Humidity,
      coCalibration,
      lastConnectedGateway,
      networkName,
      networkSlug,
    } = pod

    const deploymentTitle = deploymentDescription
      ? deploymentDescription
      : dateTimeFormatter(deploymentCreatedAt, true)

    let defectiveSensorNames = []
    if (defectiveSensors.length) {
      defectiveSensorNames = defectiveSensors
        .map(sensor => {
          const sensorValueNames = Object.keys(DEFECTIVE_SENSOR_TYPES).filter(
            sensorType => DEFECTIVE_SENSOR_TYPES[sensorType] === sensor
          )
          return sensorValueNames.length ? sensorValueNames[0] : null
        })
        .filter(existy)
    }
    const gatewayModuleIdArray = currentPodChartData.map(x => x.gatewayModuleId)
    const gatewayModuleId =
      gatewayModuleIdArray[gatewayModuleIdArray.length - 1]
    const isLeakPod = podType === POD_TYPES.LEAK_POD

    return isPodLoading ? (
      <Spin size="large" className="w-100 center mv5" />
    ) : (
      <section className="Pod">
        <div className="mb3">
          <div className="mb2">
            <Bread>
              <Crumb>
                <Anchor to="/inventory/all">Inventory</Anchor>
              </Crumb>
              <Crumb>
                <Anchor to={`/inventory/all/pods`}>Smart Pods</Anchor>
              </Crumb>
              <Crumb>
                <div className="inline-flex items-center">
                  <span>{pillarId}</span>
                  <span className="pl1 gray">({renderPodType(podType)})</span>
                </div>
              </Crumb>
            </Bread>
            <Bread size={SIZES.SMALL}>
              {!!siteName && (
                <Crumb>
                  {hasRole(ROLES.PILLAR_ADMIN, ROLES.SUPER_ADMIN) ? (
                    <Anchor to={`/sites/${siteSlug}`}>{siteName}</Anchor>
                  ) : (
                    siteName
                  )}
                </Crumb>
              )}
              {!!floorId && (
                <Crumb>
                  {hasPermission(USER_PERMISSIONS.VIEW_FLOOR) ? (
                    <Anchor to={`/sites/${siteSlug}/floors/${floorId}`}>
                      Floor {floor}
                    </Anchor>
                  ) : (
                    `Floor ${floor}`
                  )}
                </Crumb>
              )}
              {!!locationName && (
                <Crumb>
                  {hasPermission(USER_PERMISSIONS.VIEW_LOCATION) ? (
                    <Anchor
                      to={`/sites/${siteSlug}/floors/${floorId}/locations/${locationId}`}
                    >
                      {locationName}
                    </Anchor>
                  ) : (
                    locationName
                  )}
                </Crumb>
              )}
            </Bread>
          </div>
          <div className="flex justify-center mb3">
            <div>{toTitleCase(POD_STATUS[status])}</div>
            {moduleId && (
              <div>
                <span className="mh1">&mdash;</span>
                <Anchor
                  to={`https://conductor.link-labs.com/symphony/${moduleId}`}
                >
                  {moduleId}
                </Anchor>
              </div>
            )}
          </div>
          <div className="flex justify-center mb3">
            <Anchor to={`${match.url}/edit`}>Edit</Anchor>
          </div>
        </div>
        <Divider />
        <div className="charts">
          <div className="mb2 flex-ns">
            <DateRange
              label="Select date range to view Smart Pod readings"
              startValue={startValue}
              endValue={endValue}
              onClose={this.onCloseDateRange}
              shouldLimitRange={!hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN)}
            />
            {hasRole(ROLES.SUPER_ADMIN, ROLES.PILLAR_ADMIN) && (
              <Select
                className="ml4-ns"
                label="Smoothing Interval"
                input={{
                  value: smoothInterval,
                  onChange: x => this.handleFilterChange(x),
                }}
              >
                {Object.keys(SMOOTH_INTERVAL).map(x => (
                  <Option value={SMOOTH_INTERVAL[x]} key={x}>
                    {toTitleCase(x)}
                  </Option>
                ))}
              </Select>
            )}
          </div>
          <SensorChart
            data={currentPodChartData}
            columns={currentPodChartColumns}
            updateQueryParams={updateQueryParams}
            isLoading={isChartDataLoading}
            locationName={locationName}
            podPillarId={pillarId}
            isLeakPod={isLeakPod}
          />
        </div>
        <Divider />
        <Switch>
          <Route
            path={`${match.url}/`}
            exact
            render={props => <Redirect to={`${match.url}/comments`} />}
          />
          <Fragment>
            <Tabs
              defaultActiveKey="comments"
              type="card"
              activeKey={this.state.activeTab}
              onTabClick={this.handleTabSelection}
            >
              {hasPermission(USER_PERMISSIONS.VIEW_COMMENT) && (
                <TabPane tab="Comments" key="comments">
                  <Route
                    path={`${match.url}/comments`}
                    exact
                    render={props => (
                      <AllComments
                        resourceType={RESOURCE_TYPES.POD}
                        resourceSlug={slug}
                        startTime={commentStartTime}
                        endTime={commentEndTime}
                        setCommentTime={this.setCommentTime}
                        isCommentLoading={isCommentLoading}
                        getAllComments={getAllComments}
                        deleteComment={deleteComment}
                        createComment={createComment}
                        editComment={editComment}
                        getAllTags={getAllTags}
                        tags={tags}
                        comments={comments}
                      />
                    )}
                  />
                </TabPane>
              )}
              <TabPane tab="Assignment History" key="assignment-history">
                <Route
                  path={`${match.url}/assignment-history`}
                  exact
                  render={props => (
                    <AssignmentHistory
                      assignmentHistory={assignmentHistory}
                      getAssignmentHistory={getAssignmentHistory}
                      resourceId={id}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              <TabPane tab="Calibration History" key="calibration-history">
                <Route
                  path={`${match.url}/calibration-history`}
                  exact
                  render={props => (
                    <CalibrationHistory
                      calibrationHistory={calibrationHistory}
                      getCalibrationHistory={getCalibrationHistory}
                      podPillarId={pillarId}
                      isLoading={isCalibrationHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              <TabPane tab="Edit History" key="edit-history">
                <Route
                  path={`${match.url}/edit-history`}
                  exact
                  render={props => (
                    <EditHistory
                      editHistory={editHistory}
                      getEditHistory={getEditHistory}
                      resourceId={id}
                      isLoading={isHistoryLoading}
                    />
                  )}
                />
              </TabPane>
              <TabPane tab="Details" key="details">
                <Route
                  path={`${match.url}/details`}
                  exact
                  render={props => (
                    <div>
                      <div className="flex flex-wrap">
                        <Block label="Location Description">
                          {locationDescription}
                        </Block>
                        <Block label="Description">{description}</Block>
                        <Block label="Pillar ID">{pillarId}</Block>
                        <Block label="Asset Location">
                          {assetLocationPillarId}
                        </Block>
                        <Block label="Network">
                          {networkSlug && (
                            <Anchor to={`/networks/${networkSlug}/view`}>
                              {networkName}
                            </Anchor>
                          )}
                        </Block>
                        <Block label="Last Connected Network Gateway">
                          {lastConnectedGateway}
                        </Block>
                        <Block label="Type">{renderPodType(podType)}</Block>
                        <Block label="Defective Sensors">
                          {!!defectiveSensorNames.length && (
                            <ul className="pl4 mb0">
                              {defectiveSensorNames.map(sensor => (
                                <li key={sensor}>{toTitleCase(sensor)}</li>
                              ))}
                            </ul>
                          )}
                        </Block>
                        <Block label="Assigned Firmware Version">
                          {firmwareVersion}
                        </Block>
                        <Block label="Smart Pod Firmware Version">{`${packetVersionNumber}.${packetMinorVersionNumber}`}</Block>
                        <Block label="Product Version">{productVersion}</Block>
                        <Block label="Date Built">
                          {builtDate && dateFormatter(builtDate)}
                        </Block>
                        <Block label="Tags">
                          {!!pod.tags.length && (
                            <ul className="pl4 mb0">
                              {pod.tags.map(tag => (
                                <li key={tag.id}>{tag.name}</li>
                              ))}
                            </ul>
                          )}
                        </Block>
                        {deploymentSlug && siteSlug && siteName && (
                          <Fragment>
                            <Block label="Deployment">
                              <Anchor
                                to={`/sites/${siteSlug}/deployments/${deploymentSlug}`}
                              >
                                {deploymentTitle}
                              </Anchor>
                            </Block>
                            <Block label="Deployment Install Type">
                              {toTitleCase(deploymentInstallType)}
                            </Block>
                            <Block label="Deployment Site">
                              <Anchor to={`/sites/${siteSlug}`}>
                                {siteName}
                              </Anchor>
                            </Block>
                          </Fragment>
                        )}
                        {gatewayModuleId && (
                          <Block label="Last Connected Network Gateway">
                            <Anchor
                              to={`https://conductor.link-labs.com/gateway/${gatewayModuleId}`}
                            >
                              {gatewayModuleId}
                            </Anchor>
                          </Block>
                        )}
                      </div>
                      <H4>Batteries</H4>
                      <div className="flex flex-wrap">
                        <Block label="Battery Start Status">
                          {toTitleCase(BATTERY_STATE[batteryStartStatus])}
                        </Block>
                        <Block label="Battery Start Date">
                          {batteryChangeDate &&
                            dateFormatter(batteryChangeDate)}
                        </Block>
                        <Block label="Battery Current Status">
                          {toTitleCase(BATTERY_STATE[batteryCurrentStatus])}
                        </Block>
                        <Block label="Battery Used % (I)">
                          {batteryUsedPercentCurrent}
                        </Block>
                        <Block label="Battery Used Last Updated">
                          {batteryPercentCurrentTime &&
                            dateFormatter(batteryPercentCurrentTime)}
                        </Block>
                        <Block label="Battery Used % (II)">
                          {batteryUsedPercentVoltage}
                        </Block>
                      </div>
                      <H4>Sensor Calibration</H4>
                      {podType === POD_TYPES.SMART ? (
                        <div className="flex flex-wrap">
                          <Block label="BME680 Humidity">
                            {calibrationBme680Humidity.toString()}
                          </Block>
                          <Block label="BME680 Temperature">
                            {calibrationBme680Temperature.toString()}
                          </Block>
                          <Block label="BME680 Pressure">
                            {calibrationBme680Pressure.toString()}
                          </Block>
                          <Block label="BME680 VOC">
                            {calibrationBme680Voc.toString()}
                          </Block>
                          <Block label="BME280 Humidity">
                            {calibrationBme280Humidity.toString()}
                          </Block>
                          <Block label="BME280 Temperature">
                            {calibrationBme280Temperature.toString()}
                          </Block>
                          <Block label="BME280 Pressure">
                            {calibrationBme280Pressure.toString()}
                          </Block>
                          <Block label="LMT87 Temperature">
                            {calibrationLmt87Temperature.toString()}
                          </Block>
                          <Block label="Sharp1023 Dust">
                            {calibrationSharp1023Dust.toString()}
                          </Block>
                          <Block label="BMA222E Acceleration">
                            {calibrationBma222eAcceleration.toString()}
                          </Block>
                          <Block label="OPT3002 Ambient Light">
                            {calibrationOpt3002dnptAmbientLight.toString()}
                          </Block>
                          <Block label="CO">{coCalibration.toString()}</Block>
                        </div>
                      ) : (
                        <div className="flex flex-wrap">
                          <Block label="Temperature SHTC3">
                            {calibrationShtc3Temperature.toString()}
                          </Block>
                          <Block label="Humidity SHTC3">
                            {calibrationShtc3Humidity.toString()}
                          </Block>
                        </div>
                      )}
                    </div>
                  )}
                />
              </TabPane>
              <TabPane tab="Assets" key="assets">
                <Route
                  path={`${match.url}/assets`}
                  exact
                  render={props => <PodAssets images={podImages} />}
                />
              </TabPane>
              {hasPermission(USER_PERMISSIONS.VIEW_WORKFLOW) && (
                <TabPane tab="Refurb Responses" key="refurb-responses">
                  <Route
                    path={`${match.url}/refurb-responses`}
                    exact
                    render={props => (
                      <AllPodRefurbResponses
                        workflowResponses={workflowResponses}
                        podPillarId={pillarId}
                      />
                    )}
                  />
                </TabPane>
              )}
            </Tabs>
          </Fragment>
        </Switch>
      </section>
    )
  }
}

export default Pod
