import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Table,
  Column,
  Menu,
  Dropdown,
  Icon,
  Badge,
  Tooltip,
  Spin,
} from './common/Ant'
import { Anchor, ActionAnchor } from './common/Anchor'
import SearchFilter from './common/SearchFilter'
import EscalationPolicySelect from './EscalationPolicySelect'
import { addEscalationPolicytoSiteThresholdAction } from '../actions/sitesActions'
import { hasPermission } from '../utils/hasPermission'
import { toTitleCase } from '../utils/textFormatters'
import { parseQueryParams, createQueryString } from '../utils/queryParams'
import { configurePagination, escapeRegExp } from '../utils/helpers'
import {
  THRESHOLD_LEVELS,
  THRESHOLD_DATA_SOURCES,
  USER_PERMISSIONS,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
} from '../constants'

class AllThresholds extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: this.props.thresholds,
      searchText: '',
      page: DEFAULT_PAGE,
      perPage: DEFAULT_PER_PAGE,
      pagination: {
        current: 1,
      },
      filtered: false,
    }
  }

  static propTypes = {
    thresholds: PropTypes.arrayOf(PropTypes.object).isRequired,
    escalationPolicies: PropTypes.arrayOf(PropTypes.object),
    meta: PropTypes.object,
    getAllThresholds: PropTypes.func,
    updateQueryParams: PropTypes.func,
    deleteThreshold: PropTypes.func,
    deleteThresholdFromEntity: PropTypes.func,
    isRenderingDashboardView: PropTypes.bool,
    isThresholdLoading: PropTypes.bool,
    shouldRenderEscalationPolicies: PropTypes.bool,
    match: PropTypes.shape({ url: PropTypes.string }),
  }

  static defaultProps = {
    shouldRenderEscalationPolicies: true,
  }

  componentDidMount() {
    const { page, perPage } = parseQueryParams(global.location)

    const query = {
      page: page ? parseInt(page, 10) : DEFAULT_PAGE,
      perPage: perPage ? parseInt(perPage, 10) : DEFAULT_PER_PAGE,
    }
    this.setState(query)

    this.props.getAllThresholds &&
      this.props.getAllThresholds(createQueryString(query))
  }

  componentWillReceiveProps() {
    this.handleSearch()
  }

  handleTableChange = pagination => {
    const {
      getAllThresholds,
      updateQueryParams,
      isRenderingDashboardView,
      match,
    } = this.props
    const { page, perPage } = this.state

    if (!isRenderingDashboardView && page !== pagination.current) {
      const query = { page: pagination.current, perPage }
      const qs = createQueryString(query)

      getAllThresholds(qs)

      updateQueryParams &&
        updateQueryParams({
          pathname: match.url,
          search: qs,
        })
    }

    this.setState({
      page: pagination.current,
      searchText: '',
    })
  }

  handleSearch = () => {
    const { searchText } = this.state
    const regex = new RegExp(escapeRegExp(searchText), 'gi')

    this.setState((state, props) => {
      const data = props.thresholds
        .map(record => {
          const match =
            (record.name && record.name.match(regex)) ||
            (record.thresholdName && record.thresholdName.match(regex)) ||
            record.id.toString().match(regex)

          return match ? record : null
        })
        .filter(record => !!record)

      return {
        filtered: !!searchText,
        data,
      }
    })
  }

  setSearchText = searchText => this.setState({ searchText })

  renderThresholdLevel(level) {
    switch (level) {
      case THRESHOLD_LEVELS.HIGH:
        return 'High'
      case THRESHOLD_LEVELS.WARNING:
        return 'Medium'
      case THRESHOLD_LEVELS.LOW:
        return 'Low'
    }
  }

  renderThresholdDataSource(source) {
    switch (source) {
      case THRESHOLD_DATA_SOURCES.TEMPERATURE:
        return 'Temperature'
      case THRESHOLD_DATA_SOURCES.PRESSURE:
        return 'Pressure'
      case THRESHOLD_DATA_SOURCES.HUMIDITY:
        return 'Humidity'
      case THRESHOLD_DATA_SOURCES.DUST:
        return 'Dust'
      case THRESHOLD_DATA_SOURCES.SOUND:
        return 'Sound'
      case THRESHOLD_DATA_SOURCES.VOC:
        return 'VOC (Alpha)'
      case THRESHOLD_DATA_SOURCES.CARBON_MONOXIDE:
        return 'Carbon Monoxide'
      case THRESHOLD_DATA_SOURCES.MIC_COUNT:
        return 'Noise Count (> 85 dBA)'
      case THRESHOLD_DATA_SOURCES.DEW_POINT:
        return 'Dew Point'
      case THRESHOLD_DATA_SOURCES.WATER_FLOW:
        return 'Water Flow'
      case THRESHOLD_DATA_SOURCES.LEAK_EVENT:
        return 'Leak Event'
      case THRESHOLD_DATA_SOURCES.LEAK_STATE:
        return 'Leak State'
      default:
        return '--'
    }
  }

  render() {
    const {
      deleteThreshold,
      deleteThresholdFromEntity,
      escalationPolicies,
      meta,
      isRenderingDashboardView,
      isThresholdLoading,
      shouldRenderEscalationPolicies,
      getAllThresholds,
    } = this.props
    const { data, page, perPage } = this.state

    const query = {
      page,
      perPage,
    }

    const thresholdName = isRenderingDashboardView ? 'thresholdName' : 'name'
    const thresholdId = isRenderingDashboardView ? 'thresholdId' : 'id'
    const thresholdActive = isRenderingDashboardView
      ? 'thresholdActive'
      : 'active'
    const thresholdPublishToUser = isRenderingDashboardView
      ? 'thresholdPublishToUser'
      : 'publishToUser'

    const expandedRowRender = record => (
      <div className="mt1">
        <Table
          dataSource={record.rulesJson.map((x, i) => ({ ...x, index: i }))}
          rowKey="index"
          pagination={false}
          tableLayout="auto"
        >
          <Column
            title="Type"
            dataIndex="thresholdType"
            render={text => toTitleCase(text)}
          />
          <Column
            title="Source"
            dataIndex="source"
            render={text => this.renderThresholdDataSource(text)}
          />
          <Column title="Low" dataIndex="low" />
          <Column title="High" dataIndex="high" />
        </Table>
      </div>
    )

    return (
      <section className="mb5 AllThresholds">
        {!isRenderingDashboardView && (
          <SearchFilter
            searchText={this.state.searchText}
            setSearchText={this.setSearchText}
            getItems={getAllThresholds}
            query={query}
          />
        )}
        {isThresholdLoading ? (
          <Spin size="large" className="w-100 center mv5" />
        ) : (
          <Table
            className="nested-table"
            dataSource={data}
            onChange={this.handleTableChange}
            expandedRowRender={
              isRenderingDashboardView ? null : expandedRowRender
            }
            bordered
            rowKey={thresholdId}
            tableLayout="auto"
            pagination={
              !isRenderingDashboardView &&
              configurePagination({
                perPage,
                ...meta,
              })
            }
          >
            <Column
              title={
                isRenderingDashboardView ? 'Active Threshold' : 'Threshold Name'
              }
              dataIndex="id"
              render={(text, record) => {
                const isGroup = !!record.thresholdGroupSlug
                const name = isGroup
                  ? record.thresholdGroupName
                  : record[thresholdName]
                const href = !record.thresholdGroupName
                  ? `/rules/thresholds/${record[thresholdId]}`
                  : `/rules/groups/${record.thresholdGroupSlug}/edit`
                const badge = record[thresholdActive] ? (
                  <Badge status="success" />
                ) : (
                  <Badge status="default" />
                )
                const muted = !record.thresholdGroupName &&
                  !record[thresholdPublishToUser] && (
                    <Tooltip title="Threshold Muted">
                      <Icon type="exclamation-circle-o" className="pr2" />
                    </Tooltip>
                  )

                const groupIcon = isGroup && (
                  <div className="flex items-center ml2">
                    <Tooltip title="Threshold Group">
                      <Icon className="dark-green f4" type="block" />
                    </Tooltip>
                  </div>
                )

                return (
                  <div className="flex">
                    {badge} {muted}{' '}
                    {hasPermission(USER_PERMISSIONS.VIEW_THRESHOLD) ? (
                      <Anchor to={href}>{name}</Anchor>
                    ) : (
                      name
                    )}
                    {groupIcon}
                  </div>
                )
              }}
            />
            {/* <Column */}
            {/*   title="Company" */}
            {/*   dataIndex="companyName" */}
            {/*   render={text => text || '--'} */}
            {/* /> */}
            {!isRenderingDashboardView && [
              <Column
                title="Site"
                dataIndex="siteName"
                render={text => text || '--'}
              />,
              <Column
                title="Level"
                dataIndex="level"
                render={text => this.renderThresholdLevel(text)}
              />,
            ]}
            {shouldRenderEscalationPolicies &&
              hasPermission(USER_PERMISSIONS.VIEW_ESCALATION_POLICY) &&
              isRenderingDashboardView && (
                <Column
                  title="Escalation Policy"
                  dataIndex="escalationPolicySlug"
                  render={(text, record) => (
                    <EscalationPolicySelect
                      escalationPolicies={escalationPolicies}
                      escalationPolicySlug={record.escalationPolicySlug}
                      thresholdId={record.id}
                      submitAction={addEscalationPolicytoSiteThresholdAction}
                    />
                  )}
                />
              )}
            {hasPermission(USER_PERMISSIONS.EDIT_THRESHOLD) &&
              (isRenderingDashboardView ? (
                <Column
                  render={(text, record) => {
                    const href = !record.thresholdGroupName
                      ? `/rules/thresholds/${record[thresholdId]}`
                      : `/rules/groups/${record.thresholdGroupSlug}/edit`
                    const menu = (
                      <Menu>
                        <Menu.Item key="edit-threshold">
                          <Anchor to={href}>Edit</Anchor>
                        </Menu.Item>
                        <Menu.Item key="remove-threshold-from-site">
                          <ActionAnchor
                            onClick={() => deleteThresholdFromEntity(record.id)}
                          >
                            Remove
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ) : (
                <Column
                  render={(text, record) => {
                    const menu = (
                      <Menu>
                        <Menu.Item key="edit-threshold">
                          <Anchor
                            to={`/rules/thresholds/${record[thresholdId]}/edit`}
                          >
                            Edit
                          </Anchor>
                        </Menu.Item>
                        <Menu.Item key="delete-threshold">
                          <ActionAnchor
                            onClick={() => deleteThreshold(record[thresholdId])}
                          >
                            Delete
                          </ActionAnchor>
                        </Menu.Item>
                      </Menu>
                    )

                    return (
                      <Dropdown overlay={menu} trigger={['click']}>
                        <a className="ant-dropdown-link">
                          Actions <Icon type="down" />
                        </a>
                      </Dropdown>
                    )
                  }}
                />
              ))}
          </Table>
        )}
      </section>
    )
  }
}

export default AllThresholds
